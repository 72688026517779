import React, { useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { toast } from "react-toastify";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddEditSubConfrigurationModel from "../../../../components/common/AddEditSubConfrigurationModel";
import DeleteModal from "../../../../components/common/DeleteModal";
import ViewSubConfrigurationModel from "../../../../components/common/ViewSubConfrigurationModel";
import Index from "../../../Index";

const AddSubConfiguration = () => {
  const [open, setOpen] = useState(false);
  const [subConfigurationId, setSubConfigurationId] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState(null);
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { state } = useLocation();
  const [subconfigurations, setSubconfigurations] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 
  const getAllSubConfiguration = async () => {
    try {
      const response = await DataService.get(
        `/common/get-all-subconfiguration/${state?.configuration?._id}`
      );
      if (response.status === 200) {
        setSubconfigurations(response.data.data);
        setFilterData(response.data.data);
      }
    } catch (error) {
      toast.error(JSON.parse(error?.request?.response)?.message);
    }
  };
  useEffect(() => {
    getAllSubConfiguration();
  }, []);

  const handleConfigurationAdd = (values) => {
    const addSubConfiguration = async () => {
      try {
        const response = await DataService.post("/admin/add-subconfiguration", {
          subConfigurationId: data?._id ? data?._id : undefined,
          configurationId: state?.configuration?._id
            ? state?.configuration?._id
            : undefined,
          title: values.title,
        });
        if (response.data.status === 200 || response.data.status === 201) {
          values.title = "";
          setOpenEdit(false);
          getAllSubConfiguration();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(JSON.parse(error?.request?.response)?.message);
      }
    };
    addSubConfiguration();
  };

  const handelDelete = async () => {
    try {
      const response = await DataService.delete(
        `/admin/delete-subconfiguration/${subConfigurationId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        getAllSubConfiguration();
        setOpen(false);
        setSubConfigurationId(null);
      } else {
        setSubConfigurationId(null);
      }
    } catch (error) {
      setSubConfigurationId(null);
      toast.error(JSON.parse(error?.request?.response)?.message);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = subconfigurations?.filter((item) => {
      const matchesTitle = item?.title && item?.title?.toLowerCase().includes(search);

      return matchesTitle;
    });
    setFilterData(res);
  };

  return (
    <Box className="main-add-edit">
      <Box className="top-table">
        <Box classsName="left-heading-table">
          <Typography component="h5" variant="h5">
            {state?.configuration?.title}
          </Typography>
        </Box>
        <Box className="right-top-table">
        <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={() => {
              console.log("ooo");
              setOpenEdit(true);
            }}
          >
            Add {state?.configuration?.title}
          </Button>
        </Box>
      </Box>

      {/* <Box className="admin-master">
                <Formik
                    initialValues={initialValues}
                    validationSchema={configurationAddSchema}
                    onSubmit={handleConfigurationAdd}
                >
                    {({ values, handleSubmit, handleChange, errors }) => (
                        <Box className='admin-form' component="form" onSubmit={handleSubmit} >
                            <Box className="admin-input">
                                <TextField
                                    size="small"
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                {
                                    errors.name && (
                                        <span className='error'> {errors.name} </span>
                                    )
                                }
                            </Box>
                            <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                                <Button type="submit" variant="contained" >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Formik>
            </Box> */}
      <Box className="main-content">
        <TableContainer className="table-main" sx={{overflow:"scroll"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S. No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((subconfiguration, i) => (
                <TableRow key={subconfiguration._id}>
                  <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{subconfiguration.title}</TableCell>
                  <TableCell>
                    <Box className="mian-table-icon">
                      <Box className="first-icon-edit">
                     
                        <span>
                          <BorderColorIcon
                            sx={{
                              color: "blue",
                              padding: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenEdit(true);
                              setData(subconfiguration);
                            }}
                          />
                        </span>
                        <span>
                          <DeleteIcon
                            sx={{
                              color: "red",
                              padding: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpen(true);
                              setSubConfigurationId(subconfiguration._id);
                            }}
                          />
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                  <DeleteModal
                    open={open}
                    setOpen={setOpen}
                    handelDelete={handelDelete}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Index.TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={subconfigurations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        <AddEditSubConfrigurationModel
          data={data}
          setData={setData}
          modaltitle={state?.configuration?.title}
          open={openEdit}
          setOpen={setOpenEdit}
          handelSubmitButton={handleConfigurationAdd}
        />
        <ViewSubConfrigurationModel
          data={data}
          setData={setData}
          modaltitle={state?.configuration?.title}
          open={openView}
          setOpen={setOpenView}
        />
      </Box>
    </Box>
  );
};

export default AddSubConfiguration;
