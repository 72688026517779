import React, {  useState } from "react";
import "./Professional.css";
import imageIcon from "../../../../assets/images/user-profile.jpg";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const ProfessionalList = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [professionalList, setProfessinalList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const navigate = PageIndex.useNavigate();
  const fetchCategoryList = async () => {
    try {
      const response = await PageIndex.DataService.get(PageIndex.API.ADMIN.CATEGORY_LIST);
      setCategoryList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching category list:", error);
    }
  };
  PageIndex.useEffect(() => {
    fetchCategoryList();
    getProfessionalList();
  }, []);

  const hanldeSubmit = (data) => {
    navigate(`/professional/${data?._id}`, { state: { data } });
  };

  const getProfessionalList = async () => {
   try {
    const res = await PageIndex.DataService.get(PageIndex.API.USER.PROFESSIONAL_LIST);
    setFilterData(res?.data?.data);
    setProfessinalList(res?.data?.data);
   } catch (error) {
    console.log(error,"error");
   }
  };

  const findNextAvailableDate = (availability) => {
    const today = new Date();
    const checkData = today?.toISOString()?.slice(0, 10)?.replace(/-/g, "/");

    const futureDates = availability?.filter((dateStr) => dateStr >= checkData);
    if (futureDates && futureDates?.length > 0) {
      return PageIndex.format(futureDates[0], "dd/MM/yyyy");
    } else {
      return "Not available now";
    }
  };
  
  const handleSearchChange = (e) => {
    const search = e.target.value.trim();
    const res = professionalList?.filter((item) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      return (
        fullName.includes(search?.toLowerCase()) ||
        (item.email &&
          item.email?.toLowerCase()?.includes(search?.toLowerCase())) ||
        (item.city && item?.city?.title?.toLowerCase()?.includes(search?.toLowerCase())) ||
        (item?.categoryId && item?.categoryId?.category?.toLowerCase()?.includes(search?.toLowerCase())) ||
         (item?.mobileNumber && item?.mobileNumber.toString().includes(search?.toLowerCase())) ||
         (item?.tagLine && item?.tagLine?.toLowerCase()?.includes(search?.toLowerCase()))
      );
    });
    setFilterData(res);
  };

  const filterChange = (event) => {
    const category = event.target.value;
    if (category == "all") {
      setFilterData(professionalList);
    } else {
      const filteredData = professionalList?.filter(
        (item) => item?.categoryId?._id == category
      );
      setFilterData(filteredData);
    }
  };

  return (
    <>
      <Index.Box className="professional-list-main-box">
        <Index.Box className="professional-list-header">
          <Index.Box className="header-list-dropdown">
            <Index.TextField
              type="text"
              defaultValue={"all"}
              name="categoryId"
              label="Category"
              onChange={filterChange}
              select
              className="category-dropdown"
            >
              <Index.MenuItem value={"all"}>All</Index.MenuItem>
              {categoryList?.map((item) => (
                <Index.MenuItem key={item?._id} value={item?._id}>
                  {" "}
                  {item.category}
                </Index.MenuItem>
              ))}
            </Index.TextField>
          </Index.Box>

          <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
        </Index.Box>

        <Index.Box className="professional-list-detail-box">
          <Index.Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {filterData &&
              filterData?.map((item, index) => (
                <Index.Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                  <Index.Box className="professional-box">
                    <Index.Box className="prof-img-box">
                      {item?.profile ? (
                        <img
                          src={`${PageIndex.imageURL}/${item?.profile}`}
                          className="prof-img"
                          onClick={() => {
                            hanldeSubmit(item);
                          }}
                        />
                      ) : (
                        <img
                          src={imageIcon}
                          className="prof-img"
                          onClick={() => {
                            hanldeSubmit(item);
                          }}
                        />
                      )}
                    </Index.Box>

                    <Index.Box className="professional-detail-box">
                      <Index.Typography
                        className="prof-name"
                        onClick={() => {
                          hanldeSubmit(item);
                        }}
                      >
                        {item?.firstName?.charAt(0)?.toUpperCase() +
                          item?.firstName?.slice(1)}{" "}
                        {item?.lastName?.charAt(0)?.toUpperCase() +
                          item?.lastName?.slice(1)}
                      </Index.Typography>
                      <Index.Box className="prof-city-box">
                        <Index.LocationOnIcon className="prof-city-icon" />
                        <Index.Typography>{item?.city?.title}</Index.Typography>
                      </Index.Box>
                      <Index.Box className="prof-email-box">
                        <Index.EmailIcon className="prof-email-icon" />
                        <Index.Typography>{item?.email}</Index.Typography>
                      </Index.Box>

                      <Index.Box className="prof-contact-box">
                        <Index.CallIcon className="prof-contact-icon" />
                        <Index.Typography> {item?.mobileNumber}</Index.Typography>
                      </Index.Box>
                      <Index.Box className="prof-time-box">
                        <Index.CalendarMonthIcon className="prof-time-icon" />
                        <Index.Typography className="prof-time-text">
                          Available :
                        </Index.Typography>
                        <Index.Typography className="text">
                          {findNextAvailableDate(item.availability)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="prof-service-box">
                        <Index.Typography className="prof-service-text-heading">
                          Service :{" "}
                        </Index.Typography>
                        <Index.Typography>{item?.categoryId?.category}</Index.Typography>
                      </Index.Box>

                      <Index.Box className="prof-package-box">
                        <Index.Typography className="prof-package-text-heading">
                          Tagline :
                        </Index.Typography>

                        <Index.Typography className="text">
                          {item?.tagLine}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              ))}
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ProfessionalList;
