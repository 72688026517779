import React from 'react'

import SaveIcon from '@mui/icons-material/Save';
import  LoadingButton  from '@mui/lab/LoadingButton/LoadingButton';


const LoaderButton = ({ className, loading }) => {
    console.log("className",className);
    return (
        <LoadingButton
            type="submit"
            className={className}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
        >
            Save
        </LoadingButton>
    )
}

export default LoaderButton