import { Box, Typography, TextField, Checkbox, TableRow, TableCell, TableBody, TableContainer, Table, TableHead, Button } from '@mui/material'
import "./addEdit.css"
import React from 'react'
import { TypeSpecimenTwoTone } from '@mui/icons-material'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataService } from '../../../../config/DataService';
import { toast } from 'react-toastify';
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Formik } from 'formik';
import * as Yup from "yup"


export default function AddEdit() {
  const [allCheck, setAllCheck] = useState({ tag: "", all: false });
  const navigate = useNavigate();
  const { state } = useLocation();
  const roleAddSchema = Yup.object().shape({
    RoleType: Yup.string().required("Role type is required"),
  })
  const getInitialValues = (state) => {
    const permissions = [
      {
        title: "Admin Master",
        tag: "AdminMaster",
      },
      {
        title: "Role Management",
        tag: "RoleManagement",
      },
      {
        title: "User Management",
        tag: "UserManagement",
      },
      {
        title: "Professional Management",
        tag: "ProfessionalManagement",
      },
      {
        title: "Configuration Management",
        tag: "ConfigurationManagement",
      },
    ];
    const initialValues = {
      RoleType: state?.role?.RoleType || "",
      permission: permissions.map((permission) => ({
        ...permission,
        All: state?.role?.permission?.[permission.tag]?.All || false,
        Add: state?.role?.permission?.[permission.tag]?.Add || false,
        Edit: state?.role?.permission?.[permission.tag]?.Edit || false,
        View: state?.role?.permission?.[permission.tag]?.View || false,
        Delete: state?.role?.permission?.[permission.tag]?.Delete || false,
        Active: state?.role?.permission?.[permission.tag]?.Delete || false,
      })),
    };

    return initialValues;
  };

  const initialValues = getInitialValues(state);
  const handleRoleAddEdit = (values) => {
    const filetredData = values.permission.filter((data) =>
      Object.values(data).some((value) => value === true)
    );
    const role = {};
    filetredData?.forEach((ele) => {
      role[ele?.tag] = ele;
    });
    try {
      const createRole = async () => {
        const response = await DataService.post(
          "/admin/add-edit-permission",
          {
            permissionId: state?.role?._id ? state?.role?._id : undefined,
            RoleType: values.RoleType,
            permission: role,
          }
        );
        if (response.data.status === 201 || response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/admin/role-management");
        } else {
          toast.error(response.data.message);
        }
      };
      createRole();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleAllClick = ({ module, tag, value, setFieldValue, values }) => {
    const AllModules = ["Add", "Edit", "View", "Delete", "Active"];
    initialValues?.permission.map((permission, i) => {
      if (permission.tag == tag) {
        const updatedValues = { ...values };
        updatedValues.permission[i][module] = value;
        if (value) {
          if (module == "All") {
            AllModules.forEach((field) => {
              setFieldValue(`permission[${i}].${field}`, true);
            });
          } else if (AllModules.includes(module)) {
            const allChecked = AllModules.every(
              (field) => values?.permission[i][field]
            );
            setFieldValue(`permission[${i}].All`, allChecked);
          }
        } else {
          if (module == "All") {
            AllModules.forEach((field) => {
              setFieldValue(`permission[${i}].${field}`, false);
            });
          } else if (AllModules.includes(module)) {
            const allChecked = AllModules.every(
              (field) => values?.permission[i][field]
            );
            setFieldValue(`permission[${i}].All`, allChecked);
          }
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={roleAddSchema}
      onSubmit={handleRoleAddEdit}
    >
      {({ values, handleSubmit, handleChange, setFieldValue, errors }) => (
        <Box component="form" onSubmit={handleSubmit} className="main-add-edit">
          <Box className="top-add-edit">
            <Typography component="h5" variant="h5" >
              {state?.role?._id ? "Edit" : "Add"} Role
            </Typography>
            <Box className="main-input">
              <TextField
                className="custom-input"
                id="outlined-basic"
                variant="outlined"
                label="Role Type"
                name="RoleType"
                value={values.RoleType}
                onChange={handleChange}
              />
            </Box>
            {errors.RoleType && (
              <span className='error'> {errors.RoleType} </span>
            )}
          </Box>
          <Box className="main-content">
            <TableContainer >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Modules</TableCell>
                    <TableCell >All</TableCell>
                    <TableCell>Add</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell >View</TableCell>
                    <TableCell >Delete</TableCell>
                    <TableCell >Active/Deactive</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {values?.permission?.map((role, i) => (

                    <TableRow>
                      <TableCell>
                        {role.title}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          name="all"
                          checked={values?.permission[i].All}
                          onChange={(e) => {
                            handleAllClick({
                              module: "All",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values?.permission[i].All}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={values.permission[i].Add}
                          onChange={(e) => {
                            setFieldValue(
                              `permission[${i}].Add`,
                              e.target.checked
                            );
                            handleAllClick({
                              module: "Add",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values.permission[i].Add}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={values.permission[i].Edit}
                          onChange={(e) => {
                            setFieldValue(
                              `permission[${i}].Edit`,
                              e.target.checked
                            );
                            handleAllClick({
                              module: "Edit",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values.permission[i].Edit}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={values.permission[i].View}
                          onChange={(e) => {
                            setFieldValue(
                              `permission[${i}].View`,
                              e.target.checked
                            );
                            handleAllClick({
                              module: "View",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values.permission[i].View}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={values.permission[i].Delete}
                          onChange={(e) => {
                            setFieldValue(
                              `permission[${i}].Delete`,
                              e.target.checked
                            );
                            handleAllClick({
                              module: "Delete",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values.permission[i].Delete}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={values.permission[i].Active}
                          onChange={(e) => {
                            setFieldValue(
                              `permission[${i}].Active`,
                              e.target.checked
                            );
                            handleAllClick({
                              module: "Active",
                              tag: role.tag,
                              value: e.target.checked,
                              setFieldValue,
                              values: values,
                            });
                          }}
                          value={values.permission[i].Active}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </TableContainer>
            <Box sx={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                type="submit"
                variant="contained"
                startIcon={<ControlPointIcon />}
              >
                {state?.role?._id ? "Edit" : "Add"} Role
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>

  )
}
