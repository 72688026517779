

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";

import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import "./Signup.css";
import { DataService } from "../../config/DataService";
import { API } from "../../config/API";

const steps = [
  "Step 1: Personal Information",
  "Step 2: Services Details",
  "Step 3: Portfolio  Details",
  "Step 4: Package Details",
];

export default function ProfessionalSignup() {
  const navigate = useNavigate();
  const data = useLocation();
  const [roleList, setRoleList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [userData, setUserData] = useState({});
  const [subconfigurations, setSubconfigurations] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [subconfigurationsCity, setSubconfigurationsCity] = useState([]);
  const [cityId, setCityId] = useState("");
  const userId = userData?._id;

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setSubmitted(true);

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const initialValues = {
    firstName: userData?.firstName || "",
    email: userData?.email || "",
    city: userData?.city || "",
    language: userData?.language || "",
    mobileNumber: userData?.mobileNumber || "",
    categoryId: "",
    gender: "",
    nationality: userData?.nationality || "",
    tagLine: userData?.tagLine || "",
    DOB: userData?.DOB || "",
    profile: userData?.profile || "",
    Portfolio: userData?.Portfolio || "",
    packages: [{
      packageName: "",
      description: ""
    }],

    services: [{
      serviceName: ""
    }],
    newPackageName: "",
    newDescription: "",
  };

  const fetchRoleList = async () => {
    try {
      const response = await DataService.get(API.ADMIN.CATEGORY_LIST);

      setRoleList(response?.data?.data);
    } catch (error) {
      console.error("Error fetching category list:", error);
    }
  };

  const signupSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter name"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email"),
    mobileNumber: Yup.number().required("Please enter phone number"),
    // categoryId: Yup.string().required("Please select category"),
    // gender: Yup.string().required("Please enter gender"),
    nationality: Yup.string().required("Please enter nationality"),
    tagLine: Yup.string().required("Please enter tagLine"),
    language: Yup.string().required("Please enter language"),
    city: Yup.string().required("Please enter city"),
    DOB: Yup.date().required("Please enter DOB"),
    profile: Yup.string().required("Please enter profile"),
    // Portfolio: Yup.string().required("Please enter Portfolio"),
    packages: Yup.array().required("Please enter package name"),
    // description: Yup.array().required("Please enter description"),
    // services: Yup.array().required("Please enter services"),
  });

  const getSingleUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { auth: token };
      const res = await DataService.get(API.USER.USER_GET_SINGLE, { headers });
      console.log(res?.data, "159");
      if (res?.data?.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleData = async (values, { resetForm }) => {


    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("firstName", values?.firstName || "");
    formData.append("email", values?.email || "");
    formData.append("city", values?.city || "");
    formData.append("language", values?.language || "");
    formData.append("mobileNumber", values?.mobileNumber || "");
    formData.append("categoryId", values?.categoryId || "");
    formData.append("gender", userData?.gender?._id || "");
    formData.append("nationality", values?.nationality || "");
    formData.append("tagLine", values?.tagLine || "");
    formData.append("DOB", values?.DOB || "");
    formData.append("profile", values.profile);
    Array.from(values.Portfolio).forEach((image) => {
      formData.append("Portfolio", image);
    });
    formData.append("services", JSON.stringify(values.services))
    formData.append("packages", JSON.stringify(values.packages))
    console.log({ formData });

    await DataService.post(API.USER.PROFESSIONAL_SIGNUP, formData)

      .then((res) => {
        if (res.data.status == 201) {
          toast.success(
            res.data.message || "Professional registered successfully"
          );
          localStorage.setItem("userData", JSON.stringify(res.data.data));

          resetForm();
          // setTimeout(() => {
            navigate("/user/login");
          // }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err?.res?.data?.message, "error");
        console.error(err);
      });
  };

  useEffect(() => {
    fetchRoleList();
    getSingleUser()
  }, []);

  const getAllConfigurationData = async (value) => {
    try {
      const res = await DataService.get(API.USER.CONFIGURATION_GET_ALL, value);
      if (res?.data?.status === 200) {
        const configurations = res.data.data;
        const languageConfig = configurations.find(config => config.title === "Language");
        const cityConfig = configurations.find(config => config.title === "City");
        setLanguageId(languageConfig._id);
        setCityId(cityConfig._id);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const getAllCity = async () => {
    try {
      const response = await DataService.get(
        `/common/get-all-subconfiguration/${cityId}`
      );
      if (response.status === 200) {
        setSubconfigurationsCity(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getAllSubConfiguration = async () => {
    try {
      const response = await DataService.get(
        `/common/get-all-subconfiguration/${languageId}`
      );
      console.log(response.data, "genderid88888");
      if (response.status === 200) {
        setSubconfigurations(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllConfigurationData();
  }, []);

  useEffect(() => {
    if (languageId) {
      getAllSubConfiguration();
    }
  }, [languageId]);

  useEffect(() => {
    if (cityId) {
      getAllCity();
    }
  }, [cityId]);

  return (
    <>
      <Box sx={{ width: "100%" }} className="addAdmin_form">
        <Stepper activeStep={activeStep}></Stepper>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={signupSchema}
          onSubmit={handleData}
        >
          {({ values, handleSubmit, handleChange, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {console.log("errors", errors)}
              {console.log("values", values)}
              {activeStep === 0 && (
                <div className="addAdmin_activity_zero_form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Name</Typography>
                      <TextField
                        type="text"
                        placeholder="Name"
                        fullWidth
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                      >
                        Name
                        {console.log(errors, 56757654)}
                      </TextField>
                      {errors.firstName && (
                        <p className="err">{errors.firstName}</p>
                      )}
                    </Grid>
                    <Grid item xs={4} md={6} lg={6}>
                      <Typography>Email</Typography>

                      <TextField
                        type="email"
                        placeholder="Email"
                        fullWidth
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      >
                        Email
                      </TextField>
                      {console.log(errors)}
                      {errors.email && <p className="err">{errors.email}</p>}
                    </Grid>
                    <Grid item xs={8} md={6} lg={6}>
                      <Typography>Mobile Number</Typography>

                      <TextField
                        type="tel"
                        placeholder="Mobile Number"
                        fullWidth
                        name="mobileNumber"
                        value={values.mobileNumber}
                        onChange={handleChange}
                      >
                        Phone Number
                      </TextField>
                      {errors.mobileNumber && (
                        <p className="err">{errors.mobileNumber}</p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>City</Typography>

                      <TextField
                        type="text"
                        fullWidth
                        defaultValue={userData?.city}
                        value={values.city}
                        onChange={handleChange}
                        name="city"
                        select
                      >
                        {subconfigurationsCity.map((item) => (

                          <MenuItem key={item._id} value={item._id}>
                            {item.title}
                          </MenuItem>
                        ))}

                      </TextField>
                      {errors.city && <p className="err">{errors.city}</p>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Gender</Typography>

                      <TextField
                        type="text"
                        placeholder="Gender"
                        fullWidth
                        value={userData?.gender?.title}
                        // onChange={handleChange}
                        name="gender"
                      >
                        Gender
                      </TextField>
                      {errors.gender?.title && <p className="err">{errors.gender?.title}</p>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>DOB</Typography>

                      <TextField
                        type="date"
                        placeholder="Date of Birth"
                        fullWidth
                        value={
                          values.DOB
                            ? new Date(values.DOB)?.toISOString().split("T")[0]
                            : ""
                        }
                        onChange={handleChange}
                        name="DOB"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        DOB
                      </TextField>
                      {errors.DOB && <p className="err">{errors.DOB}</p>}
                    </Grid>{" "}
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Language</Typography>
                      <TextField
                        type="text"
                        label="Select language"
                        fullWidth
                        className='down-select'
                        name="language"
                        value={values.language}
                        onChange={handleChange}
                        select
                      >

                        {subconfigurations.map((item) => (

                          <MenuItem key={item._id} value={item._id}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </TextField>
                      {errors.language && (
                        <p className="err">{errors.language}</p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Nationality</Typography>

                      <TextField
                        type="text"
                        placeholder="nationality"
                        fullWidth
                        value={values.nationality}
                        onChange={handleChange}
                        name="nationality"
                      >
                        Nationality
                      </TextField>
                      {errors.nationality && (
                        <p className="err">{errors.nationality}</p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography> Tag Line</Typography>

                      <TextField
                        type="text"
                        placeholder=" Tag Line"
                        fullWidth
                        value={values.tagLine}
                        onChange={handleChange}
                        name="tagLine"
                      >
                        Tag Line
                      </TextField>
                      {errors.tagLine && (
                        <p className="err">{errors.tagLine}</p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Profile</Typography>

                      <TextField
                        className="form-field"
                        type="file"
                        id="profile"
                        placeholder="Please select file"
                        name="profile"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setFieldValue("profile", e.target.files[0]);
                        }}
                      >
                        Profile
                      </TextField>
                      {errors.profile && <p className="err">{errors.profile}</p>}
                    </Grid>
                  </Grid>
                </div>
              )}
              {console.log(errors)}
              {console.log({ values })}

              {activeStep === 1 && (
                <div className="addAdmin_activity_zero_form">
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={6} lg={6}>
                      <Typography>Category</Typography>
                      <TextField
                        type="text"
                        placeholder="Select category"
                        fullWidth
                        name="categoryId"
                        value={values?.categoryId}
                        onChange={handleChange}
                        select
                      >
                        <MenuItem value="" disabled>
                          Select Category
                        </MenuItem>
                        {roleList.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.category}
                          </MenuItem>
                        ))}
                      </TextField>
                      {errors.categoryId && (
                        <p className="err">{errors.categoryId}</p>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className="services_className">
                      <FieldArray name="services">
                        {({ push, remove, form }) => (
                          <>
                            <Typography className="services_text">Services</Typography>
                            {values?.services?.map((service, index) => (
                              <div key={index} className="services_className_combine_div">
                                <TextField
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                  name={`services.${index}.serviceName`}
                                  value={values.services[index].serviceName || ""}
                                  placeholder="Service Name"
                                  className="services_className_combine"
                                />
                                {errors.services && errors.services && (
                                  <p className="err">{errors.services}</p>
                                )}
                                <Box>
                                  <Button
                                    variant="contained"
                                    onClick={() => remove(index)}
                                    className="services_className_combine remove_btn"
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </div>
                            ))}
                            <Box className="services_addservices_div">
                              <Button
                                variant="contained"
                                onClick={() => push('')}
                                className="services_className_combine add_btn"
                              >
                                Add Service
                              </Button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </div>
              )}

              {activeStep === 2 && (
                <div className="addAdmin_activity_zero_form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography>Portfolio</Typography>

                      <TextField
                        type="file"
                        id="Portfolio"
                        placeholder="please select file"
                        name="Portfolio"
                        accept="image/png, image/gif, image/jpeg"
                        value={values.file}
                        onChange={(e) => {
                          setFieldValue("Portfolio", e.target.files);
                        }}
                        inputProps={{
                          multiple: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              {activeStep === 3 && (
                <div className="addAdmin_activity_zero_form">
                  <Grid container spacing={2}>
                    <Grid container spacing={5} className="pkg_grid">
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={12}
                        className="pkg_name_grid"
                      >
                        <FieldArray name="packages">
                          {({ push, remove, form }) => (
                            <>
                              {form?.values?.packages?.map(
                                (packages, index) => (
                                  <div
                                    key={index}
                                    className="sdfweiiooosdfweeww"
                                  >
                                    <Grid
                                      container
                                      className="sdlfjweoixcvnsmsdfhe"
                                    >
                                      <Grid item xs={12} md={12} lg={6}>
                                        <Typography>Package Name</Typography>
                                        <TextField
                                          type="text"
                                          name={`packages.${index}.packageName`}
                                          value={
                                            values.packages[index].packageName || ""
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          placeholder="Package Name"
                                        />
                                        {errors.packages && (
                                          <p className="err">
                                            {errors.packages}
                                          </p>
                                        )}
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={6}
                                        className="DES_BOX"
                                      >
                                        <Typography className="des_text">
                                          Description
                                        </Typography>
                                        <textarea
                                          className="DES"
                                          name={`packages.${index}.description`}
                                          multiple
                                          placeholder="description"
                                          style={{
                                            width: "100%",
                                            height: "100px",
                                          }}
                                          value={
                                            values.packages[index].description || ""
                                          }
                                          onChange={handleChange}
                                        />
                                        {errors.packages && (
                                          <p className="err">
                                            {errors.packages}
                                          </p>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Box className="remove_btn_box">
                                      <Button
                                        className="remove_btn"
                                        variant="contained"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  </div>
                                )
                              )}
                              <Box className="services_addservices_div">
                                <Button
                                  className="services_className_combine remove_btn"
                                  variant="contained"
                                  onClick={() => push("")}
                                >
                                  Add Package
                                </Button>
                              </Box>
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box className="skip_btn_box">
                  <Button
                    color="inherit"
                    variant="contained"
                    className="skip_btn"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                </Box>
                <Box sx={{ flex: "1 1 auto" }} />
                <Box className="skip_btn_box">
                  {isStepOptional(activeStep) && (
                    <Button
                      className="skip_btn"
                      variant="contained"
                      color="inherit"
                      onClick={handleSkip}
                      sx={{ mr: 1 }}
                    >
                      Skip
                    </Button>
                  )}
                </Box>

                <Box className="add_btn_box">
                  <Button>
                    {activeStep == steps.length - 1 ? (
                      <Button
                        variant="contained"
                        className="add_btn"
                        type="submit"
                      >
                        Signup
                      </Button>
                    ) : (
                      <Box className="next_btn_box" onClick={handleNext}>
                        <Button variant="contained"> Next </Button>
                      </Box>
                    )}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}
