import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./common.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { DataService } from "../../config/DataService";

const AddEditAdminModel = ({
  data,
  setData,
  open,
  setOpen,
  handelSubmitButton,
}) => {
  const [permissions, setPermissions] = useState([]);
  const handleClose = () => {
    setData(null);
    setOpen(false);
  };
  let initialValues = {
    firstName: data?.firstName
      ? data?.firstName
      : data?.firstName
      ? data?.firstName
      : "",
    lastName: data?.lastName ? data?.lastName : "",
    email: data?.email ? data?.email : "",
    roleType: data?.roleType ? data?.roleType : "",
    password: data?.password ? data?.password : "",
    confirmPassword: data?.confirmPassword ? data?.confirmPassword : "",
  };

  let adminSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    roleType: Yup.string().required("Role type is required"),
    email: Yup.string().required("Email is required"),
  });
  if (!data) {
    initialValues.password = "";
    adminSchema.password = Yup.string().required("Password is required");
    adminSchema.confirmPassword = Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password");
  }
  useEffect(() => {
    try {
      const getAllRoles = async () => {
        const response = await DataService.get("/admin/get-all-permission");
        if (response.data.status === 200) {
          setPermissions(response.data.data);
        }
      };
      getAllRoles();
    } catch (error) {
      toast.error(error.message);
    }
  }, []);
  return (
    <Modal
      className="cus-main-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="cus-modal">
        <Typography
          className="modal-heading-text"
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          {data ? "Edit" : "Add"} Admin
        </Typography>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={adminSchema}
            onSubmit={handelSubmitButton}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
            }) => (
              <Box
                className="admin-form"
                component="form"
                onSubmit={handleSubmit}
              >
                <Box className="admin-input">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <span className="error"> {errors.firstName} </span>
                  )}
                </Box>
                <Box className="admin-input">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <span className="error"> {errors.lastName} </span>
                  )}
                </Box>
                <Box className="admin-input">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error"> {errors.email} </span>
                  )}
                </Box>
                <Box className="admin-input">
                  <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Role Type
                    </InputLabel>
                    <Select
                      sx={{ width: "100%" }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={values.roleType}
                      name="roleType"
                      size="small"
                      onChange={(e) =>
                        setFieldValue("roleType", e.target.value)
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {permissions?.map((permission) => (
                        <MenuItem value={permission._id}>
                          {permission.RoleType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {errors.roleType && (
                  <span className="error"> {errors.roleType} </span>
                )}
                {!data && (
                  <>
                    <Box className="admin-input">
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <span className="error"> {errors.password} </span>
                      )}
                    </Box>
                    <Box className="admin-input">
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Confirm Password"
                        variant="outlined"
                        name="confirmPassword"
                        onChange={handleChange}
                      />
                      {errors.confirmPassword && (
                        <span className="error">
                          {" "}
                          {errors.confirmPassword}{" "}
                        </span>
                      )}
                    </Box>
                  </>
                )}
                <Box className="main-button-modal">
                  <Box className="first-cancel-btn">
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setData(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box className="second-delete-btn">
                    <Button type="submit">save</Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEditAdminModel;
