import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import RatingModal from "./RatingModal";
import "./MyBooking.css";

const MyBooking = () => {
  const [booking, setBooking] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [type, setType] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getBooking = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.USER_GET_BOOKING
      );
      if (res.data.status === 200) {
        setBooking(res?.data?.data);
        setFilterData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getBooking();
  }, []);


  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = booking?.filter((item) => {
      const fullName = `${item?.professionalId?.firstName} ${item?.professionalId?.lastName}`.toLowerCase();
      const matchesFullName = fullName.includes(search);
      const matchesDate = item?.date && item?.date.includes(search);
      const matchesPackageName = item?.packagesId?.some(pkg => pkg.packageName?.toLowerCase().includes(search));
      const matchesStatus = item?.status && item?.status?.toLowerCase().includes(search);
      const matchesRating = item?.averageRating && item?.averageRating == search;
      
      return matchesFullName || matchesDate || matchesPackageName || matchesStatus || matchesRating;
    });
    setFilterData(res);
};


  return (
    <>
      <div style={{ margin: "25px" }}>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Booking List{" "}
          </Index.Typography>

          <Index.Box className="heading-add-box">
            {/* <Index.TextField size="small" placeholder="Search" /> */}
            {/* <Index.Button onClick={() => {}} className='add-button'>
        Add Package
       </Index.Button> */}

            <Index.TextField
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
          </Index.Box>
        </Index.Box>

        <Index.TableContainer
          className="packages-table-container booking-list"
          sx={{ overflow: "scroll" }}
        >
          <Index.Table
            className="package-table"
            stickyHeader
            aria-label="sticky table"
          >
            <Index.TableHead className="table-head">
              <Index.TableCell className="tableCell">S.No</Index.TableCell>
              <Index.TableCell className="tableCell">Image </Index.TableCell>
              <Index.TableCell className="tableCell">
                Professional Name
              </Index.TableCell>
              {/* <Index.TableCell className="tableCell">Service</Index.TableCell> */}
              <Index.TableCell className="tableCell">Date</Index.TableCell>
              <Index.TableCell className="tableCell">Package</Index.TableCell>
              <Index.TableCell className="tableCell">Status</Index.TableCell>
              <Index.TableCell className="tableCell">Rating</Index.TableCell>
            </Index.TableHead>

            {filterData &&
              filterData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((data, index) => (
                  <Index.TableBody className="table-body">
                    <Index.TableCell>{index + 1 + page * rowsPerPage}</Index.TableCell>
                    <Index.TableCell>
                      <img
                        src={`${PageIndex.imageURL}/${data?.professionalId?.profile}`}
                        style={{
                          height: "38px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Index.TableCell>
                    <Index.TableCell>{`${data?.professionalId?.firstName} ${data?.professionalId?.lastName}`}</Index.TableCell>
                    {/* <Index.TableCell>{data?.professionalId?.services?.serviceName?.map((servic,index)=>(
                <p>{servic}</p>
              ))}</Index.TableCell> */}
                    <Index.TableCell>{data?.date}</Index.TableCell>
                    <Index.TableCell>
                      {data?.packagesId[0]?.packageName}
                    </Index.TableCell>
                    <Index.TableCell>{data?.status} </Index.TableCell>
                    <Index.TableCell>
                      {data?.averageRating ? (
                        <Index.Typography className="rating-text"
                          onClick={() => {
                            setOpen(true);
                            setData(data);
                            setType("read");
                          }}>{data?.averageRating}</Index.Typography>
                      ) : (
                        <>
                          {data?.status == "Completed" ? (
                            <Index.Button
                              onClick={() => {
                                setOpen(true);
                                setData(data);
                                setType("write");
                              }}
                              className="rating-btn"
                            >
                              rating
                            </Index.Button>
                          ) : "-"}
                        </>
                      )}
                    </Index.TableCell>
                  </Index.TableBody>
                ))}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={booking.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <RatingModal
        open={open}
        setOpen={setOpen}
        setData={setData}
        data={data}
        getBooking={getBooking}
        type={type}
      />
    </>
  );
};

export default MyBooking;
