import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import "./common.css"
import { Formik } from "formik";
import * as Yup from "yup"

const AddEditUserModel = ({ type,data, setData, open, setOpen, handelSubmitButton }) => {
    const handleClose = () => {
        setData(null);
        setOpen(false);
    }
    let initialValues = {
        firstName: data?.firstName ? data?.firstName : data?.firstName ? data?.firstName : "",
        lastName: data?.lastName ? data?.lastName : "",
        email: data?.email ? data?.email : "",
        city: data?.city ? data?.city : ""
    }

    let userSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required"),
        city: Yup.string().required("City is required"),
    })
    if (!data) {
        initialValues.password = "";
        userSchema.password = Yup.string().required("Password is required");
    }
    return (
        <Modal
            className="cus-main-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="cus-modal">
                <Typography
                    className="modal-heading-text"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    {data ? "Edit" : "Add"} {type}
                </Typography>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={userSchema}
                        onSubmit={handelSubmitButton}
                    >
                        {({ values, handleSubmit, handleChange, errors }) => (
                            <Box className='admin-form' component="form" onSubmit={handleSubmit} >
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                    />
                                    {errors.firstName && (
                                        <span className='error'> {errors.firstName} </span>
                                    )}
                                </Box>
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                    />
                                    {errors.lastName && (
                                        <span className='error'> {errors.lastName} </span>
                                    )}
                                </Box>
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && (
                                        <span className='error'> {errors.email} </span>
                                    )}
                                </Box>
                                {
                                    !data && (
                                        <Box className="admin-input">
                                            <TextField
                                                size="small"
                                                id="outlined-basic"
                                                label="Password"
                                                variant="outlined"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                            {errors.password && (
                                                <span className='error'> {errors.password} </span>
                                            )}
                                        </Box>
                                    )
                                }
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="City"
                                        variant="outlined"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                    />
                                    {errors.city && (
                                        <span className='error'> {errors.city} </span>
                                    )}
                                </Box>
                                <Box className="main-button-modal">
                                    <Box className="first-cancel-btn">
                                        <Button onClick={() => { setOpen(false); setData(null) }}>Cancel</Button>
                                    </Box>
                                    <Box className="second-delete-btn">
                                        <Button type="submit" >save</Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Formik>
                </Box>

            </Box>
        </Modal>
    )
}

export default AddEditUserModel