import React, { useState } from "react";
import "./Booking.css";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RatingModal from "../../../user/pages/booking/RatingModal";

const Booking = () => {
  const [bookingData, setBookingData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectId, setSelectId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, id) => {
    setSelectId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBooking = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.PROFESSIONAL_GET_BOOKING
      );
      if (res.data.status === 200) {
        setBookingData(res?.data?.data);
        setFilterData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  PageIndex.useEffect(() => {
    getBooking();
  }, []);

  const approveBooking = async (status) => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.PROFESSIONAL_STATUS_BOOKING,
        {
          bookingId: selectId,
          status: status,
        }
      );
      if (res.data.status === 200) {
        getBooking();
        PageIndex.toast.success(res?.data?.message);
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = bookingData?.filter((item) => {
      const fullName = `${item?.userId?.firstName} ${item?.userId?.lastName}`.toLowerCase();
      const matchesFullName = fullName.includes(search);
      const matchesNumber = item?.userId && item?.userId?.mobileNumber.toString().includes(search); 
      const matchesDate = item?.date && item?.date.includes(search);
      const matchesPackageName = item?.packagesId?.some(pkg => pkg.packageName?.toLowerCase().includes(search));
      const matchesStatus = item?.status && item?.status?.toLowerCase().includes(search);
      const matchesRating = item?.averageRating && item?.averageRating == search;

      return matchesFullName || matchesDate || matchesPackageName || matchesStatus || matchesNumber || matchesRating;
    });
    setFilterData(res);
  };

  return (
    <>
      <div style={{ margin: "25px" }}>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Booking List{" "}
          </Index.Typography>

          <Index.Box className="heading-add-box">
            {/* <Index.TextField size="small" placeholder="Search" /> */}
            {/* <Index.Button onClick={() => {}} className='add-button'>
  Add Package
 </Index.Button> */}
            <Index.TextField
              className="search-filter-heading"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
          </Index.Box>
        </Index.Box>

        <Index.TableContainer
          className="packages-table-container booking-list"
          sx={{ overflow: "scroll" }}
        >
          <Index.Table
            className="package-table"
            stickyHeader
            aria-label="sticky table"
          >
            <Index.TableHead className="table-head">
              <Index.TableCell className="tableCell">S.No</Index.TableCell>
              <Index.TableCell className="tableCell">Image </Index.TableCell>
              <Index.TableCell className="tableCell">User Name</Index.TableCell>
              <Index.TableCell className="tableCell">Number</Index.TableCell>
              <Index.TableCell className="tableCell">Date</Index.TableCell>
              <Index.TableCell className="tableCell">Package</Index.TableCell>
              <Index.TableCell className="tableCell">Status</Index.TableCell>
              <Index.TableCell className="tableCell">Rating</Index.TableCell>
              <Index.TableCell className="tableCell">Action</Index.TableCell>
              <Index.TableCell className="tableCell"></Index.TableCell>
            </Index.TableHead>

            {filterData &&
              filterData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => (
                  <Index.TableBody className="table-body">
                    <Index.TableCell>
                      {index + 1 + page * rowsPerPage}
                    </Index.TableCell>
                    <Index.TableCell>
                      {/* <img src={data?.userId?.profile}/> */}
                      <img
                        src={`${PageIndex.imageURL}/${item?.userId?.profile}`}
                        style={{
                          height: "38px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Index.TableCell>
                    <Index.TableCell>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</Index.TableCell>
                    <Index.TableCell>
                      {item?.userId?.mobileNumber}
                    </Index.TableCell>
                    <Index.TableCell>{item?.date}</Index.TableCell>
                    <Index.TableCell>
                      {item?.packagesId[0]?.packageName}
                    </Index.TableCell>
                    <Index.TableCell>{item?.status}</Index.TableCell>
                    {console.log({ item })}
                    <Index.TableCell className="booking-table-btn-box">
                {item?.averageRating?
                        <Index.Typography
                          className="rating-text"
                          onClick={() => {
                            setOpen(true);
                            setData(item);
                          }}
                        >
                          {item?.averageRating }
                        </Index.Typography>
                        :"-"}
                  
                      {/* <Index.Button className="approved-btn" disabled={data?.status=="Approved"} onClick={()=>approveBooking(data?._id,"Approved")}>Approve</Index.Button>
              <Index.Button className="rejected-btn" disabled={data?.status=="Rejected"} onClick={()=>approveBooking(data?._id,"Rejected")}>Reject</Index.Button> */}
                    </Index.TableCell>
                    <Index.TableCell>
                    {item?.status !== "Completed" ?
                    <>
                          <MoreVertIcon
                            aria-controls={`menu-${index}`}
                            aria-haspopup="true"
                            onClick={(event) => {
                              handleClick(event, item?._id);
                            }}
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                          />
                          <Menu
                            id={`menu-${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                approveBooking("Approved");
                              }}
                            >
                              Approve
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                approveBooking("Rejected");
                              }}
                            >
                              Reject
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                approveBooking("Completed");
                              }}
                            >
                              Complete
                            </MenuItem>
                          </Menu>
                        </>
                           :"-" }
                    </Index.TableCell>
                    
                  
                  </Index.TableBody>
                ))}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={bookingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <RatingModal
        open={open}
        setOpen={setOpen}
        data={data}
        setData={setData}
        type={"read"}
      />
    </>
  );
};

export default Booking;
