import React from 'react'
import './UserAuth.css';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { userForgotPassword } from '../../../validation/UserValidation';
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/API";

function ForgotPassword() {
    const initialValues = {
        email: "",
    }

    const handleFormSubmit = async (value) => {
        try {
            const res = await DataService.post(API.USER.USER_FORGOT_PASSWORD, value);
            if (res.data.status == 200) {
                toast.success(res?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, "error");
        }
    }

    return (
        <div className='meanClass'>
            <div className="left "></div>
            <div className="rigth common">
                <div className='forgot-page'>
                    <h2 className='user-heding'>Forgot Password!</h2>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validationSchema={userForgotPassword}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <Form className='main-form ' onSubmit={handleSubmit}>
                                <label className='user-from' htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" placeholder="Enter your email"
                                    value={values.email} onChange={handleChange} />
                                {errors.email && touched.email ? (<p className='from-error'>{errors.email}</p>) : null}

                                <div class="user-submit-button">
                                    <button type="submit">Forgot Password</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword
