import React from "react";
import "./Login.css";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/API";

import * as Yup from "yup"
const userLoginValidation = Yup.object().shape((
  {
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  }
));

const Login = () => {
  const navigate = useNavigate();
  const handleSubmit = async (value) => {
    try {
      const res = await DataService.post(API.ADMIN.ADMIN_LOGIN, value);
      if (res.data.status == 200) {
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.data.token);
        delete res.data.data.token
        localStorage.setItem("admin", JSON.stringify(res.data.data));
        navigate("/admin/dashboard");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, "error");
    }
  };
  return (
    <>
      <Box className="login">
        <Box className="login-main-box">
          <Box className="login-header-box">Admin Login</Box>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            validationSchema={userLoginValidation}
          >
            {({ values, errors, handleChange, handleSubmit,handleBlur ,touched}) => (
              <form onSubmit={handleSubmit} style={{margin:"0"}}>
                <Box className="login-textfield-box">
                  <TextField
                    size="small"
                    className="login-textfield"
                    placeholder="email"
                    name="email"
                    onBlur={handleBlur}
                    type="email"
                    onChange={handleChange}
                    value={values?.email}
                  />
                {errors?.email && touched?.email && (
                    <Typography color="error">{errors?.email}</Typography>
                  )}
                  <TextField
                    size="small"
                    name="password"
                    type="password"
                    className="login-textfield"
                    placeholder="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.password}
                  />
                  {errors?.password && touched?.password && (
                    <Typography color="error">{errors?.password}</Typography>
                  )}
                  <Box>
                    <Button className="login-btn" type="submit">
                      Login
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
         </Box>
      </Box>  
    </>
  );
};

export default Login;
