import React, { useEffect } from 'react'
import { Box, Button, FormControl, Input, InputLabel, TextField, Typography } from '@mui/material'
// import "./admin-master.css"
import { Formik } from 'formik'
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import * as Yup from "yup"
import { useLocation, useNavigate } from 'react-router-dom';
import { DataService } from '../../../../config/DataService';
import { toast } from 'react-toastify';
import ViewCard from '../../../../components/common/ViewCard';
const UserView = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    return (
        <Box className="main-add-edit">
            <Box className="top-add-edit">
                <Typography component="h5" variant="h5" >
                    View User
                </Typography>
            </Box>
            <ViewCard user={state.user} />
            {/* <Box className="admin-master">
                <Box className='admin-form'  >
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            name="firstName"
                            disabled={true}
                            value={state?.user?.firstName}
                        />
                    </Box>
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            name="lastName"
                            disabled={true}
                            value={state?.user?.lastName}
                        />
                    </Box>
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            name="email"
                            disabled={true}
                            value={state?.user?.email}
                        />
                    </Box>
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="City"
                            variant="outlined"
                            name="city"
                            disabled={true}
                            value={state?.user?.city}
                        />
                    </Box>
                </Box>
            </Box> */}
        </Box>
    )
}

export default UserView