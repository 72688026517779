import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import "./MyBooking.css";
import StarIcon from "@mui/icons-material/Star";

const RatingModal = ({ open, setOpen, data, setData, getBooking, type }) => {
  const [questionData, setQuestionData] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setQuestionData([]);
    setData([]);
    setRatingData([]);
  };
  const [ratingData, setRatingData] = useState([]);
  console.log({ ratingData });

  // const handleRatingChange = (data, id) => {
  //   setRatingData((prevData) => [
  //     ...prevData,
  //     { questionsId: id, rating: data },
  //   ]);
  // };

  const handleRatingChange = (data, id) => {
    // Check if the question has already been rated
    const existingRatingIndex = ratingData.findIndex(item => item.questionsId === id);
  
    if (existingRatingIndex !== -1) {
      // If the question has already been rated, update the rating
      setRatingData(prevData => {
        const updatedRatingData = [...prevData];
        updatedRatingData[existingRatingIndex] = { questionsId: id, rating: data };
        return updatedRatingData;
      });
    } else {
      // If the question has not been rated yet, add a new rating
      setRatingData(prevData => [...prevData, { questionsId: id, rating: data }]);
    }
  };
  
  const submitRating = async () => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.USER_ADD_RATING_QUESTION,
        {
          professionalId: data?.professionalId?._id,
          questions: ratingData,
          bookingId: data?._id,
        }
      );
      if (res?.data?.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        handleClose();
        getAllRating();
        getBooking();
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const getAllRating = async () => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.USER_GET_ALL_QUESTION_RATING,
        {
          professionalId: data?.professionalId?._id,
          bookingId: data?._id,
        }
      );
      if (res?.data?.status == 200) {
        setQuestionData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    if (data?._id) {
      getAllRating();
    }
  }, [open]);

  return (
    <div>
      <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box className="question-modal-main">
          <Index.Box className="prof-heading-box">
            <Index.Typography className="prof-heading-text">
              Rating
            </Index.Typography>
          </Index.Box>
          {questionData &&
            questionData?.map((ques, index) => (
              <Index.Box className="question-modal-middle-main-box">
                <Index.Box className="question-modal-middle-box">
                  <Index.Typography className="question-text">
                    {`${index + 1}.`} {ques?.question}
                  </Index.Typography>
                  <Index.Rating
                    className="question-rating"
                    name="rating"
                    // value={ratings}
                    readOnly={type == "read"}
                    defaultValue={ques?.userRating?.[0]?.rating}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      handleRatingChange(newValue, ques._id);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </Index.Box>
              </Index.Box>
            ))}
          {type == "read" ? (
            ""
          ) : (
            <Index.Box className="main-button-modal">
              <Index.Box className="first-cancel-btn">
                <Index.Button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Index.Button>
              </Index.Box>
              <Index.Box className="second-delete-btn">
                <Index.Button
                  onClick={() => {
                    submitRating();
                  }}
                >
                  Submit
                </Index.Button>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </div>
  );
};

export default RatingModal;
