import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Paper, styled } from "@mui/material";
import Jpg from "../../assets/images/jpg";
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import PageIndex from "../../container/PageIndex";
import Index from "../../container/Index";
import RatingModal from "../../container/user/pages/booking/RatingModal";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function ViewCardProfessional({ user }) {
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };


    const [page1, setPage1] = React.useState(0);
    const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  
    const handleChangePage1 = (event, newPage) => {
      setPage1(newPage);
    };
  
    const handleChangeRowsPerPage1 = (event) => {
      setRowsPerPage1(+event.target.value);
      setPage1(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const[booking,setBooking]=useState([]);

      const getBookingData =async()=>{
         try {
             const res = await PageIndex.DataService.get(`${PageIndex.API.ADMIN.PROFESSIONAL_BOOKING_LIST}?professionalId=${user?._id}`);
             if(res?.data?.status == 200){
                 setBooking(res?.data?.data);
             }
         } catch (error) {
             console.log(error,"error");
         }
      }
         PageIndex.useEffect(()=>{
         getBookingData();
         },[])
     

    return (
      <>
        <Box
            className="my-profile"
            sx={{ marginTop: "30px", minWidth: 275, overflow: "auto" }}
        >
            <Card className="my-profile-card" variant="outlined">
                <CardContent className="my-profile-content">
                    <Box className="custom-container">
                        <Grid container >
                            <Grid item sm={3}>
                                <Box className="profile-section">
                                    <Box className="profile-image">
                                    {user?.profile?
                                         <img
                                         src={`${PageIndex.imageURL}/${user?.profile}`}/>
                                        :
                                        <img src={Jpg.profile} alt="profile" />
                                      }
                                    </Box>
                                    <Box className="profile-name">
                                        <Typography sx={{ textTransform: "capitalize" }} variant="h6">
                                            {user?.firstName} {user?.lastName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={9}>
                                <Box className="profile-content-section">
                                    <Box className="profile-content-title">
                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    email :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.email ? user?.email : "-"}
                                            </Typography>
                                        </Box>

                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    gender :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.gender?.title ? user?.gender?.title : "-"}
                                            </Typography>
                                        </Box>

                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    language :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.language?.title ? user?.language?.title : "-"}
                                            </Typography>
                                        </Box>

                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    nationality :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.nationality ? user?.nationality : "-"}
                                            </Typography>
                                        </Box>

                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">city : </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.city?.title ? user?.city?.title : "-"}
                                            </Typography>
                                        </Box>
                                        <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">Category : </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.categoryId?.category ? user?.categoryId?.category : "-"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Index.Box>
          <Index.Box
            sx={{
              width: "100%",
              border: "0.5px solid gray",
            }}
          >
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tap-heading-box"
              >
                <Index.Tab label="Service" className="tap-heading-prof" />
                <Index.Tab label="Package" className="tap-heading-prof" />
                <Index.Tab label="Portfolio" className="tap-heading-prof" />
                <Index.Tab label="Booking" className="tap-heading-prof" />
                {/* <Index.Tab label="Reviews" className="tap-heading-prof" /> */}
              </Index.Tabs> 
            </Index.Box>
            {value == 0 && (
              <Index.Box className="service-box-container">
                <Index.Box className="servies-list-detail">
                  <ul>
                    {user?.services?.map((items, index) => (
                      <>
                        <li className="text">{items?.serviceName}</li>
                      </>
                    ))}
                  </ul>
                </Index.Box>
              </Index.Box>
            )}
            {value == 1 && (
              <>
              <Index.TableContainer className="packages-table-container"  sx={{overflow:"scroll"}}>
                <Index.Table stickyHeader aria-label="sticky table">
                  <Index.TableHead className="table-head">
                    <Index.TableCell
                      align="center"
                      className="table-heading-cell"
                    >
                      No.
                    </Index.TableCell>
                    <Index.TableCell className="table-heading-cell">
                      Package{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-head-last-row">
                      Description
                    </Index.TableCell>
                  </Index.TableHead>
                  {user?.packages?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items, index) => (
                    <Index.TableBody className="table-body">
                      <Index.TableCell align="center">
                        {index + 1 + page * rowsPerPage}
                      </Index.TableCell>
                      <Index.TableCell>{items?.packageName}</Index.TableCell>
                      <Index.TableCell>{items?.description}</Index.TableCell>
                    </Index.TableBody>
                  ))}
                </Index.Table>
              </Index.TableContainer>
                 <Index.TablePagination
                 rowsPerPageOptions={[10, 25, 100]}
                 component="div"
                 count={user?.packages?.length}
                 rowsPerPage={rowsPerPage}
                 page={page}
                 onPageChange={handleChangePage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               />
               </>
            )}

            {value == 2 && (
              <>
                <Index.Box
                  sx={{ flexGrow: 1, width: "97%" }}
                  className="prof-img-container"
                >
                  <Index.Grid container spacing={2}>
                    {user?.Portfolio?.map((items, index) => (
                      <>
                        <Index.Grid item xs={4} >
                          <Index.Box item className="portfolio-img-main-box">
                            <Zoom>
                            <img
                              src={`${PageIndex.imageURL}/${items}`}
                              className="portfolio-img"
                            /></Zoom>
                          </Index.Box>
                        </Index.Grid>
                      </>
                    ))}
                  </Index.Grid>
                </Index.Box>
              </>
            )}
            
            {value == 3 && (
    
                       <div style={{ padding: "20px",border:"0.5px solid gray" }}>
      <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Booking List{" "}
          </Index.Typography>

          <Index.Box className="heading-add-box">
            {/* <Index.TextField size="small" placeholder="Search" /> */}
            {/* <Index.Button onClick={() => {}} className='add-button'>
        Add Package
       </Index.Button> */}
          </Index.Box>
        </Index.Box>
    <Index.TableContainer className="packages-table-container booking-list-view"  sx={{overflow:"scroll"}}>
          <Index.Table className="package-table" stickyHeader aria-label="sticky table">
            <Index.TableHead className="table-head">
              <Index.TableCell className="tableCell">S.No</Index.TableCell>
              <Index.TableCell className="tableCell">Image </Index.TableCell>
              <Index.TableCell className="tableCell">User Name</Index.TableCell>
              <Index.TableCell className="tableCell">Number</Index.TableCell>
              {/* <Index.TableCell className="tableCell">Service</Index.TableCell> */}
              <Index.TableCell className="tableCell">Date</Index.TableCell>
              <Index.TableCell className="tableCell">Package</Index.TableCell>
              <Index.TableCell className="tableCell">Status</Index.TableCell>
              <Index.TableCell className="tableCell">Rating</Index.TableCell>
            </Index.TableHead>

     {booking&&booking?.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)?.map((data,index)=>(
            <Index.TableBody className="table-body">
              {/* <Index.TableCell>{index+1}</Index.TableCell> */}
              <Index.TableCell>{index + 1 + page1 * rowsPerPage1}</Index.TableCell>
              <Index.TableCell >
                <img    src={`${PageIndex.imageURL}/${data?.userId?.profile}`}
                style={{height: "38px",width: "50px", borderRadius: "5px"}} />
              </Index.TableCell>
              <Index.TableCell>{`${data?.userId?.firstName} ${data?.userId?.lastName}`}</Index.TableCell>
              {/* <Index.TableCell>{data?.professionalId?.services?.serviceName?.map((servic,index)=>(
                <p>{servic}</p>
              ))}</Index.TableCell> */}
              <Index.TableCell>{data?.userId?.mobileNumber}</Index.TableCell>
              <Index.TableCell>{data?.date}</Index.TableCell>
              <Index.TableCell>{data?.packagesId[0]?.packageName}</Index.TableCell>
              <Index.TableCell>{data?.status} </Index.TableCell>
              <Index.TableCell>
          {data?.averageRating?
              <Index.Typography className="rating-text" 
                 onClick={() => {
                  setOpen(true);
                  setData(data);
                }}
                >{data?.averageRating }</Index.Typography>:"-"}
                 </Index.TableCell>
              {/* <Index.TableCell>  
                 {data?.status == "Completed" &&
                <Index.Button onClick={()=>{setOpen(true);setData(data)}} className="rating-btn">
                  rating</Index.Button>}
                  </Index.TableCell> */}
            </Index.TableBody>
            ))}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={booking.length}
        rowsPerPage={rowsPerPage1}
        page={page1}
        onPageChange={handleChangePage1}
        onRowsPerPageChange={handleChangeRowsPerPage1}
      />
                        </div>
       
            )}


   {/* {value == 4 && (
              <Index.Box style={{ height: "288px" }}>ReviewsTab</Index.Box>
            )} */}


          </Index.Box>
        </Index.Box>
                    

               

                    {/* <Box className="view-profile-data top-border-line">
                        <Box className="professional-view-profile">
                            <Box className="custom-data-profile">

                                <Typography className="profile-data-lists">
                                    packages :{" "}
                                </Typography>
                            </Box>
                            <TableContainer className="custom-table-container">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Package Name</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {user?.packages?.map((service, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Typography>{service?.packageName}</Typography> </TableCell>
                                                <TableCell><Typography>{service?.description}</Typography> </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box className=" top-border-line">
                        <Box className="custom-data-profile">
                            <Typography className="profile-data-lists">
                                Portfolio :{" "}
                            </Typography>
                        </Box>
                        <Box className="profile-content-data portfolio-image">
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1}>
                                    {user?.Portfolio?.map((image, index) => (
                                        <Grid item xs={3} key={index}>
                                            <Box className="custom-item">
                                                <img src={`${imageURL}/${image}`} alt={`portfolio_${index}`} />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>

                        </Box>
                    </Box> */}
                </CardContent>
            </Card>
        </Box>
        <RatingModal
      open={open}
      setOpen={setOpen}
      data={data}
      setData={setData}
      type={"read"}
      />
        </>
    );
}
