import React, { useState, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import uploadicon from "../../../../assets/images/upload-icon.png";

const AddEditPortfolio = ({
  open,
  setOpen,
  data,
  setData,
  getPortfolioDetails,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesPath, setSelectedImagesPath] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(false); 
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedImagesPath([...selectedImagesPath, ...files]);
    const selectedImagesArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedImages((prevImages) => [...prevImages, ...selectedImagesArray]);
    event.target.value = null;
    setError(false);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImagesPath];
    updatedImages.splice(index, 1);
    setSelectedImagesPath(updatedImages);
    const updatedDisplayImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedDisplayImages);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImages([])
    setSelectedImagesPath([])
    setError(false);
  };

  const updatePortfolio = async () => {
    if (selectedImages.length == 0) {
      setError(true); 
      return;
    }
    try {
      setBtnLoading(true);
      const formData = new FormData();
      selectedImagesPath.forEach((file) => {
        formData.append("Portfolio", file);
      });

      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.PROFESSIONAL_UPDATE_PORTFOLIO,
        formData
      );

      if (res?.data?.status === 200) {
        PageIndex.toast.success("Portfolio added sccessfully");
        getPortfolioDetails();
        handleClose();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      handleClose();
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      
      <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box className="portfolio-main-container">
          <Index.Box className="prof-heading-box">
            <Index.Typography className="prof-heading-text">
              Add Portfolio
            </Index.Typography>
          </Index.Box>

          {!selectedImages[0] ?
          <>
            <Index.Box className="portfolio-image-select-btn">
              <Index.Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  multiple
                  style={{ display: "none" }}
                />
                <Index.Box className="upload-img-box">
                  <img src={uploadicon} onClick={() => fileInputRef.current.click()} className="upload-img-icon" />
                </Index.Box>
                <Index.Box className="image-text-box-modal">
                  <Index.Typography className="image-text-modal">Upload portfolio images</Index.Typography>
                </Index.Box>
              </Index.Box>
             
            </Index.Box>
             {error && ( 
              <Index.Typography className="error">
                Please select at least one image.
              </Index.Typography>
            )}
            </>
            :
            <Index.Box className="my-class">
              <Index.Box sx={{ flexGrow: 1 }} className="prof-img-container-modal">
                <Index.Grid container spacing={2}>
                  {selectedImages?.map((imageUrl, index) => (
                    <Index.Grid item xs={4} key={index}>
                      <Index.Box item className="portfolio-img-modal-box">
                        <img
                          src={imageUrl}
                          className="portfolio-img-modal"
                          alt={`Selected ${index}`}
                        />
                        <Index.ClearIcon
                          fontSize="small"
                          className="portflio-img-remove-icon"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </Index.Box>
                    </Index.Grid>
                  ))}
                </Index.Grid>
              </Index.Box>
            </Index.Box>
          }
          <Index.Box className="main-button-modal">
            <Index.Box className="first-cancel-btn">
              <Index.Button onClick={handleClose}>Cancel</Index.Button>
            </Index.Box>
            <Index.Box className="second-delete-btn">
              <Index.Button
                type="submit"
                onClick={updatePortfolio}
                disabled={btnLoading }
              >
                Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AddEditPortfolio;
