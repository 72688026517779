import React from 'react';

function EditWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <g data-name="Layer 92">
          <path
            d="M21.1 2.9a3 3 0 0 0-4.27 0l-1.92 1.95 4.24 4.24 1.92-1.92a3 3 0 0 0 .03-4.27zM13.49 6.27 5.1 14.66a1.15 1.15 0 0 0-.19.26l-2.78 5.61A1 1 0 0 0 3 22a1 1 0 0 0 .44-.1l5.61-2.78a1.15 1.15 0 0 0 .26-.19l8.39-8.39zM21 22h-9a1 1 0 0 1 0-2h9a1 1 0 0 1 0 2z"
            fill="#ffffff"
            opacity="1"
            data-original="#000000"
            class=""
          />
        </g>
      </g>
    </svg>
  );
}

export default EditWhite;
