import React from "react";
import { Box, Button } from "@mui/material";
import "./Layout.css"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

const Header = ({ setOpen, open }) => {
  const navigate = useNavigate();
  const handelLogout = ()=>{
    localStorage.clear();
    navigate("/admin")
  }
  return (
    <div>
      <Box className="header" >
        <Box className="header-box">
          <Box className="icon-bar" onClick={() => setOpen(open == true ? false : true)}>
            <MenuIcon />
          </Box>
          <Box>
             <Button onClick={()=>handelLogout()} className="logout" variant="">
                logout
              </Button> 
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
