import React, { useState, useEffect } from "react";
import "./BookingModal.css";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import { BookingSchema } from "../../../../../validation/UserValidation";
import DatePickerField from "./DatePickerField";
import dayjs from "dayjs";

const BookingModal = ({ open, setOpen, handleClose, id }) => {
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const submitForm = async (values) => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.USER_ADD_BOOKING,
        {
          professionalId: id,
          packagesId: values.packageName,
          date: values.date,
        }
      );
      if (res.data.status === 201) {
        PageIndex.toast.success(res?.data?.message);
        handleClose();
      }
    } catch (error) {
      handleClose();
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const getPackegesDetails = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.USER_GET_PROFESSIONAL_PACKAGES,
        { id: id }
      );
      if (res?.data?.status === 200) {
        setPackageData(res?.data?.data?.packages);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getPackegesDetails();
  }, []);

  const getData = async () => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.SERVICE.AVAILABILITY_GET,
        {
          userId: id,
        }
      );
      if (res.data.status === 200) {
        if (res.data?.data?.time == null) {
          setSelectedDates([])
        } else {
          setSelectedDates(res.data?.data?.time?.map((date) => dayjs(date)));
        }
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      
      <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PageIndex.Formik
          initialValues={{
            packageName: "",
            date: "",
          }}
          onSubmit={submitForm}
          validationSchema={BookingSchema}
        >
          {({ values, handleChange, handleSubmit, errors, setFieldValue, handleBlur,touched }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Index.Box className="packages-modal-main">
                <Index.Box className="prof-heading-box">
                  <Index.Typography className="prof-heading-text">
                    Service Booking
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="package-name-input">
                  <DatePickerField
                    label="Select service date"
                    options={selectedDates}
                    // value={values?.date}
                    onBlur={handleBlur}
                    name="date"
                    onChange={(newValue) => {
                      const formattedDate =
                        dayjs(newValue)?.format("DD/MM/YYYY");
                      setFieldValue("date", formattedDate);
                    }}

                  />
                </Index.Box>
                {errors.date && touched.date && <span className="error"> {errors.date} </span>}

                <Index.TextField
                  placeholder="Package Name"
                  // size="small"
                  className="package-name-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  select
                  label="Select Package"
                  value={values?.packageName}
                  name="packageName"
                >
                  {packageData?.map((item) => (
                    <Index.MenuItem key={item?._id} value={item?._id}>
                      {item?.packageName}
                    </Index.MenuItem>
                  ))}
                </Index.TextField>
                {errors.packageName && touched?.packageName && (
                  <span className="error"> {errors.packageName} </span>
                )}

                <Index.Box className="main-button-modal">
                  <Index.Box className="first-cancel-btn">
                    <Index.Button onClick={handleClose}>Cancel</Index.Button>
                  </Index.Box>
                  <Index.Box className="second-delete-btn">
                    <Index.Button type="submit" disabled={loading}>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PageIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default BookingModal;
