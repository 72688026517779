import React, { useState } from "react";
import "./Professional.css";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import profileImg from "../../../../assets/images/jpg/profile.jpg";
import imageIcon from "../../../../assets/images/user-profile.jpg";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import BookingModal from "./BookingModal/BookingModal";

const ProfessionalDetails = () => {
let token = localStorage.getItem("token");
  const professionalData = PageIndex.useLocation();
  const data = professionalData?.state?.data;
  const [value, setValue] = React.useState(0);
  const [open,setOpen]=useState(false);
  const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const findNextAvailableDate = (availability) => {
    const today = new Date();
    const checkData = today?.toISOString()?.slice(0, 10)?.replace(/-/g, "/");
    const futureDates = availability?.filter((dateStr) => dateStr >= checkData);
    if (futureDates && futureDates.length > 0) {
      return PageIndex.format(futureDates[0], "dd/MM/yyyy");
    } else {
      return "Not available now";
    }
  };

  return (
    <>
      <Index.Box className="prof-detail-main-box">
        <Index.Box className="prof-detail-heading-box">
          <Index.Box className="prof-detail-img-box">
            {data?.profile ? (
              <img
                src={`${PageIndex.imageURL}/${data?.profile}`}
                className="prof-detail-heading-img"
              />
            ) : (
              <img src={imageIcon} className="prof-detail-heading-img" />
            )}
          </Index.Box>

          <Index.Box className="profile-detail-heading-right-box">
            <Index.Typography className="name-text">
              {data?.firstName?.charAt(0)?.toUpperCase() +
                data?.firstName?.slice(1)}{" "}
              {data?.lastName?.charAt(0)?.toUpperCase() +
                data?.lastName?.slice(1)}
            </Index.Typography>

            <Index.Box className="prof-city-box">
              <Index.LocationOnIcon className="prof-detail-city-icon" />
              <Index.Typography>{data?.city?.title}</Index.Typography>
            </Index.Box>

            <Index.Box className="prof-email-box">
              <Index.EmailIcon className="prof-email-icon" />
              <Index.Typography>{data?.email}</Index.Typography>
            </Index.Box>

            <Index.Box className="prof-contact-box">
              <Index.CallIcon className="prof-contact-icon" />
              <Index.Typography>+91 {data?.mobileNumber}</Index.Typography>
            </Index.Box>
            {/* <Index.Box className="prof-time-box">
              <Index.CalendarMonthIcon className="prof-time-icon" />
              <Index.Typography className="prof-time-text">Available :</Index.Typography>
              <Index.Typography className="text">
                {findNextAvailableDate(data?.availability)}
              </Index.Typography>
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="prof-service-right-box">
          
            <Index.Box className="prof-service-box">
              <Index.Typography className="prof-service-text-heading">
                Language:
              </Index.Typography>
              <Index.Typography>{data?.language?.title}</Index.Typography>
            </Index.Box>
            <Index.Box className="prof-service-box">
              <Index.Typography className="prof-service-text-heading">
                Nationality:
              </Index.Typography>
              <Index.Typography>{data?.nationality} </Index.Typography>
            </Index.Box>
            <Index.Box className="prof-service-box">
              <Index.Typography className="prof-service-text-heading">
                Tagline:
              </Index.Typography>
              <Index.Typography className="prof-tagline-details"> {data?.tagLine} </Index.Typography>
            </Index.Box>
          </Index.Box>
          {token?
          <Index.Box className="booking-btn-box">
            <Index.Button className="booking-button" onClick={()=>{setOpen(true)}}>Book Service</Index.Button>
          </Index.Box>:""}

        </Index.Box>

        <Index.Box>
          <Index.Box
            sx={{
              width: "100%",
              margin: "20px 0px 0px 0px",
              border: "0.5px solid gray",
            }}
          >
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tap-heading-box"
              >
                <Index.Tab label="Service" className="tap-heading" />
                <Index.Tab label="Package" className="tap-heading" />
                <Index.Tab label="Portfolio" className="tap-heading" />
                {/* <Index.Tab label="Reviews" className="tap-heading" /> */}
              </Index.Tabs>
            </Index.Box>
            {value == 0 && (
              <Index.Box className="service-box-container">
                <Index.Box className="servies-list-detail">
                  <ul>
                    {data?.services?.map((items, index) => (
                      <>
                        <li className="text">{items?.serviceName}</li>
                      </>
                    ))}
                  </ul>
                </Index.Box>
              </Index.Box>
            )}
            {value == 1 && (
              <>
              <Index.TableContainer className="packages-table-container" sx={{overflow:"scroll"}}>
                <Index.Table stickyHeader aria-label="sticky table">
                  <Index.TableHead className="table-head">
                    <Index.TableCell
                      align="center"
                      className="table-heading-cell"
                    >
                      No.
                    </Index.TableCell>
                    <Index.TableCell className="table-heading-cell">
                      Package{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-head-last-row">
                      Description
                    </Index.TableCell>
                  </Index.TableHead>
                  {data?.packages?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items, index) => (
                    <Index.TableBody className="table-body">
                      <Index.TableCell align="center">
                        {index + 1 + page * rowsPerPage}
                      </Index.TableCell>
                      <Index.TableCell>{items?.packageName}</Index.TableCell>
                      <Index.TableCell>{items?.description}</Index.TableCell>
                    </Index.TableBody>
                  ))}
                </Index.Table>
              </Index.TableContainer>
                 <Index.TablePagination
                 rowsPerPageOptions={[10, 25, 100]}
                 component="div"
                 count={data?.packages?.length}
                 rowsPerPage={rowsPerPage}
                 page={page}
                 onPageChange={handleChangePage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               />
              </>
            )}

            {value == 2 && (
              <>
                <Index.Box
                  sx={{ flexGrow: 1, width: "97%" }}
                  className="prof-img-container"
                >
                  <Index.Grid container spacing={2}>
                    {data?.Portfolio?.map((items, index) => (
                      <>
                        <Index.Grid item xs={3}>
                          <Index.Box item className="portfolio-img-main-box">
                            <Zoom>
                            <img
                              src={`${PageIndex.imageURL}/${items}`}
                              className="portfolio-img"
                            /></Zoom>
                          </Index.Box>
                        </Index.Grid>
                      </>
                    ))}
                  </Index.Grid>
                </Index.Box>
              </>
            )}
            {/* {value == 3 && (
              <Index.Box style={{ height: "288px" }}>ReviewsTab</Index.Box>
            )} */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
   
   <BookingModal 
   open={open}
   setOpen={setOpen}
   handleClose={handleClose}
   id={data?._id}
   />

    </>
  );
};

export default ProfessionalDetails;
