import React from 'react'
import Index from '../../container/Index'

const NoDataFound = () => {
  return (
    <>
    <Index.Box className="no-data-found-box">
      <Index.Typography className="no-data-found-text"> 
         No Data Found
      </Index.Typography>
        
    </Index.Box>
    
    
    </>
  )
}

export default NoDataFound