import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataService } from "../../../../config/DataService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./CategoryMater.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Index from "../../../Index";

export const CategoryMaster = () => {
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required("Category Name is required"),
  });

  const handleOpen = (id, isDelete) => {
    if (isDelete) {
      setOpenDeleteConfirmation(true);
      setUserId(id);
    } else {
      setOpen(true);
      setUserId(id);
      if (id) {
        const category = categoryData.find((cat) => cat._id === id);
        setCategoryName(category.category);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDeleteConfirmation(false);
    setUserId("");
    setCategoryName("");
  };

  const GetAllCategory = () => {
    const token = localStorage.getItem("token");
    const headers = { headers: { auth: token } };

    DataService.get("/common/get-all-category", headers)
      .then((res) => {
        setCategoryData(res?.data?.data);
        setFilterData(res?.data?.data);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        console.error("Error fetching data:", error);
      });
  };

  const addCategory = (values, resetForm) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { auth: token } };
    DataService.post(
      "/admin/add-edit-category",
      { category: values.categoryName },
      headers
    )
      .then((response) => {
        if (response.data.status === 201) {
          toast.success(response.data.message);
        }
        resetForm();
        GetAllCategory();
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error:", error);
      });
  };

  const editCategory = (values, resetForm) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { auth: token } };
    const requestBody = {
      category: values.categoryName,
      categoryId: userId,
    };

    DataService.post(`/admin/add-edit-category`, requestBody, headers)
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message);
        }
        resetForm();
        GetAllCategory();
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error:", error);
      });
  };

  const deleteCategory = (categoryId, handleClose) => {
    const token = localStorage.getItem("token");
    DataService.post(
      "/admin/delete-category",
      { categoryId },
      { headers: { auth: token } }
    )
      .then((response) => {
        if (response.data.status === 200) {
          toast.error(response.data.message);
          GetAllCategory();
          handleClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error deleting category. Please try again.");
        console.error("Error:", error);
      });
  };
  const handleActiveDeactive = (categoryId) => {
    const token = localStorage.getItem("token");
    DataService.post(
      "/admin/active-deactive-category",
      { categoryId },
      { headers: { auth: token } }
    )
      .then((response) => {
        if (response.data.status === 200) {
          // toast.success(response.data.message);
          GetAllCategory();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error:", error);
      });
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = categoryData?.filter((item) => {
      const matchesCategory = item.category.toLowerCase().includes(search);
      return matchesCategory ;
    });
    setFilterData(res);
  };


  useEffect(() => {
    GetAllCategory();
  }, []);

  return (
    <>
      <Box className="main-role">
        <Box className="top-role">
          <Box className="left-heading-role">
            <Typography component="h5" variant="h5">
              Category Master
            </Typography>
          </Box>
          <Box className="right-top-role">
            <Index.TextField
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
            <Button
              variant="contained"
              className="btn btn-primary"
              onClick={() => handleOpen()}
              aria-placeholder="Catergory Name"
            >
              Add Category
            </Button>
          </Box>
        </Box>
        <TableContainer className="table-main" sx={{ overflow: "scroll" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-main-column">S.No</TableCell>
                <TableCell className="table-main-column">
                  Category Name
                </TableCell>
                <TableCell className="table-main-column">Status</TableCell>
                <TableCell className="table-main-column" align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleActiveDeactive(item._id)}
                      type="button"
                      variant="contained"
                    >
                      {!item.isActive ? "active" : "deactive"}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Box>
                      <EditIcon onClick={() => handleOpen(item._id)} />
                      <DeleteIcon onClick={() => handleOpen(item._id, true)} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={categoryData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Modal
        className="cus-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="cus-modal">
          <Typography
            className="modal-heading-text"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {userId ? "Edit Category" : "Add Category"}
          </Typography>
          <Formik
            initialValues={{ categoryName: categoryName }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              if (userId) {
                editCategory(values, resetForm);
              } else {
                addCategory(values, resetForm);
              }
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors }) => (
              <Form className="add-category-data" onSubmit={handleSubmit}>
                <Field
                  type="text"
                  name="categoryName"
                  value={values.categoryName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as={TextField}
                  label="Category Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={<ErrorMessage name="categoryName" />}
                  error={Boolean(errors.categoryName)}
                />
                <Box className="main-button-modal">
                  <Box className="first-cancel-btn">
                    <Button onClick={handleClose}>Cancel</Button>
                  </Box>
                  <Box className="second-delete-btn">
                    <Button type="submit">{userId ? "Update" : "Add"}</Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Modal
        className="cus-main-modal"
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="cus-modal">
          <Typography
            className="modal-heading-text"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are You Sure?
          </Typography>
          <Typography
            className="modal-text"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Do you really want to delete this record? This action cannot be
            undone.
          </Typography>

          <Box className="main-button-modal">
            <Box className="first-cancel-btn">
              <Button onClick={() => setOpenDeleteConfirmation(false)}>
                Cancel
              </Button>
            </Box>
            <Box className="second-delete-btn">
              <Button onClick={() => deleteCategory(userId, handleClose)}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
