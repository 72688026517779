import React, { useState } from "react";
import Index from "../../../Index";
import "../../../user/pages/profile/userEditProfile.css";
import userProfile from "../../../../assets/images/jpg/profile.jpg";
import EditWhite from "../../../../assets/images/svg/EditWhite";
import { useFormik } from "formik";
import { EditUserProfile } from "../../../../validation/UserValidation";
import PageIndex from "../../../PageIndex";

function Profile() {
  const data = JSON.parse(localStorage.getItem("userData"));
  const [userData, setUserData] = useState("");
  const [file, setFile] = useState("");
  console.log(userData, "111");

  const [subconfigurations, setSubconfigurations] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [subconfigurationsCity, setSubconfigurationsCity] = useState([]);
  const [cityId, setCityId] = useState("");

  const getAllConfigurationData = async (value) => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.CONFIGURATION_GET_ALL,
        value
      );
      if (res?.data?.status === 200) {
        const configurations = res.data.data;
        const languageConfig = configurations.find(
          (config) => config.title === "Language"
        );
        const cityConfig = configurations.find(
          (config) => config.title === "City"
        );
        setLanguageId(languageConfig._id);
        setCityId(cityConfig._id);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllCity = async () => {
    try {
      const response = await PageIndex.DataService.get(
        `/common/get-all-subconfiguration/${cityId}`
      );
      if (response.status === 200) {
        setSubconfigurationsCity(response.data.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllSubConfiguration = async () => {
    try {
      const response = await PageIndex.DataService.get(
        `/common/get-all-subconfiguration/${languageId}`
      );
      console.log(response.data, "genderid88888");
      if (response.status === 200) {
        setSubconfigurations(response.data.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  PageIndex.useEffect(() => {
    getAllConfigurationData();
  }, []);

  PageIndex.useEffect(() => {
    if (languageId) {
      getAllSubConfiguration();
    }
  }, [languageId]);

  PageIndex.useEffect(() => {
    if (cityId) {
      getAllCity();
    }
  }, [cityId]);

  const [tempProfileImageURL, setTempProfileImageURL] = useState(null);
  const dob = userData.DOB ? new Date(userData.DOB) : null;
  const formattedDOB = dob
    ? `${dob.getMonth() + 1}/${dob.getDate()}/${dob.getFullYear()}`
    : "";

  const initialValues = {
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    DOB: formattedDOB,
    city: userData?.city || "",
    nationality: userData.nationality || "",
    language: userData.language?._id || "",
    tagLine: userData.tagLine || "",
    mobileNumber: userData.mobileNumber || "",
    gender: userData?.gender?.title || "",
    profile: userData.profile || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: EditUserProfile,
      enableReinitialize: true,
      onSubmit: (values, action) => {
        editProfileSubmit(values);
        action.resetForm();
      },
    });

  // const handleProfileImageChange = (e) => {
  //   const files = e?.target?.files[0];
  //   setFile(files);
  //   setTempProfileImageURL(URL.createObjectURL(files));
  // };

  const handleProfileImageChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setTempProfileImageURL(URL.createObjectURL(file));
      } else {
        console.error("File type not allowed. Please select a JPG, JPEG, or PNG file.");
      }
    }
  };

  const getSingleUser = async () => {
    try {
      const res = await PageIndex.DataService.get(
        `${PageIndex.API.USER.PROFESSIONAL_SINGLE}?profesionalId=${data?._id}`
      );
      if (res?.data?.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const editProfileSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("_id", data?._id);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("DOB", values.DOB);
      formData.append("city", values.city);
      formData.append("tagLine", values.tagLine);
      formData.append("language", values.language);
      formData.append("nationality", values.nationality);
      formData.append("mobileNumber", values.mobileNumber);
      formData.append("profile", file ? file : userData?.profile);
      formData.append("gender", userData?.gender?._id);

      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.USER_EDIT_PROFILE,
        formData
      );

      if (res?.data?.status === 200) {
        getSingleUser();
        PageIndex.toast.success(res?.data?.message);
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  PageIndex.useEffect(() => {
    if (data?._id) {
      getSingleUser();
    }
  }, []);

  return (
    <>
      <Index.Box className="admin-main-content-list">
        <Index.Box className="admin-common-title-details">
          <Index.Box className="admin-heade-bg-title">
            <Index.Box className="admin-flex-common">
              <Index.Typography className="admin-common-title">
                My Profile Details
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <form onSubmit={handleSubmit} className="user-profile-form">
            <Index.Box className="profile-max-user-details">
              <Index.Box className="profile-edit-bg">
                <Index.Box className="profile-view-listing">
                  <Index.Box className="profile-view-flex">
                    <Index.Box className="profile-image-view">
                      {values.profile === "" ? (
                        <img
                          src={tempProfileImageURL || userProfile}
                          className="profile-viewer"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={
                            tempProfileImageURL ||
                            `${PageIndex.imageURL}/${values.profile}`
                          }
                          className="profile-viewer"
                          alt="profile"
                        />
                      )}

                      <Index.Button
                        component="label"
                        variant="contained"
                        tabIndex={-1}
                        className="profile-btn-label"
                      >
                        <EditWhite />
                        <input
                          id="profile"
                          type="file"
                          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
                          style={{ display: "none" }}
                          name="profile"
                          onChange={handleProfileImageChange}
                          onBlur={handleBlur}
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="profile-name-details">
                      <Index.Typography
                        component="h5"
                        variant="h5"
                        className="profile-user-name"
                      >
                        Professional
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="profile-user-email"
                      >
                        professional@gmail.com
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="profile-edit-padding">
                  <Index.Grid
                    container
                    spacing={2}
                    className="common-user-grid"
                  >
                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          First Name
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="firstName"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your first name"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.firstName && touched.firstName ? (
                          <p className="from-error">{errors.firstName}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Last name
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="lastName"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your last name"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.lastName && touched.lastName ? (
                          <p className="from-error">{errors.lastName}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          City
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          {/* <Index.TextField
                                                        id="city"
                                                        fullWidth
                                                        variant="outlined"
                                                        className="common-textfield-content"
                                                        placeholder='Enter your city'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    /> */}
                          <Index.TextField
                            type="text"
                            fullWidth
                            className="common-textfield-content"
                            name="city"
                            size="small"
                            defaultValue={userData?.city}
                            value={values.city}
                            onChange={handleChange}
                            select
                          >
                            {subconfigurationsCity?.map((item) => (
                              <Index.MenuItem key={item._id} value={item._id}>
                                {item.title}
                              </Index.MenuItem>
                            ))}
                          </Index.TextField>
                        </Index.Box>
                        {errors.city && touched.city ? (
                          <p className="from-error">{errors.city}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Language
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          {/* <Index.TextField
                            id="language"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your language"
                            value={values.language}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                          <Index.TextField
                            type="text"
                            fullWidth
                            className="common-textfield-content"
                            name="language"
                            size="small"
                            defaultValue={userData?.language}
                            value={values.language}
                            onChange={handleChange}
                            select
                          >
                            {subconfigurations?.map((item) => (
                              <Index.MenuItem key={item._id} value={item._id}>
                                {item.title}
                              </Index.MenuItem>
                            ))}
                          </Index.TextField>
                        </Index.Box>
                        {errors.language && touched.language ? (
                          <p className="from-error">{errors.language}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Nationality
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="nationality"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your city"
                            value={values.nationality}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.nationality && touched.nationality ? (
                          <p className="from-error">{errors.nationality}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Tag Line
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="tagLine"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your tag line"
                            value={values.tagLine}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.tagLine && touched.tagLine ? (
                          <p className="from-error">{errors.tagLine}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Date of Birth
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="DOB"
                            fullWidth
                            variant="outlined"
                            className="common-textfield-content"
                            placeholder="Enter your DOB"
                            value={values.DOB}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.DOB && touched.DOB ? (
                          <p className="from-error">{errors.DOB}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText
                          className="common-label-details"
                          varia
                        >
                          Mobile Number
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            id="mobileNumber"
                            fullWidth
                            variant="outlined"
                            type="number"
                            name="mobileNumber"
                            className="common-textfield-content"
                            placeholder="Enter your mobile number"
                            value={values.mobileNumber}
                            // onChange={handleChange}
                            onChange={(e) => {
                              let number = e?.target?.value;
                              if (number?.length <= 10) {
                                setFieldValue("mobileNumber", number);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                        {errors.mobileNumber && touched.mobileNumber ? (
                          <p className="from-error">{errors.mobileNumber}</p>
                        ) : null}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={4} className="common-user-grid-item">
                      <Index.Box className="common-input-field">
                        <Index.FormHelperText className="common-label-details">
                          Gender
                        </Index.FormHelperText>
                        <Index.Box className="input-common-field">
                          <Index.TextField
                            sx={{ width: "100%" }}
                            id="gender"
                            name="gender"
                            size="small"
                            placeholder="Please select your gender"
                            value={values.gender}
                            className="common-textfield-content"
                          />
                        </Index.Box>
                        {/* {errors.gender && touched.gender ? (<p className='from-error'>{errors.gender}</p>) : null} */}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} className="common-user-grid-item">
                      <Index.Box className="common-btn-details ">
                        <Index.Box className="flex-gap-footer end-justify-content">
                          <Index.Button
                            type="submit"
                            className="button-primary"
                          >
                            Upload Profile
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </form>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Profile;
