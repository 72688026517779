import { Description } from '@mui/icons-material';
import * as Yup from 'yup';
const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
)
const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const emailRegex =
    /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+\+)*(?:\w+\!)*(?:\w+\#)*(?:\w+\$)*(?:\w+\&)*(?:\w+\%)*(?:\w+\-)*\w+@\w+\.\w{2,3}(?:\.[a-z]{2,3})?)$/;

export const userLoginSchema = Yup.object({
    email: Yup.string()
        .required("Please enter your email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),
    password: Yup.string()
        .required("Please enter your password"),
});

export const userForgotPassword = Yup.object({
    email: Yup.string()
        .required("Please enter your email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),
})
export const userVerificationPassword = Yup.object({
    userEmail: Yup.string()
        .required("Please enter your email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),
})

export const UserSignUp = Yup.object({
    firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid first name')
        .min(2, 'First name must be at least 2 characters')
        .max(20, 'First name must be at most 20 characters')
        .required('Please enter your first name'),
    lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid last name')
        .min(2, 'Last name must be at least 2 characters')
        .max(20, 'Last name must be at most 20 characters')
        .required('Please enter your last name'),
    email: Yup.string()
        .required("Please enter your email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),
    DOB: Yup.string().required("Please enter your DOB"),
    city: Yup.string().required("Please enter your city"),
    gender: Yup.string().required("Please enter your gender"),
    password: Yup.string()
        .matches(passwordRegex, "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character")
        .required("Please enter your password"),
    confirmPassword: Yup.string("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please enter confirm Password"),
    mobileNumber: Yup.string().matches(phoneRegex, "Invalid phone").required("Please enter your mobile number"),
});

export const userResetPassword = Yup.object({
    password: Yup.string()
        .matches(passwordRegex, "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character")
        .required("Please enter your password"),
    confirm_password: Yup.string("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please enter confirm Password"),
})

export const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .matches(passwordRegex, "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character")
        .required("Please enter your old password"),
    newPassword: Yup.string()
        .matches(passwordRegex, "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character")
        .required("Please enter your password"),
    confirmPassword: Yup.string("Please enter confirm password")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please enter confirm Password"),
});

export const EditUserProfile = Yup.object({
    firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid first name')
        .min(2, 'First name must be at least 2 characters')
        .max(20, 'First name must be at most 20 characters')
        .required('Please enter your first name'),
    lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid last name')
        .min(2, 'Last name must be at least 2 characters')
        .max(20, 'Last name must be at most 20 characters')
        .required('Please enter your last name'),
    DOB: Yup.string().required("Please enter your DOB"),
    city: Yup.string().required("Please enter your city"),
    // gender: Yup.string().required("Please select your gender"),
    mobileNumber: Yup.string().matches(phoneRegex, "Invalid phone").required("Please enter your mobile number"),
});

export const EditSiteSetting = Yup.object({
    name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Please enter a valid website name')  
        .required('Please enter website name'),

    email: Yup.string().required("Please enter email"),
    address: Yup.string().required("Please enter address"),
    facebook: Yup.string().required("Please enter facebook link"),
    instagram: Yup.string().required("Please enter instagram link"),
    youTube: Yup.string().required("Please enter youtube link"),
    twitter: Yup.string().required("Please enter twitter link"),
    mobileNumber: Yup.string().matches(phoneRegex, "Invalid phone").required("Please enter mobile number"),
});




export const ProfessionalEditProfile = Yup.object({
    firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid first name')
        .min(2, 'First name must be at least 2 characters')
        .max(20, 'First name must be at most 20 characters')
        .required('Please enter first name'),

    lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Please enter a valid last name')
        .min(2, 'Last name must be at least 2 characters')
        .max(20, 'Last name must be at most 20 characters')
        .required('Please enter  last name'),

    email: Yup.string()
        .required("Please enter email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),

    city: Yup.string().required("Please enter city"),
    nationality: Yup.string().required("Please enter nationality"),
    language: Yup.string().required("Please enter language"),
    tagLine: Yup.string().required("Please enter tag line"),
    // password: Yup.string()
    //     .matches(passwordRegex, "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character")
    //     .required("Please enter your password"),
    // confirmPassword: Yup.string("Please enter confirm password")
    //     .oneOf([Yup.ref("password"), null], "Passwords must match")
    //     .required("Please enter confirm Password"),
    mobileNumber: Yup.string().matches(phoneRegex, "Invalid phone").required("Please enter mobile number"),
});

export const PackagesAddEdit =Yup.object({
    packageName: Yup.string()
    .required('Please enter package name'),

    description: Yup.string()
    .required('Please enter description'),
})
export const QuestionAddEdit =Yup.object({
    question: Yup.string()
    .required('Please enter question'),
})

export const ServiceAddEdit =Yup.object({
    serviceName: Yup.string()
    .required('Please enter service name'),
})

export const BookingSchema =Yup.object({
    packageName: Yup.string()
    .required('Please select package'),

    date: Yup.string()
    .required('Please select date'),
})


export const TermsAndConditionValidation =Yup.object({
    title: Yup.string()
    .required('Please enter title'),
  description: Yup.string()
   .required('Please enter description'),
})


export const userContactDetail = Yup.object({
    fullName: Yup.string()
        .required('Please enter name'),
    email: Yup.string()
        .required("Please enter email")
        .email("Email is not valid")
        .matches(emailRegex, "Email is not valid"),
    message: Yup.string().required("Please enter message"),
    mobileNumber: Yup.string().matches(phoneRegex, "Invalid phone").required("Please enter mobile number"),
});