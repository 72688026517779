import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import "./PrivacyPolicy.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { TermsAndConditionValidation } from "../../../../validation/UserValidation";
const TermsAndCondition = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data, "11");

  const submitForm = async (value) => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.ADMIN.ADDEDIT_PRIVACY_POLICY,
        {
          title: value.title,
          description: value.description,
        }
      );
      if (res?.data?.status == 200 || 201) {
        PageIndex.toast.success(res?.data?.message);
        getData();
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };
  const getData = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.CMS_DATA_GET
      );
      if (res?.data?.status == 200) {
        setData(res?.data?.data[0]?.privacyPolicy);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Index.Box className="main-table">
        <Index.Box className="top-table">
          <Index.Box classsName="left-heading-table">
            <Index.Typography component="h5" variant="h5">
             Privacy Policy
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {!loading ? (
          <PageIndex.Formik
            validationSchema={TermsAndConditionValidation}
            initialValues={{
              title: data.title ? data.title : "",
              description: data.description ? data.description : "",
            }}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ margin: "0px" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Index.Box className="terms-middle-box">
                  <Index.Box className="term-title-box">
                    <Index.Typography className="term-title-text">
                      Title
                    </Index.Typography>
                    <Index.TextField
                      size="small"
                      fullWidth
                      placeholder="Enter title"
                      onChange={handleChange}
                      value={values?.title}
                      name="title"
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 64 }}
                    />
                    {errors?.title && (
                      <span className="error"> {errors?.title} </span>
                    )}
                  </Index.Box>

                  <Index.Box className="terms-description-box">
                    <Index.Typography className="decription-text">
                      Description
                    </Index.Typography>
                    <CKEditor
                      editor={ClassicEditor}
                      data={values?.description}
                      onChange={(event, editor) => {
                        let data = editor.getData();
                        setFieldValue("description", data);
                      }}
                      config={{
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo'
                          ]
                        }

                      }}
                    />
                    {errors?.description && (
                      <span className="error">{errors?.description} </span>
                    )}
                  </Index.Box>
                  <Index.Box className="terms-btn-box">
                    <Index.Button type="submit" className="terms-btn">
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PageIndex.Formik>
        ) : (
          ""
        )}
      </Index.Box>
    </>
  );
};

export default TermsAndCondition;
