import React from 'react'
import Index from '../../../Index';
import "./FAQ.css"
const ViewFaq = ({open,setOpen,data,setData}) => {
    const handleClose = () => {
        setOpen(false);
        setData();
      };
  return (
  <>
     <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

       <Index.Box className="packages-modal-main" sx={{width:"650px"}}>
       <Index.Box className="prof-heading-box">
                  <Index.Typography className="prof-heading-text">
                    View FAQ
                  </Index.Typography>
                </Index.Box>
       <Index.Box className="faq-view-modal" >
        <Index.Typography className="faq-view-quesiton">Ques. - {data?.question}</Index.Typography>
        <Index.Typography className="faq-view-answer">Ans. - {data?.answer}</Index.Typography>
      
        </Index.Box>
       </Index.Box>


      </Index.Modal>
  </>
  )
}

export default ViewFaq