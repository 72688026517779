import React, { useState, useEffect } from 'react';
import "./defaultLayout.css"
import userProfile from '../../../assets/images/jpg/profile.jpg'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, List, ListItem, Menu, Typography, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Badge from '@mui/material/Badge';
import userAccount from '../../../assets/images/svg/account.svg';
import userLock from '../../../assets/images/svg/Lock.svg';
import { LogoutFun } from './LogoutService';
import BookingIcon from "../../../assets/images/booking-.png";
import PageIndex from '../../../container/PageIndex';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));


export default function UserHeader() {
    let userType= JSON.parse(localStorage.getItem('userData'))

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const open = Boolean(anchorEl);
    let token = localStorage.getItem("token")

    const [webData, setWebData] = useState({});
    const getSiteData = async () => {
      try {
        const res = await PageIndex.DataService.get(
          PageIndex.API.ADMIN.GET_SITE_SETTING
        );
        if (res?.data?.status === 200) {
          setWebData(res?.data?.data[0]);
        }
      } catch (error) {
          console.log(error,"error");
      }
    };
  

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick  = ()=>{
          navigate("/professional/signup")
      }
    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const userURL=useLocation()
    
    const handelLogout = ()=>{
      localStorage.clear();
      navigate("/")
    }

    useEffect(() => {
        getSiteData();
      }, []);

    return (
        <Box className={`user-header-details ${isSticky ? 'sticky' : ''}`}>
            <Box className="container">
                <Box className="user-flex-header-details">
                    <Box className="user-logo-left">
                        <Link className="logo-user-redirect">
                        <img src={`${PageIndex.imageURL}/${webData?.logo}`} style={{height:"54px",width:"300px",borderRadius:"10px"}}/>
                            {/* { <h1 style={{ color: '#2a3547', lineHeight: '35px' }}>Hire Professional</h1> } */}
                        </Link>
                    </Box>
                    <Box className="user-center-menu-list">
                        <List className="user-list-details">
                            <ListItem className={`user-listitem-details ${userURL.pathname=="/"?"active":""}`}>
                                <Link className="user-redirect-listing" to="/">Home</Link>
                            </ListItem>
                            <ListItem className={`user-listitem-details ${userURL.pathname=="/professional/list"?"active":""}`}>
                                <Link className="user-redirect-listing" to="/professional/list">Professional</Link>
                            </ListItem>
                            <ListItem className="user-listitem-details">
                                <Link className="user-redirect-listing">Expert Consulting</Link>
                            </ListItem>
                        </List>
                        <Box className = "signup_btn" justifyContent="flex-start">
         {userType?.userType == "user"?

                    <Button variant="contained" className='signup-button' onClick={handleClick}>Professional Signup</Button>
                    :""}
                    </Box>
                    </Box>
                    <Box className="user-right-account">
                        <Box className="user-flex-account">
                            {token ?
                                <Box className="account-details">
                                    <Box
                                        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
                                        className="myuser-account"
                                    >
                                        <IconButton
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleProfileClick}
                                            className='profile-btn'
                                        >
                                            <Box className="user-profile-icon" >
                                                <img src={userProfile} className="profile-details" alt="profile" />
                                            </Box>
                                        </IconButton>
                                    </Box>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleProfileClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button'
                                        }}
                                        className="menu-admin-profile"
                                    >
                                        <Box
                                            className="user-profile-details-menu"
                                            onClick={() => handleProfileClose()}
                                        >
                                            <Box className="user-padding-profile">
                                                <Box className="user-profile-title">
                                                    <Typography component="p" variant="p">
                                                        User Profile
                                                    </Typography>
                                                </Box>
                                                <Box className="user-flex-details">
                                                    <Box className="profile-user-drop">
                                                        <img
                                                            src={userProfile} 
                                                            className="profile-drrop"
                                                            alt="profile"
                                                        ></img>
                                                    </Box>
                                                    <Box className="profile-user-details">
                                                        <Typography className="profile-user-name">
                                                            User
                                                        </Typography>
                                                        <Typography className="profile-user-designation">
                                                            Designer
                                                        </Typography>
                                                        <Typography className="profile-user-email">
                                                            <MailOutlineIcon />
                                                            mailto:info@user.com
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="user-profile-listing">
                                                    <Box className="flex-user-profile">
                                                        <Link className="flex-link-details" to={'edit-profile'}>
                                                            <Box className="flex-user-img-details">
                                                                <img
                                                                    src={userAccount}
                                                                    alt="account"
                                                                    className="account-profile"
                                                                />
                                                            </Box>
                                                            <Box className="profile-desc-admin">
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-title"
                                                                >
                                                                    My Profile
                                                                </Typography>
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-desc"
                                                                >
                                                                    Account Setting
                                                                </Typography>

                                                                
                                                            </Box>
                                                        </Link>
                                                    </Box>
                                                    <Box className="flex-user-profile">
                                                        <Link className="flex-link-details" to={'change-password'}>
                                                            <Box className="flex-user-img-details">
                                                                <img
                                                                    src={userLock}
                                                                    alt="account"
                                                                    className="account-profile"
                                                                />
                                                            </Box>
                                                            <Box className="profile-desc-admin">
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-title"
                                                                >
                                                                    Change Password
                                                                </Typography>
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-desc"
                                                                >
                                                                    Account Setting
                                                                </Typography>
                                                            </Box>
                                                        </Link>
                                                    </Box>

                                                    <Box className="flex-user-profile">
                                                        <Link className="flex-link-details" to={'my-booking'}>
                                                            <Box className="flex-user-img-details">
                                                                <img
                                                                    src={BookingIcon}
                                                                    alt="account"
                                                                    className="account-profile"
                                                                />
                                                            </Box>
                                                            <Box className="profile-desc-admin">
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-title"
                                                                >
                                                                   My Booking
                                                                </Typography>
                                                                <Typography
                                                                    component="p"
                                                                    variant="p"
                                                                    className="profile-drop-desc"
                                                                >
                                                                    Booking detail
                                                                </Typography>
                                                            </Box>
                                                        </Link>
                                                    </Box>
                                                    <Box className="logout-btn-profile">
                                                        <IconButton
                                                    onClick={()=>handelLogout()} 
                                                            className="outline-logout-btn btn"
                                                        >
                                                            Logout
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Menu>
                                </Box>
                                :
                                <Box className="cart-details-open">
                                    <Button className='main-btn' onClick={() => navigate("/user/login")}>
                                        Login
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}