import React, { useState } from 'react'
import "./SiteSetting.css"
import Index from '../../../Index'
import PageIndex from '../../../PageIndex';
import { useFormik } from "formik";
import { EditSiteSetting } from '../../../../validation/UserValidation';
import EditWhite from '../../../../assets/images/svg/EditWhite';
import userProfile from "../../../../assets/images/jpg/profile.jpg"

const SiteSetting = () => {
  const [webData, setWebData] = useState("");
  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const [tempProfileImageURL, setTempProfileImageURL] = useState(null);
  const [tempProfileImageURL1, setTempProfileImageURL1] = useState(null);
  const getSiteData = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.GET_SITE_SETTING
      );
      if (res?.data?.status === 200) {
        setWebData(res?.data?.data[0]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getSiteData();
  }, [])

  const initialValues = {
    name: webData?.websiteName || "",
    email: webData?.email || "",
    address: webData?.address || "",
    mobileNumber: webData?.mobileNumber || "",
    twitter: webData?.twitter || "",
    youTube: webData?.youTube || "",
    instagram: webData?.instagram || "",
    facebook: webData?.facebook || "",
    faviconIcon: webData?.faviconIcon || "",
    logo: webData?.logo || ""

  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: EditSiteSetting,
      enableReinitialize: true,
      onSubmit: (values, action) => {
        editProfileSubmit(values);
        action.resetForm();
      },
    });

  // const handleProfileImageChange = (e) => {
  //   const files = e?.target?.files[0];
  //   setFile(files);
  //   setTempProfileImageURL(URL.createObjectURL(files));
  // };
  const handleProfileImageChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setTempProfileImageURL(URL.createObjectURL(file));
      } else {
        console.error("File type not allowed. Please select a JPG, JPEG, or PNG file.");
      }
    }
  };

  const handleProfileImageChange1 = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        setFile1(file);
        setTempProfileImageURL1(URL.createObjectURL(file));
      } else {
        console.error("File type not allowed. Please select a JPG, JPEG, or PNG file.");
      }
    }
  };



  const editProfileSubmit = async (values) => {
    console.log({ values });
    try {
      const formData = new FormData();
      // formData.append("id", webData?._id);
      formData.append("websiteName", values.name);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("mobileNumber", values.mobileNumber);
      formData.append("facebook", values.facebook);
      formData.append("instagram", values.instagram);
      formData.append("twitter", values.twitter);
      formData.append("youTube", values.youTube);
      formData.append("faviconIcon", file ? file : values?.faviconIcon );
      formData.append("logo", file1 ? file1 : values?.logo);

      const res = await PageIndex.DataService.post(
        PageIndex.API.ADMIN.ADDEDIT_SITE_SETTING,
        formData
      );

      if (res?.data?.status === 200 || 201) {
        PageIndex.toast.success(res?.data?.message);
        getSiteData();
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  PageIndex.useEffect(() => {
    getSiteData();
  }, []);
  return (
    <>
      <Index.Box className="admin-main-content-list">
        <Index.Box className="admin-common-title-details">
          <Index.Box className="admin-heade-bg-title">
            <Index.Box className="admin-flex-common">
              <Index.Typography className="admin-common-title">
                Site Setting
              </Index.Typography>
            </Index.Box>
          </Index.Box>
            <form onSubmit={handleSubmit} className="user-profile-form">
              <Index.Box className="profile-max-user-details">
                <Index.Box className="profile-edit-bg">
                  <Index.Box className="profile-view-listing" sx={{ display: "flex", gap: "25%" }}>
                    <Index.Box className="profile-view-flex">
                      <Index.Box className="profile-image-view">
                        {values.faviconIcon === "" ? (
                          <img
                            src={tempProfileImageURL || userProfile}
                            className="profile-viewer"
                            alt="profile"
                          />
                        ) : (
                          <img
                            src={
                              tempProfileImageURL ||
                              `${PageIndex.imageURL}/${values.faviconIcon}`
                            }
                            className="profile-viewer"
                            alt="profile"
                          />
                        )}

                        <Index.Button
                          component="label"
                          variant="contained"
                          tabIndex={-1}
                          className="profile-btn-label"
                        >
                          <EditWhite />
                          <input
                            id="profile"
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            style={{ display: "none" }}
                            name="profile"
                            onChange={handleProfileImageChange}
                            onBlur={handleBlur}
                          />
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="profile-name-details">
                        <Index.Typography
                          component="h5"
                          variant="h5"
                          className="profile-user-name"
                        >
                          Website Favicon
                        </Index.Typography>
                        {/* <Index.Typography
                        component="p"
                        variant="p"
                        className="profile-user-email"
                      >
                        mailto:professional@gmail.com
                      </Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="profile-view-flex">
                      <Index.Box className="profile-image-view">
                        {values.logo === "" ? (
                          <img
                            src={tempProfileImageURL1 || userProfile}
                            className="profile-viewer"
                            alt="profile"
                          />
                        ) : (
                          <img
                            src={
                              tempProfileImageURL1 ||
                              `${PageIndex.imageURL}/${values.logo}`
                            }
                            className="profile-viewer"
                            alt="profile"
                          />
                        )}

                        <Index.Button
                          component="label"
                          variant="contained"
                          tabIndex={-1}
                          className="profile-btn-label"
                        >
                          <EditWhite />
                          <input
                            id="profile"
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            style={{ display: "none" }}
                            name="profile"
                            onChange={handleProfileImageChange1}
                            onBlur={handleBlur}
                          />
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="profile-name-details">
                        <Index.Typography
                          component="h5"
                          variant="h5"
                          className="profile-user-name"
                        >
                          Website Logo
                        </Index.Typography>
                        {/* <Index.Typography
                        component="p"
                        variant="p"
                        className="profile-user-email"
                      >
                        mailto:professional@gmail.com
                      </Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="profile-edit-padding">
                    <Index.Grid
                      container
                      spacing={2}
                      className="common-user-grid"
                    >
                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Website Name
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              id="name"
                              fullWidth
                              name='name'
                              variant="outlined"
                              className="common-textfield-content"
                              placeholder="Enter website name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 64 }}
                            />
                          </Index.Box>
                          {errors.name && touched.name ? (
                            <p className="from-error">{errors.name}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Email
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              id="email"
                              fullWidth
                              name='email'
                              variant="outlined"
                              className="common-textfield-content"
                              placeholder="Enter email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 64 }}
                            />
                          </Index.Box>
                          {errors.email && touched.email ? (
                            <p className="from-error">{errors.email}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Address
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              id="address"
                              fullWidth
                              name='address'
                              variant="outlined"
                              className="common-textfield-content"
                              placeholder="Enter address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 150 }}

                            />
                          </Index.Box>
                          {errors.address && touched.address ? (
                            <p className="from-error">{errors.address}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>


                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Mobile Number
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              id="mobileNumber"
                              fullWidth
                              variant="outlined"
                              name='mobileNumber'
                              type="number"
                              className="common-textfield-content"
                              placeholder="Enter your mobile number"
                              value={values.mobileNumber}
                              onChange={(e) => {
                                let number = e?.target?.value;
                                if (number?.length <= 10) {
                                  setFieldValue("mobileNumber", number);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors.mobileNumber && touched.mobileNumber ? (
                            <p className="from-error">{errors.mobileNumber}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Facebook
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              fullWidth
                              variant="outlined"
                              name='facebook'
                              type="text"
                              className="common-textfield-content"
                              placeholder="Enter your facebook link"
                              value={values.facebook}
                              onChange={handleChange}

                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors.facebook && touched.facebook ? (
                            <p className="from-error">{errors.facebook}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>


                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                          Instragram
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              fullWidth
                              variant="outlined"
                              name='instagram'
                              type="text"
                              className="common-textfield-content"
                              placeholder="Enter your instagram link"
                              value={values.instagram}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors.instagram && touched.instagram ? (
                            <p className="from-error">{errors.instagram}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>


                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                          Twitter
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              fullWidth
                              variant="outlined"
                              name='twitter'
                              type="text"
                              className="common-textfield-content"
                              placeholder="Enter your twitter link"
                              value={values.twitter}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors.twitter && touched.twitter ? (
                            <p className="from-error">{errors.twitter}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>


                      <Index.Grid item xs={6} className="common-user-grid-item">
                        <Index.Box className="common-input-field">
                          <Index.FormHelperText
                            className="common-label-details"
                            varia
                          >
                            Youtube
                          </Index.FormHelperText>
                          <Index.Box className="input-common-field">
                            <Index.TextField
                              fullWidth
                              variant="outlined"
                              name='youTube'
                              type="text"
                              className="common-textfield-content"
                              placeholder="Enter your youtube link"
                              value={values.youTube}
                            onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors.youTube && touched.youTube ? (
                            <p className="from-error">{errors.youTube}</p>
                          ) : null}
                        </Index.Box>
                      </Index.Grid>


                      <Index.Grid item xs={12} className="common-user-grid-item">
                        <Index.Box className="common-btn-details ">
                          <Index.Box className="flex-gap-footer end-justify-content">
                            <Index.Button
                              type="submit"
                              className="button-primary"
                            >
                              Submit
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>

        </Index.Box>
      </Index.Box>
    </>
  )
}

export default SiteSetting