import React, { useState } from "react";
import "./UserFaq.css"
import UserFooter from "../../../../components/user/defaultLayout/UserFooter";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UserFaq = () => {
  const [data, setData] = useState("");
  console.log({ data });
  const getData = async () => {
    try {
      const res = await PageIndex.DataService.get(PageIndex.API.ADMIN.GET_FAQ);
      setData(res?.data?.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="policy-main-box">
        <Index.Box className="policy-section">
          <Index.Box className="policy-heading">
            <Index.Typography variant="h4">
              {" "}
              Frequently Asked Questions
            </Index.Typography>
          </Index.Box>
          {data &&
            data?.map((item, index) => (
              // <Index.Box className="policy-content">
              //   <Index.Typography>
              //     Ques {index + 1} - {item?.question}{" "}
              //   </Index.Typography>
              //   <Index.Typography>Ans - {item?.answer} </Index.Typography>
              // </Index.Box>
              <Accordion className="policy-content policy-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="faq-question"
              >
              Ques.{index + 1} - {item?.question}{" "}
              </AccordionSummary>
              <AccordionDetails >
              Ans. - {item?.answer} 
              </AccordionDetails>
            </Accordion>
            ))}
        </Index.Box>
      </div>



      <UserFooter />
    </div>
  );
};

export default UserFaq;
