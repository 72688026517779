import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./admin-master.css";
import { Formik } from "formik";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import { useState } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { toast } from "react-toastify";
const AdminMasterAddEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [permissions, setPermissions] = useState([]);
  // console.log("state",state.admin);
  const adminAddSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // roleType: Yup.string().required("Role type is required"),
    email: Yup.string().required("Email is required"),
    // password: Yup.string().required("Password is required"),
    // confirmPassword: Yup.string().required("Confirm password is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const initialValues = {
    firstName: state?.admin?.firstName ? state?.admin?.firstName : "",
    lastName: state?.admin?.lastName ? state?.admin?.lastName : "",
    email: state?.admin?.email ? state?.admin?.email : "",
    password: state?.admin?.password ? state?.admin?.password : "",
    confirmPassword: state?.admin?.password ? state?.admin?.password : "",
    roleType: state?.admin?.roleType ? state?.admin?.roleType : "",
  };

  const handleAdminAddEdit = (values) => {
    const createAdmin = async () => {
      try {
        const response = await DataService.post("/admin/admin-add-edit", {
          adminId: state?.admin?._id ? state?.admin?._id : undefined,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          roleType: values.roleType,
        });
        if (response.data.status === 201 || response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/admin/home");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    createAdmin();
  };

  useEffect(() => {
    try {
      const getAllRoles = async () => {
        const response = await DataService.get("/admin/get-all-permission");
        if (response.data.status === 200) {
          setPermissions(response.data.data);
        }
      };
      getAllRoles();
    } catch (error) {
      toast.error(error.message);
    }
  }, []);

  return (
    <Box className="main-add-edit">
      <Box className="top-add-edit">
        <Typography component="h5" variant="h5">
          Add Subadmin
        </Typography>
      </Box>
      <Box className="admin-master">
        <Formik
          initialValues={initialValues}
          validationSchema={adminAddSchema}
          onSubmit={handleAdminAddEdit}
        >
          {({ values, handleSubmit, handleChange, setFieldValue, errors }) => (
            <Box
              className="admin-form"
              component="form"
              onSubmit={handleSubmit}
            >
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && (
                  <span className="error"> {errors.firstName} </span>
                )}
              </Box>
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && (
                  <span className="error"> {errors.lastName} </span>
                )}
              </Box>
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <span className="error"> {errors.email} </span>
                )}
              </Box>
              <Box className="admin-input">
                <FormControl sx={{ minWidth: 200 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Role Type
                  </InputLabel>
                  <Select
                    sx={{ width: "100%" }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={values.roleType}
                    name="roleType"
                    size="small"
                    onChange={(e) => setFieldValue("roleType", e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {permissions?.map((permission) => (
                      <MenuItem value={permission._id}>
                        {permission.RoleType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.roleType && (
                  <span className="error"> {errors.roleType} </span>
                )}
              </Box>

              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  name="password"
                  onChange={handleChange}
                  // value={values.password}
                />
                {errors.password && (
                  <span className="error"> {errors.password} </span>
                )}
              </Box>
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  // value={values.confirmPassword}
                  onChange={handleChange}
                />
                {errors.confirmPassword && (
                  <span className="error"> {errors.confirmPassword} </span>
                )}
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AdminMasterAddEdit;
