import axios from "axios";
import { toast } from "react-toastify";

const API_ENDPOINT = "http://localhost:3085/api/";
// export const imageURL = "http://localhost:3085/api/image/";
export const imageURL = "https://hire-professional.appworkdemo.com/api/image/";

let baseURL = "https://hire-professional.appworkdemo.com/api";
// let baseURL = "http://localhost:3085/api/";

let DataService = axios.create({
  baseURL: baseURL,
});

let token = localStorage.getItem("token");
if (token) {
  DataService.defaults.headers.common["auth"] = token;
}

DataService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.auth = token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    toast(error.message);
    return Promise.reject(error);
  }
);

export { DataService, API_ENDPOINT };



