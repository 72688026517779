import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import DeleteModal from "../../../../components/common/DeleteModal"
import AddEditFaq from "./AddEditFaq";
import ViewFaq from "./ViewFaq";

const FAQ = () => {
  const [faqData, setFaqData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewData, setViewData] = useState("");

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const [filterData, setFilterData] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllData = async () => {
    try {
      const response = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.GET_FAQ
      );
      if (response.status === 200) {
        setFaqData(response.data.data);
        setFilterData(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  PageIndex.useEffect(() => {
    getAllData();
  }, []);

  const handleDelete = async () => {
    try {
      const res = await PageIndex.DataService.post(PageIndex.API.ADMIN.FAQ_REMOVE, {
        FAQId: deleteId,
      });
      if (res.data.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getAllData();
      }
      setDeleteOpen(false);
    } catch (error) {
      setDeleteOpen(false);
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };


  const activeDeactiveFaq = async (id, status) => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.ADMIN.FAQ_STATUS_CHANGE,
        { FAQId: id, isActive: status }
      );
      if (res?.data?.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getAllData();
      }
    } catch (error) {
      PageIndex.toast?.error(error?.response?.data?.message);
      console.log("error", error);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = faqData?.filter((item) => {
      const matchesQuestion = item?.question && item?.question?.toLowerCase().includes(search);
      const matchesAnswer = item?.answer && item?.answer?.toLowerCase().includes(search);

      return matchesQuestion || matchesAnswer;
    });
    setFilterData(res);
  };

  return (
    <>
      <Index.Box className="main-table">
        <Index.Box className="top-table">
          <Index.Box classsName="left-heading-table">
            <Index.Typography component="h5" variant="h5">
              FAQ List
            </Index.Typography>
          </Index.Box>
          <Index.Box className="right-top-table">
          <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
            <Index.Button
              variant="contained"
              className="btn btn-primary"
              onClick={() => setEditModalOpen(true)}
            >
              Add FAQ
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.Box className="main-content">
          <Index.TableContainer className="table-container">
            <Index.Table stickyHeader aria-label="sticky table">
              <Index.TableHead>
                <Index.TableRow className="faq-table-row">
                  <Index.TableCell className="table-cell">S.No</Index.TableCell>
                  <Index.TableCell className="table-cell">Question</Index.TableCell>
                  <Index.TableCell className="table-cell">Answer</Index.TableCell>
                  <Index.TableCell className="table-cell">status</Index.TableCell>
                  <Index.TableCell className="table-cell">Action</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {filterData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((user, i) => (
                    <Index.TableRow key={user._id}>
                      <Index.TableCell>{i + 1 + page * rowsPerPage}</Index.TableCell>
                      <Index.TableCell>
                        {user?.question && user.question.length > 25 ? user.question.slice(0, 25) + '...' : user.question}
                      </Index.TableCell>

                      <Index.TableCell>
                        {user?.answer && user.answer.length > 25 ? user.question.slice(0, 25) + '...' : user.answer}
                      </Index.TableCell>

                      <Index.TableCell>

                        <Index.Switch
                          size="small"
                          checked={user?.isActive === true}
                          className="table-icon-switch"
                          onClick={() =>
                            activeDeactiveFaq(
                              user?._id,
                              user?.isActive == true ? false : true
                            )
                          }
                        />
                        {/* <Index.Button
                          onClick={() => handleActiveDeactive(user._id)}
                          type="button"
                          variant="contained"
                        >
                          {!user.isActive ? "active" : "deactive"}
                        </Index.Button> */}
                      </Index.TableCell>

                      <Index.TableCell className="table-action-icon-box">

                        {/* <VisibilityIcon
                              sx={{
                                color: "",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // setOpenView(true);
                                // setData(user)
                                navigate("/admin/professional-view", {
                                  state: { user },
                                });
                              }}
                            />

                            <BorderColorIcon
                              sx={{
                                color: "blue",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate("/admin/professional-add-edit", {
                                  state: { user },
                                });
                                // setOpenEdit(true);
                                // setData(user)
                              }}
                            /> */}
                        <Index.VisibilityIcon
                          size="small"
                          className="table-icon-edit"
                          onClick={() => {
                            setViewData(user);
                            setViewModalOpen(true)
                          }}

                        />
                        <Index.EditIcon
                          size="small"
                          className="table-icon-edit"
                          onClick={() => {
                            setEditModalOpen(true);
                            setEditData(user)
                          }}
                        />

                        <ViewFaq
                          open={viewModalOpen}
                          setOpen={setViewModalOpen}
                          data={viewData}
                          setData={setViewData}
                        />


                        <Index.DeleteIcon
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteId(user._id);
                          }}
                          sx={{
                            color: "red",
                            padding: "2px",
                            cursor: "pointer",
                          }}
                        />

                      </Index.TableCell>



                    </Index.TableRow>
                  ))}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>

          <DeleteModal
            open={deleteOpen}
            setOpen={setDeleteOpen}
            handelDelete={handleDelete}
          />
          <AddEditFaq
            open={editModalOpen}
            setOpen={setEditModalOpen}
            data={editData}
            setData={setEditData}
            getFaqData={getAllData}
          />
          <Index.TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={faqData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default FAQ;
