import React, { useState } from "react";
import "./TermsCondition.css";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import UserFooter from "../../../../components/user/defaultLayout/UserFooter";

const TermsCondition = () => {
  const [data, setData] = useState("");
  const getData = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.CMS_DATA_GET
      );
      const cleanedData = res?.data?.data[0]?.termsAndCondition?.description;
      setData(cleanedData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="policy-main-box">
        <Index.Box className="policy-section">
          <Index.Box className="policy-heading">
            <Index.Typography variant="h4">
              {" "}
              Terms & Conditions
            </Index.Typography>
          </Index.Box>

          <Index.Box className="policy-content">
            <Index.Typography
              dangerouslySetInnerHTML={{ __html: data.replace(/\n/g, "<br>") }}
            />
          </Index.Box>
        </Index.Box>
      </div>

      <UserFooter />
    </div>
  );
};

export default TermsCondition;
