import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, } from '@mui/material'
import React, { useEffect } from 'react'
// import "./admin-master.css"
import { useNavigate } from 'react-router-dom'
import { DataService } from '../../../../config/DataService'
import { useState } from 'react'
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from 'react-toastify'
import AddEditUserModel from '../../../../components/common/AddEditUserModel'
import DeleteModal from '../../../../components/common/DeleteModal'
import ViewUserModel from '../../../../components/common/ViewUserModal'
import Index from '../../../Index';

const ProfessionalsList = () => {
    const [open, setOpen] = useState(false)
    const [userId, setUserId] = useState(null)
    const [openView, setOpenView] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const admin = JSON.parse(localStorage.getItem('admin'));
    const [data, setData] = useState(null)
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filterData, setFilterData] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getAllUsers = async () => {
        try {
            const response = await DataService.get(
                "/common/get-users-professionals/professional"
            );
            if (response.status === 200) {
                setUsers(response.data.data);
                setFilterData(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getAllUsers();
    }, []);

    const handleActiveDeactive = (userId) => {
        // console.log("enter");
        const userActiveDeactive = async () => {
            try {
                const response = await DataService.post(
                    "/admin/user-active-deactive",
                    {
                        userType: "professional",
                        userId: userId
                    }
                );
                if (response.status === 200) {
                    toast.success(response.data.message);
                    getAllUsers();

                }
            } catch (error) {
                console.log("error", error);
                toast.error(error?.response?.data?.message);
            }
        }
        userActiveDeactive();

    }
    const handelDelete = async () => {
        try {
            const response = await DataService.delete(`/admin/delete-user-by-admin/${userId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                getAllUsers();
                setOpen(false);
                setUserId(null)
            } else {
                setUserId(null)
            }
        } catch (error) {
            setUserId(null)
            toast.error(error?.response?.data?.message);
        }
    }
    const handelSubmitButton = (values) => {
        const addEditUser = async () => {
            try {
                let body = {
                    userId: data?._id ? data?._id : undefined,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    city: values.city,
                    userType: "professional"
                }
                if (!data) {
                    body.password = values.password
                }
                const response = await DataService.post("/admin/add-edit-user-by-admin", body);
                if (response.data.status === 200 || response.data.status == 201) {
                    values.firstName = "";
                    values.lastName = "";
                    values.email = "";
                    values.password = "";
                    values.city = "";
                    getAllUsers();
                    toast.success(response.data.message);
                    setOpenEdit(false)
                    setData(null)
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
        addEditUser();
    }

    const handleSearchChange = (e) => {
        const search = e.target.value.trim().toLowerCase();
        const res = users?.filter((item) => {
          const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();
          const matchesFullName = fullName.includes(search);
          const matchesEmail = item?.email && item?.email?.toLowerCase().includes(search);
    
          return matchesFullName || matchesEmail;
        });
        setFilterData(res);
      };

    return (
        <Box className="main-table">
            <Box className="top-table">
                <Box classsName="left-heading-table">
                    <Typography component="h5" variant="h5">
                        Professional List
                    </Typography>
                </Box>
                <Box className="right-top-table-custom">
                    <Index.TextField
                        size="small"
                        placeholder="Search"
                        onChange={(e) => handleSearchChange(e)}
                    />
                    {
                        admin?.roleType?.permission?.ProfessionalManagement?.Add && (
                            <Box className="right-top-table">
                                <Button
                                    variant="contained"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        navigate("/admin/professional-add-edit")
                                        // setOpenEdit(true);
                                    }}>
                                    Add Professional
                                </Button>
                            </Box>
                        )
                    }
                </Box>
            </Box>

            <Box className="main-content">
                <TableContainer sx={{ overflow: "scroll", height: "400px" }} >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S. No.</TableCell>
                                <TableCell >Name</TableCell>
                                <TableCell>email</TableCell>
                                {
                                    admin?.roleType?.permission?.ProfessionalManagement?.Active && (
                                        <TableCell>status</TableCell>
                                    )
                                }
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((user, i) => (
                                <TableRow key={user._id}>
                                    <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell>
                                        {`${user.firstName} ${user.lastName}`}
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    {

                                        admin?.roleType?.permission?.ProfessionalManagement?.Active && (
                                            <TableCell>
                                                <Button onClick={() => handleActiveDeactive(user._id)} type='button' variant="contained" >
                                                    {!user.isActive ? "active" : "deactive"}
                                                </Button>
                                            </TableCell>
                                        )
                                    }
                                    <TableCell>
                                        <Box className="mian-table-icon">
                                            <Box className="first-icon-edit">
                                                {
                                                    admin?.roleType?.permission?.ProfessionalManagement?.View && (
                                                        <VisibilityIcon
                                                            sx={{ color: "", padding: "2px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                // setOpenView(true);
                                                                // setData(user)
                                                                navigate("/admin/professional-view", { state: { user } })
                                                            }}
                                                        />
                                                    )
                                                }
                                                {
                                                    admin?.roleType?.permission?.ProfessionalManagement?.Edit && (
                                                        <BorderColorIcon
                                                            sx={{
                                                                color: "blue",
                                                                padding: "2px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                navigate("/admin/professional-add-edit", { state: { user } })
                                                                // setOpenEdit(true);
                                                                // setData(user)
                                                            }}
                                                        />
                                                    )
                                                }
                                                {
                                                    admin?.roleType?.permission?.ProfessionalManagement?.Delete && (
                                                        <DeleteIcon
                                                            onClick={() => { setOpen(true); setUserId(user._id) }}
                                                            sx={{ color: "red", padding: "2px", cursor: "pointer" }}
                                                        />
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <DeleteModal open={open} setOpen={setOpen} handelDelete={handelDelete} />
                                    <AddEditUserModel
                                        type="Professional"
                                        data={data}
                                        setData={setData}
                                        open={openEdit}
                                        setOpen={setOpenEdit}
                                        handelSubmitButton={handelSubmitButton}
                                    />
                                    <ViewUserModel
                                        type="Professional"
                                        data={data}
                                        setData={setData}
                                        open={openView}
                                        setOpen={setOpenView}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Index.TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>

        </Box>
    )
}

export default ProfessionalsList