import './App.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import Routes from './routes/Routes';
import "./assets/style/global.css";
import PageIndex from './container/PageIndex';

function App() {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
    
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.GET_SITE_SETTING
      );
      if (res?.data?.status === 200) {
        console.log({res});
      const favicon = document.querySelector('link[rel="icon"]');
      // favicon.href = data.faviconUrl; 
      favicon.href =`${PageIndex.imageURL}/${res?.data?.data[0]?.faviconIcon}`; 

      // document.title = data.title; 
      document.title = res?.data?.data[0]?.websiteName; 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="App">
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
