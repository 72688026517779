import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import "./common.css"
import { toast } from "react-toastify";
import { DataService } from "../../config/DataService";
import { Formik } from "formik";

const ViewAdminModel = ({ data, setData, open, setOpen }) => {
    const [permissions, setPermissions] = useState([]);
    const handleClose = () => {
        setData(null);
        setOpen(false);
    }
    useEffect(() => {
        try {
            const getAllRoles = async () => {
                const response = await DataService.get("/admin/get-all-permission");
                if (response.data.status === 200) {
                    setPermissions(response.data.data)
                }
            };
            getAllRoles();
        } catch (error) {
            toast.error(error.message);
        }
    }, [])
    return (
        <Modal
            className="cus-main-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="cus-modal">
                <Typography
                    className="modal-heading-text"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    View Admin
                </Typography>
                <Box>
                    <Formik>
                        {({ setFieldValue }) => (
                            <Box className='admin-form' >
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        name="firstName"
                                        disabled={true}
                                        value={data?.firstName}
                                    />
                                </Box>
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined"
                                        name="lastName"
                                        disabled={true}
                                        value={data?.lastName}
                                    />
                                </Box>
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        disabled={true}
                                        value={data?.email}
                                    />
                                </Box>
                                <Box className="admin-input">
                                    <FormControl sx={{ minWidth: 200 }} size="small">
                                        <InputLabel id="demo-select-small-label">Role Type</InputLabel>
                                        <Select
                                            sx={{ width: "100%" }}
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={data?.roleType}
                                            name='roleType'
                                            size='small'
                                            disabled={true}
                                            onChange={(e) => setFieldValue("roleType", e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                permissions?.map((permission) => (
                                                    <MenuItem value={permission._id}>{permission.RoleType}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* <Box className="main-button-modal">
                                    <Box className="first-cancel-btn">
                                        <Button onClick={() => { setOpen(false); setData(null) }}>Cancel</Button>
                                    </Box>
                                    <Box className="second-delete-btn">
                                        <Button type="submit" >save</Button>
                                    </Box>
                                </Box> */}
                            </Box>
                        )}
                    </Formik>
                </Box>

            </Box>
        </Modal>
    )
}

export default ViewAdminModel