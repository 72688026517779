import React, { useEffect } from 'react';
import './UserAuth.css';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { userLoginSchema } from '../../../validation/UserValidation';
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/API";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function Login() {
    const { id } = useParams()
    const navigate = useNavigate();
    const initialValues = {
        email: "",
        password: "",
    };
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event?.preventDefault();
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialValues,
            validationSchema: userLoginSchema,
            onSubmit: (values, action) => {
                loginSubmit(values);
                action.resetForm();
            },
        });

    const loginSubmit = async (value) => {
        try {
            const res = await DataService.post(API.USER.USER_LOGIN, value);
            if (res.data.status == 200) {
                toast.success(res?.data?.message);
                localStorage.setItem("token", res?.data?.data?.JwtToken);
                localStorage.setItem("userData", JSON?.stringify(res.data.data));
                if (res?.data?.data?.userType == "professional") {
                    navigate("/professional");
                } else {
                    navigate("/");
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, "error");
        }
    };

    useEffect(() => {
        if (id === '0') {
            toast.success("User Verified Successfully");
        } else if (id === '1') {
            toast.success("User Already Verified");
        }
    }, [id]);
    return (
        <>
            <div class="meanClass">
                <div class="login-left"></div>
                <div class="rigth common">
                    <h2 class="user-heding">User Login</h2>
                    <form className='main-form ' onSubmit={handleSubmit}>
                        <label className="user-from" for="email">
                            Email:
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                            <p className="from-error">{errors.email}</p>
                        ) : null}

                        <label className="user-from" for="password">
                            Password:
                        </label>
                        <OutlinedInput
                            fullWidth
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={values.password}
                            placeholder="Enter your password"
                            className='password-view'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errors.password && touched.password ? (
                            <p className="from-error">{errors.password}</p>
                        ) : null}

                        <div className="user-forgot">
                            <a href="/user/forgot-password" className="user-forgot">
                                Forgot Password
                            </a>
                        </div>
                        <div class="user-submit-button">
                            <button type="submit">Login</button>
                        </div>
                        <div className="signUp">
                            <p>
                                {" "}
                                Don't have an account?{" "}
                                <a className="sign-Bullon" href="/user/signup">
                                    Sign Up here
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login
