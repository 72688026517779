import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const ContactUs = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllContacts = async () => {
    try {
      const response = await PageIndex.DataService.get(PageIndex.API.ADMIN.CONTACT_US_LIST);
      setLoading(true)
      if (response.status === 200) {
        setData(response.data.data);
        setFilterData(response.data.data);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = data?.filter((item) => {
        const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();
        const matchesFullName = fullName.includes(search);
        const matchesEmail = item?.email && item?.email?.toLowerCase().includes(search);
        const matchesNumber = item?.mobileNumber && item?.mobileNumber.toString().includes(search);
        const matchesMessage = item?.message && item?.message?.toLowerCase().includes(search);

        return matchesFullName || matchesEmail || matchesNumber || matchesMessage;
    });
    setFilterData(res);
};

  PageIndex.useEffect(() => {
    getAllContacts();
  }, []);
  return (
    <Index.Box className="main-table">
      {/* {loading && <Lodader />} */}
      <Index.Box className="top-table">
        <Index.Box classsName="left-heading-table">
          <Index.Typography component="h5" variant="h5">
            Contact-Us
          </Index.Typography>
        </Index.Box>
        <Index.TextField
          className="search-filter-heading"
          size="small"
          placeholder="Search"
          onChange={(e) => handleSearchChange(e)}
        />
      </Index.Box>

      <Index.Box className="main-content">
        <Index.TableContainer className="table-container">
          <Index.Table stickyHeader aria-label="sticky table">
            <Index.TableHead>
              <Index.TableRow className="faq-table-row">
                <Index.TableCell className="table-cell">S.No</Index.TableCell>
                <Index.TableCell className="table-cell">Name</Index.TableCell>
                <Index.TableCell className="table-cell">Email</Index.TableCell>
                <Index.TableCell className="table-cell">Number</Index.TableCell>
                <Index.TableCell className="table-cell">Message</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {filterData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((user, i) => (
                  <Index.TableRow key={user._id}>
                    <Index.TableCell>{i + 1 + page * rowsPerPage}</Index.TableCell>
                    <Index.TableCell> {user?.fullName}</Index.TableCell>
                    <Index.TableCell>{user.email}</Index.TableCell>
                    <Index.TableCell>{user.mobileNumber}</Index.TableCell>
                    <Index.TableCell>{user.message}</Index.TableCell>
                  </Index.TableRow>
                ))}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default ContactUs;
