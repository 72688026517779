import React, { useState } from 'react';
import Index from '../../../Index';
import '../../../user/pages/changePassword/userChangePassword.css';
import { useFormik } from 'formik';
import { changePasswordSchema } from '../../../../validation/UserValidation';
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/API";
import { toast } from 'react-toastify';
import LockAnimation from '../../../../assets/images/svg/LockAnimation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';

const UserChangePassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: changePasswordSchema,
        onSubmit: async (value, { resetForm }) => {
            try {
                const token = localStorage.getItem("token");
                const headers = { headers: { auth: token } };

                const res = await DataService.post(API.USER.USER_CHANGE_PASSWORD, value, headers);
                if (res?.data?.status == 200) {
                    toast.success(res?.data?.message);
                    navigate("/");
                    resetForm();
                }
            } catch (error) {

                toast.error(error?.response?.data?.message, "error");
                resetForm();
            }
        }
    });

    return (
        <Index.Box className="change-password-admin-content">
            <Index.Box className="change-password-card-max">
                <Index.Box className="change-pass-card">
                    <Index.Box className="change-pass-card-inner">
                        <Index.Box className="lock-rounded-details">
                            <Index.Box className="lock-rounded-bg">
                                <LockAnimation />
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="admin-flex-common">
                            <Index.Typography className="admin-common-title">Change Password</Index.Typography>
                            <Index.Typography className="admin-pass-title-desc">
                                Please enter your Change password
                            </Index.Typography>
                        </Index.Box>
                        <form className='change-password-form' onSubmit={formik.handleSubmit}>
                            <Index.Box className="change-password-details">
                                <Index.Grid container spacing={2} className="common-admin-grid">
                                    <Index.Grid item xs={12} className="common-admin-grid-item">
                                        <Index.Box className="common-input-field">
                                            <Index.FormHelperText className="common-label-details" varia>
                                                Old Password
                                            </Index.FormHelperText>
                                            <Index.Box className="input-common-field">
                                                <Index.FormControl className=" change-pass-textfield">
                                                    <Index.OutlinedInput
                                                        className="outline-change-pass-details"
                                                        name="oldPassword"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.oldPassword}
                                                        onChange={formik.handleChange}
                                                        placeholder='Enter your old password'
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <Index.InputAdornment
                                                                position="end"
                                                                className="input-change-pass-position"
                                                            >
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    onMouseUp={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    edge="end"
                                                                    className="icon-password-details"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                </Index.FormControl>
                                                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                                    <p className='from-error'>{formik.errors.oldPassword}</p>
                                                ) : null}
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid item xs={12} className="common-admin-grid-item">
                                        <Index.Box className="common-input-field">
                                            <Index.FormHelperText className="common-label-details">
                                                New Password
                                            </Index.FormHelperText>
                                            <Index.Box className="input-common-field">
                                                <Index.FormControl className=" change-pass-textfield">
                                                    <Index.OutlinedInput
                                                        className="outline-change-pass-details"
                                                        name="newPassword"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.newPassword}
                                                        onChange={formik.handleChange}
                                                        placeholder='Enter your new password'
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <Index.InputAdornment
                                                                position="end"
                                                                className="input-change-pass-position"
                                                            >
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowNewPassword}
                                                                    onMouseDown={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    onMouseUp={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    edge="end"
                                                                    className="icon-password-details"
                                                                >
                                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                </Index.FormControl>
                                                {formik.touched.newPassword && formik.errors.newPassword ? (
                                                    <p className='from-error'>{formik.errors.newPassword}</p>
                                                ) : null}
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid item xs={12} className="common-admin-grid-item">
                                        <Index.Box className="common-input-field">
                                            <Index.FormHelperText className="common-label-details" varia>
                                                Confirm Password
                                            </Index.FormHelperText>
                                            <Index.Box className="input-common-field">
                                                <Index.FormControl className=" change-pass-textfield">
                                                    <Index.OutlinedInput
                                                        className="outline-change-pass-details"
                                                        name="confirmPassword"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.confirmPassword}
                                                        onChange={formik.handleChange}
                                                        placeholder='Enter your confirm password'
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <Index.InputAdornment
                                                                position="end"
                                                                className="input-change-pass-position"
                                                            >
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    onMouseUp={(event) => {
                                                                        event.preventDefault();
                                                                    }}
                                                                    edge="end"
                                                                    className="icon-password-details"
                                                                >
                                                                    {showConfirmPassword ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                </Index.FormControl>
                                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                    <p className='from-error'>{formik.errors.confirmPassword}</p>
                                                ) : null}
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid item xs={12} className="common-admin-grid-item">
                                        <Index.Box className="common-btn-details ">
                                            <Index.Box className="flex-gap-footer">
                                                <Index.Button type="submit" className='btn-primary'>
                                                    Submit
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                </Index.Grid>
                            </Index.Box>
                        </form>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}

export default UserChangePassword