import React from 'react'
import { Box,Typography } from '@mui/material'

import { useLocation, useNavigate } from 'react-router-dom';
import ViewCardProfessional from '../../../../components/common/ViewCardProfessional';

const ProfessionalView = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <Box className="main-add-edit">
            <Box className="top-add-edit">
                <Typography component="h5" variant="h5" >
                    View Professional
                </Typography>
            </Box>

            <ViewCardProfessional user={state.user} />

        </Box>
    )
}

export default ProfessionalView