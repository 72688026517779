import React, { useEffect, useState } from 'react';
import './UserAuth.css';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { UserSignUp } from '../../../validation/UserValidation';
import Index from '../../Index';
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/API";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';

const SignUp = () => {
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        DOB: "",
        city: "",
        mobileNumber: "",
        confirmPassword: "",
        gender: ""
    }
    // password
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [subconfigurations, setSubconfigurations] = useState([]);
    const [subconfigurationsCity, setSubconfigurationsCity] = useState([]);
    const [genderId, setGenderId] = useState("");
    const [cityId, setCityId] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: UserSignUp,
        onSubmit: (values, action) => {
            signUpSubmit(values)
            action.resetForm();
        }
    })

    const signUpSubmit = async (value) => {
        try {
            const res = await DataService.post(API.USER.USER_SIGNUP, value);
            if (res?.data?.status === 201) {
                toast?.success(res?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    const getAllConfigurationData = async () => {
        try {
            const res = await DataService.get(API.USER.CONFIGURATION_GET_ALL);
            if (res?.data?.status === 200) {
                const configurations = res.data.data;
                const genderConfig = configurations.find(config => config.title === "Gender");
                const cityConfig = configurations.find(config => config.title === "City");
                setGenderId(genderConfig._id);
                setCityId(cityConfig._id);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }


    const getAllCity = async () => {
        try {
            const response = await DataService.get(
                `/common/get-all-subconfiguration/${cityId}`
            );
            if (response.status === 200) {
                setSubconfigurationsCity(response.data.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllSubConfiguration = async () => {
        try {
            const response = await DataService.get(
                `/common/get-all-subconfiguration/${genderId}`
            );
            if (response.status === 200) {
                setSubconfigurations(response.data.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getAllConfigurationData();
    }, []);


    useEffect(() => {
        if (cityId) {
            getAllCity();
        }
    }, [cityId]);

    useEffect(() => {
        if (genderId) {
            getAllSubConfiguration();
        }
    }, [genderId])

    const getMaxDate = () => {
        const currentDate = new Date();
        const maxDate = new Date(currentDate?.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
        return maxDate?.toISOString()?.split('T')[0];
    };
    return (
        <div className="meanClass">
            <div className="left "></div>
            <div className="rigth common">
                <h2 className="user-heding">Registration</h2>
                <div className='user-resister-form'>
                    <form className='main-form' onSubmit={handleSubmit} >
                        <div>
                            <label className='user-from' htmlFor="firstName">First Name:</label>
                            <input type="text" id="firstName" name="firstName" placeholder="Enter your first name"
                                value={values.firstName} onChange={handleChange} />
                            {errors.firstName && touched.firstName ? (<p className='from-error'>{errors.firstName}</p>) : null}
                        </div>
                        <label className='user-from' htmlFor="lastName">Last Name:</label>
                        <input type="text" id="lastName" name="lastName" placeholder="Enter your last name"
                            value={values.lastName} onChange={handleChange} />
                        {errors.lastName && touched.lastName ? (<p className='from-error'>{errors.lastName}</p>) : null}

                        <label className='user-from' htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" placeholder="Enter your email"
                            value={values.email} onChange={handleChange} />
                        {errors.email && touched.email ? (<p className='from-error'>{errors.email}</p>) : null}

                        <label className='user-from' htmlFor="DOB">Date of Birth:</label>
                        <input
                            type="date"
                            id="DOB"
                            name="DOB"
                            placeholder="Enter your DOB"
                            value={values.DOB}
                            onChange={handleChange}
                            max={getMaxDate()}
                        />
                        {errors.DOB && touched.DOB ? (<p className='from-error'>{errors.DOB}</p>) : null}

                        <label className='user-from' htmlFor="mobileNumber">Mobile Number:</label>
                        <input type="number" id="mobileNumber" name="mobileNumber" placeholder="Enter your mobile number" maxLength="10"
                            value={values.mobileNumber} onChange={handleChange} />
                        {errors.mobileNumber && touched.mobileNumber ? (<p className='from-error'>{errors.mobileNumber}</p>) : null}


                        <label className='user-from' htmlFor="city">City:</label>

                        <Index.TextField
                            type="text"
                            // label="Select city"
                            fullWidth
                            className='drop-down-select'
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            select
                        >
                            {subconfigurationsCity && subconfigurationsCity?.map((item, index) => (

                                <Index.MenuItem key={item._id} value={item._id}>
                                    {item.title}
                                </Index.MenuItem>
                            ))}
                        </Index.TextField>
                        {errors.city && touched.city ? (<p className='from-error'>{errors.city}</p>) : null}

                        <label className='user-from' htmlFor="gender">Gender:</label>
                        <Index.TextField
                            type="text"
                            // label="Select gender"
                            fullWidth
                            className='drop-down-select'
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            select
                        >
                            {subconfigurations.map((item, index) => (
                                <Index.MenuItem key={item._id} value={item._id}>
                                    {item.title}
                                </Index.MenuItem>
                            ))}
                        </Index.TextField>
                        {errors.gender && touched.gender ? (<p className='from-error'>{errors.gender}</p>) : null}

                        <label className='user-from' htmlFor="password">Password:</label>
                        <OutlinedInput
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={values.password}
                            placeholder="Enter your password"
                            className='password-view'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errors.password && touched.password ? (<p className='from-error'>{errors.password}</p>) : null}

                        <label className='user-from' htmlFor="confirmPassword">Confirm Password:</label>
                        <OutlinedInput
                            fullWidth
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            value={values.confirmPassword}
                            placeholder="Enter your confirm password"
                            className='password-view'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (<p className='from-error'>{errors.confirmPassword}</p>) : null}

                        <div class="user-submit-button">
                            <button type="submit" >
                                Submit
                            </button>
                        </div>
                        <div className="button-for-login">
                            <p>
                                {" "}
                                Already have an account?{" "}
                                <a className="sign-Bullon" href="/user/login">
                                    Login here
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
