import React, { useEffect, useState } from 'react'
import Index from '../../../Index'
import './home.css'
import UserFooter from '../../../../components/user/defaultLayout/UserFooter';
import sliderfour from '../../../../assets/images/jpg/sliderfour.webp';
import PageIndex from '../../../PageIndex';
import { userContactDetail } from '../../../../validation/UserValidation';

export default function Home() {
    const [siteSetting, setSiteSetting] = useState([]);
    console.log(siteSetting,"siteSetting");

    const submitForm = async (values, { resetForm }) => {
        try {
            const res = await PageIndex.DataService.post(
                PageIndex.API.USER.USER_CONTACT_US,
                {
                    fullName: values.fullName,
                    email: values.email,
                    mobileNumber: values.mobileNumber,
                    message: values.message,
                }
            );
            if (res.data.status === 201) {
                PageIndex.toast.success(res?.data?.message);
                resetForm();
            }
        } catch (error) {
            PageIndex.toast.error(error?.response?.data?.message);
            resetForm();
        }
    };

    const [webData, setWebData] = useState({});
  const getSiteData = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.GET_SITE_SETTING
      );
      if (res?.data?.status === 200) {
        setWebData(res?.data?.data[0]);
      }
    } catch (error) {
        console.log(error,"error");
    }
  };

  PageIndex.useEffect(() => {
    getSiteData();
}, []);
console.log({webData});

    return (
        <>
            <Index.Box className="slider-home-user">

                <div className="hero-slick-key slider-swiper-details">
                    <Index.Box
                        style={{
                            height: "100vh",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            display: "flex",
                            backgroundImage: `linear-gradient(45deg, #00000073, #00000073),url(${sliderfour})`
                        }}
                        className="swiper-bg-content"
                    >
                        <Index.Box className="container">
                            <Index.Box className="swiper-detail-content">
                                <Index.Typography component="h4" variant="h4" className="swiper-heading">
                                    Top Hore Professional !
                                </Index.Typography>
                                <Index.Typography component="h2" variant="h2" className="swiper-heading-inner">
                                    New Collection
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="swiper-heading-description"
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                </Index.Typography>
                                <Index.Box className="swiper-btn-details">
                                    <Index.Box className='common-btn-details'>
                                        <Index.Button className='btn-primary'>See More</Index.Button>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </div>
            </Index.Box>

            <Index.Box className="contact-us-common">
                <Index.Box className="container">
                    <Index.Grid container spacing={3} className="common-admin-grid">
                        <Index.Grid item lg={6} xs={12} className="common-admin-grid-item">
                            <Index.Box className="contact-us-details-right">
                                <Index.Typography variant="h5" component="h5" className="get-contact-title">
                                    Get in Touch
                                </Index.Typography>
                                <Index.Typography variant="p" component="p" className="get-dercription">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum earum eveniet
                                    dolorum suscipit nesciunt incidunt animi repudiandae ab at, tenetur distinctio
                                    voluptate vel illo similique.
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="contact-details-show">
                                <Index.Box className="contact-details-list">
                                    <span>Phone : </span> {webData?.mobileNumber}
                                </Index.Box>
                                <Index.Box className="contact-details-list">
                                    <span>Email : </span>{webData?.email}
                                </Index.Box>
                                <Index.Box className="contact-details-list">
                                    <span>Address : </span> {webData?.address}
                                </Index.Box>
                            </Index.Box>
                            <PageIndex.Formik
                                validationSchema={userContactDetail}
                                initialValues={{
                                    fullName: "",
                                    email: "",
                                    mobileNumber: "",
                                    message: ""
                                }}
                                onSubmit={submitForm}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    handleSubmit,
                                    setFieldValue,
                                }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSubmit();
                                            }
                                        }}
                                    >

                                        <Index.Box className="contact-form-list">
                                            <Index.Grid container spacing={3} className="common-admin-grid">
                                                <Index.Grid item lg={12} xs={12} className="common-admin-grid-item">
                                                    <Index.Box className="common-input-field">
                                                        <Index.FormHelperText className="common-label-details" varia>
                                                            Your Name<span className="required"> *</span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="input-common-field">
                                                            <Index.TextField
                                                                id="outlined-basic"
                                                                fullWidth
                                                                variant="outlined"
                                                                className="common-textfield-content"
                                                                placeholder="Enter your Name"
                                                                type="text"
                                                                name="fullName"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values?.fullName}
                                                                inputProps={{ maxLength: 64 }}

                                                            />
                                                             {errors.fullName && touched.fullName &&(
                                                            <span className='error'> {errors.fullName} </span>
                                                               )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                                <Index.Grid item lg={12} xs={12} className="common-admin-grid-item">
                                                    <Index.Box className="common-input-field">
                                                        <Index.FormHelperText className="common-label-details" varia>
                                                            Email<span className="required"> *</span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="input-common-field">
                                                            <Index.TextField
                                                                id="outlined-basic"
                                                                fullWidth
                                                                variant="outlined"
                                                                className="common-textfield-content"
                                                                placeholder="Enter your email"
                                                                type="text"
                                                                name="email"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values?.email}
                                                                inputProps={{ maxLength: 64 }}
                                                            />
                                                              {errors.email &&  touched.email &&(
                                                            <span className='error'> {errors.email} </span>
                                                               )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                                <Index.Grid item lg={12} xs={12} className="common-admin-grid-item">
                                                    <Index.Box className="common-input-field">
                                                        <Index.FormHelperText className="common-label-details" varia>
                                                            Mobile Number<span className="required"> *</span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="input-common-field">
                                                            <Index.TextField
                                                                id="outlined-basic"
                                                                fullWidth
                                                                variant="outlined"
                                                                className="common-textfield-content"
                                                                placeholder="Enter your mobile number"
                                                                type="number"
                                                                name="mobileNumber"
                                                                onBlur={handleBlur}
                                                                // onChange={handleChange}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 10) {
                                                                      handleChange(e);
                                                                    }
                                                                  }}
                                                                value={values?.mobileNumber}
                                                                inputProps={{ maxLength: 10 }}
                                                            />
                                                                {errors.mobileNumber && touched.mobileNumber && (
                                                            <span className='error'> {errors.mobileNumber} </span>
                                                               )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                                <Index.Grid item lg={12} xs={12} className="common-admin-grid-item">
                                                    <Index.Box className="common-input-field">
                                                        <Index.FormHelperText className="common-label-details" varia>
                                                            Message<span className="required"> *</span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="input-common-field">
                                                            <Index.TextareaAutosize
                                                                id="review"
                                                                name="message"
                                                                placeholder="Message"
                                                                className="common-auto-textarea"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values?.message}
                                                                maxLength="250"
                                                            ></Index.TextareaAutosize>
                                                                {errors.message && touched.message && (
                                                            <span className='error'> {errors.message} </span>
                                                               )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                                <Index.Grid item lg={12} xs={12} className="common-admin-grid-item">
                                                    <Index.Box className="btn-contact-main">
                                                        <Index.Box className='common-btn-details'>
                                                            <Index.Button className='btn-primary' type='submit'>Submit</Index.Button>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                            </Index.Grid>
                                        </Index.Box>

                                    </form>
                                )}
                            </PageIndex.Formik>
                        </Index.Grid>
                    </Index.Grid>
                </Index.Box>
            </Index.Box>
            <UserFooter />
        </>
    )
}
