import React, { useState } from "react";
import "./Availability.css";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

export default function Availability() {
  let userType = JSON.parse(localStorage.getItem("userData"));
  const [id, setId] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const minSelectableDate = new Date();
  const maxSelectableDate = new Date(
    minSelectableDate.getTime() + 29 * 24 * 60 * 60 * 1000
  );

  const hanldeSubmit = async () => {
    try {
      setBtnLoading(true);
      const res = await PageIndex.DataService.post(
        PageIndex.API.SERVICE.AVAILABILITY_ADD,
        {
          id: id,
          userId: userType?._id,
          time: selectedDates,
        }
      );
      if (res.data.status == 200) {
        PageIndex.toast.success(res.data.message);
        getData();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const getData = async () => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.SERVICE.AVAILABILITY_GET,
        {
          userId: userType?._id,
        }
      );
      if (res.data.status == 200) {
        setId(res.data.data._id);
        setSelectedDates(res.data.data.time.map((date) => new Date(date)));
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  PageIndex.useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (dates) => {
    const formattedDates = dates.map((date) => date.format("YYYY/MM/DD"));
    setSelectedDates(formattedDates);
  };

  return (
    <>
      
      <div className="availbility-main-box">
        <Index.Box>
          <Index.Box className="availability-btn-box">
            <Index.Typography className="availbility-text-box">
              {" "}
              Service Availability Schedule :-
            </Index.Typography>
            <Index.Button
              className="availability-btn"
              onClick={hanldeSubmit}
              disabled={btnLoading}
            >
              Update
            </Index.Button>
          </Index.Box>

          <PageIndex.Calendar
            multiple
            onlyShowInRangeDates={true}
            onChange={handleDateChange}
            value={selectedDates}
            // plugins={[<PageIndex.DatePanel />]}
            minDate={minSelectableDate}
            maxDate={maxSelectableDate}
            className="calender-tag"
          />
        </Index.Box>
      </div>
    </>
  );
}
