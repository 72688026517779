import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect } from "react";
// import "./admin-master.css"
import { useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import AddEditModel from "../../../../components/common/AddEditModel";
import DeleteModal from "../../../../components/common/DeleteModal";
import Index from "../../../Index";
const Configuration = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();
  const [configurations, setConfiguration] = useState([]);
  const [open, setOpen] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState(null);
  const [values, setValues] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getAllConfiguration = async () => {
    try {
      const response = await DataService.get("/admin/get-all-configuration");
      if (response.status === 200) {
        setConfiguration(response.data.data);
        setFilterData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(JSON.parse(error?.request?.response)?.message);
    }
  };
  useEffect(() => {
    getAllConfiguration();
  }, []);

  const handelSubmitButton = (values) => {
    const createConfiguration = async () => {
      try {
        const response = await DataService.post(
          "/admin/add-edit-configuration",
          {
            configurationId: data ? data?._id : undefined,
            title: values.title,
          }
        );
        if (response.data.status === 201 || response.data.status === 200) {
          toast.success(response.data.message);
          setOpenEdit(false);
          getAllConfiguration();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log("error creating configuration");
        toast.error(JSON.parse(error?.request?.response)?.message);
      }
    };
    createConfiguration();
  };

  const handelDelete = async () => {
    try {
      const response = await DataService.delete(
        `/admin/delete-configuration/${configurationId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        getAllConfiguration();
        setOpen(false);
        setConfigurationId(null);
      } else {
        setConfigurationId(null);
      }
    } catch (error) {
      setConfigurationId(null);
      toast.error(JSON.parse(error?.request?.response)?.message);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = configurations?.filter((item) => {
      const matchesTitle = item?.title && item?.title?.toLowerCase().includes(search);

      return matchesTitle;
    });
    setFilterData(res);
  };

  return (
    <Box className="main-table">
      <Box className="top-table">
        <Box classsName="left-heading-table">
          <Typography component="h5" variant="h5">
            Configuration
          </Typography>
        </Box>
        <Box className="right-top-table-custom">
          <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
          {admin?.roleType?.permission?.ConfigurationManagement?.Add && (
            <Box className="right-top-table">
              <Button
                variant="contained"
                className="btn btn-primary"
                onClick={() => setOpenEdit(true)}
              >
                Add Configuration
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="main-content">
        <TableContainer className="table-main" sx={{ overflow: "scroll" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S. No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((configuration, i) => (
                <TableRow key={configuration._id}>
                  <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{configuration.title}</TableCell>
                  <TableCell>
                    <Box className="mian-table-icon">
                      <Box className="first-icon-edit">
                        {admin?.roleType?.permission?.ConfigurationManagement
                          ?.View && (
                            <span>
                              <VisibilityIcon
                                sx={{
                                  color: "",
                                  padding: "2px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/admin/add-sub-configuration", {
                                    state: { configuration },
                                  });
                                }}
                              />
                            </span>
                          )}
                        {admin?.roleType?.permission?.ConfigurationManagement
                          ?.Edit && (
                            <span>
                              <BorderColorIcon
                                sx={{
                                  color: "blue",
                                  padding: "2px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenEdit(true);
                                  setData(configuration);
                                }}
                              />
                            </span>
                          )}
                        {admin?.roleType?.permission?.ConfigurationManagement
                          ?.Delete && (
                            <span>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  padding: "2px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpen(true);
                                  setConfigurationId(configuration._id);
                                }}
                              />
                            </span>
                          )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={configurations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <AddEditModel
        data={data}
        setData={setData}
        open={openEdit}
        setOpen={setOpenEdit}
        handelSubmitButton={handelSubmitButton}
      />
      <DeleteModal open={open} setOpen={setOpen} handelDelete={handelDelete} />
    </Box>
  );
};

export default Configuration;
