import { Box } from "@mui/material";
import React, { useState } from "react";
import "./Layout.css"
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Navigate, Outlet } from "react-router-dom";

const Layout = () => {
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(true);
  return (
    <>
      {
        token ? (
          <Box className="layout-main-box">
            <Box className={`layout-left-main ${open ? "active" : "user-sidebar-deactive"}`} >
              <Sidebar />
            </Box>
            <Box className="layout-right-main">
              <Header className="header" setOpen={setOpen} open={open} />
              <Box>
                <Outlet />
              </Box>
            </Box>
          </Box>
        ) :
          (
            <Navigate to="/admin" />
          )
      }
    </>
  );
};

export default Layout;