
import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { ServiceAddEdit } from "../../../../validation/UserValidation";

const AddEditServices = ({ open, setOpen, data, setData, getServiceDetails }) => {

  const [btnLoading, setBtnLoading] = useState(false);
  console.log({ data });
  const handleClose = () => {
    setOpen(false);
    setData([])
  };

  const submitForm = async (value) => {
    try {
      setBtnLoading(true);
      const res = await PageIndex.DataService.post(PageIndex.API.USER.PROFESSIONAL_SERVICE_ADDEDIT, {
        serviceId: data?._id,
        serviceName: value.serviceName,
      });
      if (res?.data?.status == 200 || 201) {
        PageIndex.toast.success(res?.data?.message);
        handleClose();
        getServiceDetails();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      handleClose();
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PageIndex.Formik
          validationSchema={ServiceAddEdit}
          initialValues={{
            serviceName: data ? data.serviceName : "",

          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Index.Box className="packages-modal-main">
                <Index.Box className="prof-heading-box">
                  <Index.Typography className="prof-heading-text">
                    {data?._id ? "Edit Service" : "Add Service"}
                  </Index.Typography>
                </Index.Box>

                <Index.TextField
                  placeholder="Service Name"
                  size="small"
                  className="package-name-input"
                  onChange={handleChange}
                  value={values?.serviceName}
                  name="serviceName"
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 64 }}

                />
                {errors?.serviceName && (
                  <span className="error"> {errors?.serviceName} </span>
                )}



                <Index.Box className="main-button-modal">
                  <Index.Box className="first-cancel-btn">
                    <Index.Button
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Cancel
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="second-delete-btn">
                    <Index.Button type="submit" disabled={btnLoading}>save</Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PageIndex.Formik>
      </Index.Modal>
    </>
  )
}

export default AddEditServices
