import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./admin-master.css";
import { useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/common/DeleteModal";
import AddEditAdminModel from "../../../../components/common/AddEditAdminModel";
import ViewAdminModel from "../../../../components/common/ViewAdminModel";
import Index from "../../../Index";

const AdminMaster = () => {
  const adminData = JSON.parse(localStorage.getItem("admin"));
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handelDelete = async () => {
    try {
      const response = await DataService.delete(
        `/admin/delete-admin/${adminId}`
      );
      if (response.status === 200) {
        getAllAdmins();
        toast.success(response.data.message);
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllAdmins = async () => {
    try {
      const response = await DataService.get("/admin/get-all-admins");
      if (response.status == 200) {
        setAdmins(response?.data?.data);
        setFilterData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelSubmitButton = (values) => {
    const addEditAdmin = async () => {
      try {
        let body = {
          adminId: data?._id ? data?._id : undefined,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roleType: values.roleType,
        };
        if (!data) {
          body.password = values.password;
        }
        const response = await DataService.post("/admin/admin-add-edit", body);
        if (response.data.status === 200 || response.data.status == 201) {
          values.firstName = "";
          values.lastName = "";
          values.email = "";
          values.password = "";
          values.city = "";
          getAllAdmins();
          toast.success(response.data.message);
          setOpenEdit(false);
          setData(null);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    addEditAdmin();
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = admins?.filter((item) => {
      const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();
      const matchesFullName = fullName.includes(search);
      const matchesEmail = item?.email && item?.email?.toLowerCase().includes(search);

      return matchesFullName || matchesEmail;
    });
    setFilterData(res);
  };

  useEffect(() => {
    getAllAdmins();
  }, []);
  return (
    <Box className="main-table">
      <Box className="top-table">
        <Box classsName="left-heading-table">
          <Typography component="h5" variant="h5">
            Admin Master
          </Typography>
        </Box>
        <Box className="right-top-table-custom">
          <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
          {adminData?.roleType?.permission?.AdminMaster?.Add && (
            <Box className="right-top-table">
              <Button
                variant="contained"
                className="btn btn-primary"
                onClick={() => {
                  setOpenEdit(true);
                }}
              // onClick={() => navigate("/admin/admin-add-edit")}
              >
                Add admin
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="main-content">
        <TableContainer className="table-main" sx={{ overflow: "scroll" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S. No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>email</TableCell>
                {/* <TableCell>status</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((admin, i) => (
                <TableRow key={admin._id}>
                  <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>
                    {`${admin.firstName} ${admin.lastName}`}
                  </TableCell>
                  <TableCell>{admin.email}</TableCell>
                  {/* <TableCell>
                                        {admin.isActive ? "active" : "inactive"}
                                    </TableCell> */}
                  <TableCell>
                    <Box className="mian-table-icon">
                      <Box className="first-icon-edit">
                        {adminData?.roleType?.permission?.AdminMaster?.View && (
                          <span>
                            <VisibilityIcon
                              sx={{
                                color: "",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                              // onClick={() => { setOpenView(true); setData(admin); }}
                              onClick={() => {
                                navigate("/admin/admin-view", {
                                  state: { admin },
                                });
                              }}
                            />
                          </span>
                        )}
                        {adminData?.roleType?.permission?.AdminMaster?.Edit && (
                          <span>
                            <BorderColorIcon
                              sx={{
                                color: "blue",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpenEdit(true);
                                setData(admin);
                              }}
                            // onClick={() => { navigate("/admin/admin-add-edit", { state: { admin } }) }}
                            />
                          </span>
                        )}
                        {adminData?.roleType?.permission?.AdminMaster
                          ?.Delete && (
                            <span>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  padding: "2px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpen(true);
                                  setAdminId(admin._id);
                                }}
                              />
                            </span>
                          )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={admins.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <AddEditAdminModel
          data={data}
          setData={setData}
          open={openEdit}
          setOpen={setOpenEdit}
          handelSubmitButton={handelSubmitButton}
        />
        <ViewAdminModel
          data={data}
          setData={setData}
          open={openView}
          setOpen={setOpenView}
        />
        <DeleteModal
          open={open}
          setOpen={setOpen}
          handelDelete={handelDelete}
        />
      </Box>
    </Box>
  );
};

export default AdminMaster;
