import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/common/DeleteModal";
import Index from "../../../Index";

const RoleManagement = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [open, setOpen] = useState(false);
  const [permissionId, setPermissionId] = useState(null);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handelDelete = async () => {
    try {
      const response = await DataService.delete(
        `/admin/delete-permission/${permissionId}`
      );
      if (response.status === 200) {
        getAllRoles();
        toast.success(response.data.message);
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await DataService.get("/admin/get-all-permission");
      if (response.status === 200) {
        setRoles(response.data.data);
        setFilterData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllRoles();
  }, []);

  const handleRoleAddEdit = (role) => {
    navigate("/admin/role-add-edit", { state: { role: role } });
  };

  const handleRoleView = (role) => {
    navigate("/admin/role-view", { state: { role: role } });
  };

  const handleRoleDelete = (permissionId) => {
    const deleteRole = async () => {
      if (!permissionId) {
        return;
      }
      try {
        const response = await DataService.delete(
          `/admin/delete-permission/${permissionId}`
        );
        if (response.status === 200) {
          toast.success(response.data.message);
          getAllRoles();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast(error.message);
      }
    };
    deleteRole();
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = roles?.filter((item) => {
      const matchesRoleType = item?.RoleType && item?.RoleType?.toLowerCase().includes(search);

      return matchesRoleType;
    });
    setFilterData(res);
  };

  return (
    <Box className="main-table">
      <Box className="top-table">
        <Box classsName="left-heading-table">
          <Typography component="h5" variant="h5">
            Role Management
          </Typography>
        </Box>
        <Box className="right-top-table-custom">
          <Index.TextField
            size="small"
            placeholder="Search"
            onChange={(e) => handleSearchChange(e)}
          />
          {admin?.roleType?.permission?.RoleManagement?.Add && (
            <Box className="right-top-table">
              <Button
                variant="contained"
                className="btn btn-primary"
                onClick={() => navigate("/admin/role-add-edit")}
              >
                Add Role
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="main-content">
        <TableContainer className="table-main" sx={{ overflow: "scroll" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>S. No.</TableCell>
                <TableCell>Role Type</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((role, i) => (
                <TableRow key={role._id}>
                  <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{role.RoleType}</TableCell>
                  {/* <TableCell>
                    {role.isActive ? "active" : "inactive"}
                  </TableCell> */}
                  <TableCell>
                    <Box className="mian-table-icon">
                      <Box className="first-icon-edit">
                        {admin?.roleType?.permission?.RoleManagement?.View && (
                          <span onClick={(e) => handleRoleView(role)}>
                            <VisibilityIcon
                              sx={{
                                color: "",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        )}

                        {admin?.roleType?.permission?.RoleManagement?.Edit && (
                          <span onClick={(e) => handleRoleAddEdit(role)}>
                            <BorderColorIcon
                              sx={{
                                color: "blue",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        )}

                        {admin?.roleType?.permission?.RoleManagement
                          ?.Delete && (
                            <span
                              onClick={() => {
                                setOpen(true);
                                setPermissionId(role._id);
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  padding: "2px",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={roles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <DeleteModal
          open={open}
          setOpen={setOpen}
          handelDelete={handelDelete}
        />
      </Box>
    </Box>
  );
};

export default RoleManagement;
