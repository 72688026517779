import { DataService } from "../config/DataService";
import { API } from "../config/API";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { imageURL } from "../config/DataService";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";


export default {
    toast,
    Formik,
    useNavigate,
    DataService,
    API,
    useEffect,
    Calendar,
    DatePanel,
    imageURL,
    format,
    useLocation,
    ToastContainer

};  