import React, { useEffect } from 'react'
import { Box, Button, Card, CardContent, FormControl, Grid, Input, InputLabel, TextField, Typography } from '@mui/material'
import "./admin-master.css"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataService } from '../../../../config/DataService';
import { toast } from 'react-toastify';
import Jpg from "./../../../../assets/images/jpg"
const AdminView = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log("state",state.admin);
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        try {
            const getAllRoles = async () => {
                const response = await DataService.get("/admin/get-all-permission");
                if (response.data.status === 200) {
                    setPermissions(response.data.data)
                }
            };
            getAllRoles();
        } catch (error) {
            toast.error(error.message);
        }
    }, [])

    return (
        <Box className="main-add-edit">
            <Box className="top-add-edit">
                <Typography component="h5" variant="h5" >
                    View admin
                </Typography>
            </Box>
            <Box className="my-profile" sx={{ marginTop: "30px", minWidth: 275, overflow: "auto" }}>
                <Card className="my-profile-card" variant="outlined">
                    <CardContent className="my-profile-content">
                        <Grid container>
                            <Grid item sm={3}>
                                <Box className="profile-section">
                                    <Box className="profile-image">
                                        <img src={Jpg.profile} alt='profile' />
                                    </Box>
                                    <Box className="profile-name">
                                        <Typography sx={{ textTransform: "capitalize" }} variant="h6">
                                            {state?.admin?.firstName} {state?.admin?.lastName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={9}>
                                <Box className="profile-content-section">
                                    <Box className="profile-content-title">
                                        <Typography>email </Typography>
                                        <Typography>Role Type </Typography>
                                    </Box>
                                    <Box className="profile-content">
                                        <Typography>{state?.admin?.email ? state?.admin?.email : "-"}</Typography>
                                        <Typography>{ permissions.length ? permissions?.filter(role => role._id == state?.admin?.roleType)[0]?.RoleType : "-"}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            {/* <Box className="admin-master">

                <Box className='admin-form' component="form" >
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            name="firstName"
                            disabled={true}
                            value={state?.admin?.firstName}
                        />
                    </Box>
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            name="lastName"
                            disabled={true}
                            value={state?.admin?.lastName}
                        />
                    </Box>
                    <Box className="admin-input">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            name="email"
                            disabled={true}
                            value={state?.admin?.email}
                        />
                    </Box>
                    <Box className="admin-input">
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel id="demo-select-small-label">Role Type</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                name='roleType'
                                size='small'
                                disabled={true}
                                value={state?.admin?.roleType}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    permissions?.map((permission) => (
                                        <MenuItem value={permission._id}>{permission.RoleType}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </Box>
                </Box>
            </Box> */}
        </Box>
    )
}

export default AdminView