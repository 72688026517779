
const API = {
    ADMIN: {
        ADMIN_LOGIN: "/admin/login",
        CATEGORY_LIST : "/common/get-all-category",
        USER_BOOKING_LIST : "/admin/get-professional-booking",
        PROFESSIONAL_BOOKING_LIST : "/admin/get-user-booking-list",
        CONTACT_US_LIST : "/common/get-all-contact-us",
        CMS_DATA_GET : "/common/get-cms-data",
        ADDEDIT_TERMS_AND_CONDITION:"/admin/addEdit-terms-conditions",
        ADDEDIT_PRIVACY_POLICY:"/admin/addEdit-privacy-policy",
        ADDEDIT_SITE_SETTING:"/admin/add-edit-site-setting",
        GET_SITE_SETTING:"/common/get-site-setting-data",
        
        GET_FAQ:"/common/get-FAQ-data",
        FAQ_REMOVE:"/admin/delete-FAQ",
        ADDEDIT_FAQ:"/admin/add-edit-FAQ",
        FAQ_STATUS_CHANGE:"/admin/active-deactivated-FAQ",
    },
    USER: {
        USER_LOGIN: "/user/user-login",
        USER_SIGNUP: "/user/user-signup",
        USER_FORGOT_PASSWORD: "/user/user-forgot-password",
        USER_RESET_PASSWORD: "/user/user-reset-password",
        USER_VERIFIED_ACCOUNT: "/user/verified",
        USER_CHANGE_PASSWORD: "/user/change-password",
        USER_EDIT_PROFILE: "/user/edit-profile",
        USER_GET_SINGLE: "/user/get-single",
        PROFESSIONAL_SIGNUP : "/user/user-professinal-signup",
        PROFESSIONAL_LIST:"/user/get-all-profesional",
        PROFESSIONAL_SINGLE:"/user/get-single-profesional",
        PROFESSIONAL_REMOVE:"/user/delete-packages",
        PROFESSIONAL_GET_PACKAGES:"/user/get-packages",
        PROFESSIONAL_PACKAGE_ACTIVE_DEACTIVE:"/user/active-deactivated-package",
        PROFESSIONAL_PACKAGE_ADDEDIT:"/user/add-edit-packages",
        PROFESSIONAL_GET_SERVCES:"/user/get-all-services",
        PROFESSIONAL_SERVICE_ACTIVE_DEACTIVE:"/user/active-deactivated-services",
        PROFESSIONAL_SERVICE_ADDEDIT:"/user/update-services",
        PROFESSIONAL_REMOVE_SERVICE:"/user/delete-services",
        PROFESSIONAL_GET_PORTFOLIO:"/user/get-portfolios",
        PROFESSIONAL_UPDATE_PORTFOLIO:"/user/update-portfolio",
        PROFESSIONAL_REMOVE_PORTFOLIO:"/user/delete-portfolio",

        USER_ADD_BOOKING:"/user/add-booking",
        USER_GET_BOOKING:"/user/get-all-booking",
        USER_GET_PROFESSIONAL_PACKAGES:"/user/get-packages-user",

        PROFESSIONAL_GET_BOOKING:"/user/get-user-booking-for-professional",
        PROFESSIONAL_STATUS_BOOKING:"/user/approve-reject-booking",
        USER_CONTACT_US:"/common/add-contact-us",
        CONFIGURATION_GET_ALL:"/user/get-all-configuration-data",

        PROFESSIONAL_GET_QUESTION:"user/get-all-question",
        PROFESSIONAL_ADD_QUESTION:"/user/add-question",
        PROFESSIONAL_UPDATE_QUESTION:"/user/update-question",
        PROFESSIONAL_REMOVE_QUESTION:"/user/delete-question",
        PROFESSIONAL_STATUS_CHANGE_QUESTION:"",
        USER_GET_ALL_QUESTION:"/user/get-question-for-user",
        USER_ADD_RATING_QUESTION:"/user/user-reting-question",
        USER_GET_ALL_QUESTION_RATING:"/common/get-rating-by-bookingId",
    },
    SERVICE:{
        AVAILABILITY_GET:"/service/get-availability",
        AVAILABILITY_ADD:"/service/add-availability"
    }
}

export { API }