import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import AddEditPackeges from './AddEditPackeges';
import "./Package.css"
import DeleteModal from '../../../../components/common/DeleteModal';
const Package = () => {
  const [packageData, setPackageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [packageModalOpen, setPackageModalOpen] = useState(false);
  const [packegEditId, setPackageEditId] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getPackegesDetails = async () => {
    setLoading(true);
    try {
      const res = await PageIndex?.DataService?.get(PageIndex?.API.USER.PROFESSIONAL_GET_PACKAGES);
      if (res?.data?.status == 200) {
        setPackageData(res?.data?.data?.packages);
        setFilterData(res?.data?.data?.packages);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };
  useEffect(() => {
    getPackegesDetails();
  }, [])

  const activeDeactivePackegs = async (id, status) => {
    try {
      const res = await PageIndex?.DataService.post(
        PageIndex?.API.USER.PROFESSIONAL_PACKAGE_ACTIVE_DEACTIVE,
        { packageId: id, isActive: status }
      );
      if (res?.data?.status == 200) {
        PageIndex?.toast?.success(res?.data?.message);
        getPackegesDetails();
      }
    } catch (error) {
      PageIndex?.toast?.error(error?.response?.data?.message);
      console.log("error", error);
    }
  };
  const handleDeletePackge = async () => {
    try {
      const res = await PageIndex.DataService.post(PageIndex.API.USER.PROFESSIONAL_REMOVE, {
        packageId: deleteId,
      });
      if (res.data.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getPackegesDetails();
      }
      setDeleteOpen(false);
    } catch (error) {
      setDeleteOpen(false);
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = packageData?.filter((item) => {
      const matchesPackage = item.packageName.toLowerCase().includes(search);
      const matchesDescription = item.description.toLowerCase().includes(search);

      return matchesPackage || matchesDescription;
    });
    setFilterData(res);
  };

  return (
    <>

      <div style={{ margin: "25px" }}>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">Package List</Index.Typography>

          <Index.Box className="heading-add-box">
            <Index.TextField
              className="search-filter-heading"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
            <Index.Button onClick={() => setPackageModalOpen(true)} className='add-button'>
              Add Package
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.TableContainer className="packages-table-container booking-list" sx={{ overflow: "scroll" }}>
          <Index.Table className="package-table" stickyHeader aria-label="sticky table">
            <Index.TableHead className="table-head">
              <Index.TableCell align="center" className="table-heading-cell">
                No.
              </Index.TableCell>
              <Index.TableCell className="table-heading-cell">
                Package{" "}
              </Index.TableCell>
              <Index.TableCell className="table-head-last-row">
                Description
              </Index.TableCell>
              <Index.TableCell className="table-head-last-row">
                Action
              </Index.TableCell>

            </Index.TableHead>
            {filterData &&
              filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items, index) => (
                <>
                  {!loading ? (
                    <Index.TableBody className="table-body">
                      <Index.TableCell align="center">{index + 1 + page * rowsPerPage}</Index.TableCell>
                      <Index.TableCell>{items?.packageName}</Index.TableCell>
                      <Index.TableCell>{items?.description}</Index.TableCell>
                      <Index.TableCell className="table-action-icon-box">
                        <Index.Switch
                          size="small"
                          checked={items?.isActive === true}
                          className="table-icon-switch"
                          onClick={() =>
                            activeDeactivePackegs(
                              items?._id,
                              items?.isActive == true ? false : true
                            )
                          }
                        />

                        <Index.EditIcon
                          size="small"
                          className="table-icon-edit"
                          onClick={() => {
                            setPackageModalOpen(true);
                            setPackageEditId(items);
                          }}
                        />

                        <Index.DeleteIcon
                          size="small"
                          className="table-icon-delete"
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteId(items?._id);
                          }}
                        />
                      </Index.TableCell>
                    </Index.TableBody>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={packageData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />


        <DeleteModal
          open={deleteOpen}
          setOpen={setDeleteOpen}
          handelDelete={handleDeletePackge}
        />

        <AddEditPackeges
          open={packageModalOpen}
          setOpen={setPackageModalOpen}
          data={packegEditId}
          setData={setPackageEditId}
          getPackegesDetails={getPackegesDetails}
        />

      </div>
    </>
  )
}

export default Package