import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function AutocompleteDatePicker(props) {
  const { options, ...other } = props;

  const optionsLookup = React.useMemo(
    () =>
      options.reduce((acc, option) => {
        acc[dayjs(option).format('YYYY-MM-DD')] = true;
        return acc;
      }, {}),
    [options],
  );

  return (
    <DatePicker
      shouldDisableDate={(date) => {
        const formattedDate = date.format('YYYY-MM-DD');
        return !optionsLookup[formattedDate] || dayjs(formattedDate).isBefore(dayjs(), 'day');
      }}
      {...other}
      className='datepicker-input-field'
      sx={{ minWidth: '325px' }}
    />
  );
}

export default function DatePickerField({ options, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AutocompleteDatePicker
        label="Select service date"
        options={options?.map(option => new Date(option))}
        value={value}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}
