import React, { useEffect, useState } from 'react';
import Index from '../../../Index';
import '../../../user/pages/profile/userEditProfile.css';
import userProfile from '../../../../assets/images/jpg/profile.jpg';
import EditWhite from '../../../../assets/images/svg/EditWhite';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { DataService, imageURL } from "../../../../config/DataService";
import { API } from "../../../../config/API";
import { EditUserProfile } from '../../../../validation/UserValidation';


function UserEditProfile() {
    const [userData, setUserData] = useState('');
    const [file, setFile] = useState('');
    const [tempProfileImageURL, setTempProfileImageURL] = useState(null);
    const [subconfigurationsCity, setSubconfigurationsCity] = useState([]);
    const [cityId, setCityId] = useState("");
    const dob = userData.DOB ? new Date(userData.DOB) : null;
    const formattedDOB = dob ? `${dob.getMonth() + 1}/${dob.getDate()}/${dob.getFullYear()}` : "";

    const initialValues = {
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        DOB: formattedDOB,
        city: userData.city || "",
        mobileNumber: userData.mobileNumber || "",
        gender: userData.gender || "",
        profile: userData.profile || "",
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: EditUserProfile,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            editProfileSubmit(values);
            action.resetForm();
        }
    });
    console.log({ errors });


    // const handleProfileImageChange = (e) => {
    //     const files = e?.target?.files[0];
    //     setFile(files);
    //     setTempProfileImageURL(URL.createObjectURL(files));
    // };

    const handleProfileImageChange = (e) => {
        const file = e?.target?.files[0];
        if (file) {
          const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
          if (allowedTypes.includes(file.type)) {
            setFile(file);
            setTempProfileImageURL(URL.createObjectURL(file));
          } else {
            console.error("File type not allowed. Please select a JPG, JPEG, or PNG file.");
          }
        }
      };

    const getSingleUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = { auth: token };

            const res = await DataService.get(API.USER.USER_GET_SINGLE, { headers });
            if (res?.data?.status === 200) {
                setUserData(res?.data?.data);
                localStorage.setItem("profile", res?.data?.data?.profile)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    const editProfileSubmit = async (value) => {
        try {
            const formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("DOB", values.DOB);
            formData.append("mobileNumber", values.mobileNumber);
            formData.append("city", values.city);
            formData.append("profile", file ? file : userData?.profile);
            formData.append("gender", userData.gender?._id);

            const token = localStorage.getItem("token");
            const headers = { auth: token };

            const res = await DataService.post(API.USER.USER_EDIT_PROFILE, formData, { headers });
            if (res?.data?.status === 200) {
                toast.success(res?.data?.message);
                setUserData(res?.data?.data)
                getSingleUser()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        getSingleUser();
    }, []);

    const getAllConfigurationData = async (value) => {
        try {
            const res = await DataService.get(API.USER.CONFIGURATION_GET_ALL, value);
            if (res?.data?.status === 200) {
                const configurations = res.data.data;
                const cityConfig = configurations.find(config => config.title === "City");
                setCityId(cityConfig._id);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    const getAllCity = async () => {
        try {
            const response = await DataService.get(
                `/common/get-all-subconfiguration/${cityId}`
            );
            if (response.status === 200) {
                setSubconfigurationsCity(response.data.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getAllConfigurationData();
    }, []);

    useEffect(() => {
        if (cityId) {
            getAllCity();
        }
    }, [cityId]);

    return (
        <>
            <Index.Box className="admin-main-content-list">
                <Index.Box className="admin-common-title-details">
                    <Index.Box className="admin-heade-bg-title">
                        <Index.Box className="admin-flex-common">
                            <Index.Typography className="admin-common-title">Edit Profile</Index.Typography>
                        </Index.Box>
                    </Index.Box>

                    <form onSubmit={handleSubmit} className="user-profile-form">
                        <Index.Box className="profile-max-user-details">
                            <Index.Box className="profile-edit-bg">
                                <Index.Box className="profile-view-listing">
                                    <Index.Box className="profile-view-flex">
                                        <Index.Box className="profile-image-view">
                                            {values.profile === "" ? (
                                                <img
                                                    src={tempProfileImageURL || userProfile}
                                                    className="profile-viewer"
                                                    alt="profile"
                                                />
                                            ) : (
                                                <img
                                                    src={tempProfileImageURL || `${imageURL}/${values.profile}`}
                                                    className="profile-viewer"
                                                    alt="profile"
                                                />
                                            )}


                                            <Index.Button
                                                component="label"
                                                variant="contained"
                                                tabIndex={-1}
                                                className="profile-btn-label"
                                            >
                                                <EditWhite />
                                                <input
                                                    id="profile"
                                                    type="file"
                                                    inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
                                                    style={{ display: "none" }}
                                                    name='profile'
                                                    onChange={handleProfileImageChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="profile-name-details">
                                            <Index.Typography component="h5" variant="h5" className="profile-user-name">
                                                User
                                            </Index.Typography>
                                            <Index.Typography component="p" variant="p" className="profile-user-email">
                                                mailto:user@gmail.com
                                            </Index.Typography>
                                        </Index.Box>
                                    </Index.Box>



                                </Index.Box>


                                <Index.Box className="profile-edit-padding">
                                    <Index.Grid container spacing={2} className="common-user-grid">
                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details" varia>
                                                    First Name
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        id="firstName"
                                                        fullWidth
                                                        variant="outlined"
                                                        className="common-textfield-content"
                                                        placeholder='Enter your first name'
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Index.Box>
                                                {errors.firstName && touched.firstName ? (<p className='from-error'>{errors.firstName}</p>)
                                                    : null}
                                            </Index.Box>
                                        </Index.Grid>
                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details" varia>
                                                    Last name
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        id="lastName"
                                                        fullWidth
                                                        variant="outlined"
                                                        className="common-textfield-content"
                                                        placeholder='Enter your last name'
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Index.Box>
                                                {errors.lastName && touched.lastName ? (<p className='from-error'>{errors.lastName}</p>) : null}
                                            </Index.Box>
                                        </Index.Grid>

                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details" varia>
                                                    City
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        id="city"
                                                        fullWidth
                                                        className="textfield-drop-down-city"
                                                        placeholder='Enter your city'
                                                        name='city'
                                                        value={values.city}
                                                        defaultValue={userData?.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        select
                                                    >


                                                        {subconfigurationsCity?.map((item) => (

                                                            <MenuItem key={item._id} value={item._id}>
                                                                {item.title}
                                                            </MenuItem>
                                                        ))}</Index.TextField>
                                                </Index.Box>
                                                {errors.city && touched.city ? (<p className='from-error'>{errors.city}</p>) : null}
                                            </Index.Box>
                                        </Index.Grid>

                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details" varia>
                                                    Date of Birth
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        id="DOB"
                                                        fullWidth
                                                        variant="outlined"
                                                        className="common-textfield-content"
                                                        placeholder="Enter your DOB"
                                                        value={values.DOB}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Index.Box>
                                                {errors.DOB && touched.DOB ? (<p className='from-error'>{errors.DOB}</p>) : null}
                                            </Index.Box>
                                        </Index.Grid>

                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details" varia>
                                                    Mobile Number
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        id="mobileNumber"
                                                        fullWidth
                                                        type='number'
                                                        variant="outlined"
                                                        className="common-textfield-content"
                                                        placeholder="Enter your mobile number"
                                                        value={values.mobileNumber}
                                                        onChange={(e) => {
                                                            if (e.target.value.length <= 10) {
                                                              handleChange(e);
                                                            }
                                                          }}
                                                        onBlur={handleBlur}
                                                    />
                                                </Index.Box>
                                                {errors.mobileNumber && touched.mobileNumber ? (<p className='from-error'>{errors.mobileNumber}</p>) : null}
                                            </Index.Box>
                                        </Index.Grid>


                                        <Index.Grid item xs={4} className="common-user-grid-item">
                                            <Index.Box className="common-input-field">
                                                <Index.FormHelperText className="common-label-details">
                                                    Gender
                                                </Index.FormHelperText>
                                                <Index.Box className="input-common-field">
                                                    <Index.TextField
                                                        sx={{ width: "100%" }}
                                                        id="gender"
                                                        name='gender'
                                                        size='small'
                                                        placeholder="Please select your gender"
                                                        value={values.gender?.title}
                                                        className="common-textfield-content"
                                                    />

                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Grid>


                                        <Index.Grid item xs={12} className="common-user-grid-item">
                                            <Index.Box className="common-btn-details ">
                                                <Index.Box className="flex-gap-footer end-justify-content">
                                                    <Button className='button-secondary' >Cancel</Button>
                                                    <Button type="submit" className='button-primary' >Upload Profile</Button>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Grid>

                                    </Index.Grid>
                                </Index.Box>

                            </Index.Box>
                        </Index.Box>
                    </form>
                </Index.Box>
            </Index.Box >
        </>
    )
}

export default UserEditProfile
