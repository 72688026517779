import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
// import "./admin-master.css"
import { Formik } from "formik";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import { useState } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { toast } from "react-toastify";
import Lodader from "../../../../components/common/Lodader";
import LoaderButton from "../../../../components/common/LoaderButton";
import { API } from "../../../../config/API";

const UserAddEdit = () => {
  const [loading, setLoading] = useState(false);
  const [subconfigurationsCity, setSubconfigurationsCity] = useState([]);
  const [cityId, setCityId] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  let initialValues = {
    firstName: state?.user?.firstName
      ? state?.user?.firstName
      : state?.user?.firstName
        ? state?.user?.firstName
        : "",
    lastName: state?.user?.lastName ? state?.user?.lastName : "",
    email: state?.user?.email ? state?.user?.email : "",
    city: state?.user?.city ? state?.user?.city?._id : "",
  };

  let userSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    city: Yup.string().required("City is required"),
  });
  // if (!state) {
  //     initialValues.password = "";
  //     userSchema.password = Yup.string().required("Password is required");
  // }

  const handelSubmitButton = (values) => {
    const addEditUser = async () => {
      try {
        let body = {
          userId: state?.user?._id ? state?.user?._id : undefined,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          city: values.city,
          userType: "user",
        };
        // if (!state) {
        //     body.password = values.password
        // }
        setLoading(true);
        const response = await DataService.post(
          "/admin/add-edit-user-by-admin",
          body
        );
        if (response.data.status === 200 || response.data.status == 201) {
          setLoading(false);
          navigate("/admin/user-list");
          // getAllUsers();
          toast.success(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    };
    addEditUser();
  };


  const getAllConfigurationData = async (value) => {
    try {
      const res = await DataService.get(API.USER.CONFIGURATION_GET_ALL, value);
      if (res?.data?.status === 200) {
        const configurations = res.data.data;
        const cityConfig = configurations.find(config => config.title === "City");
        setCityId(cityConfig._id);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const getAllCity = async () => {
    try {
      const response = await DataService.get(
        `/common/get-all-subconfiguration/${cityId}`
      );
      if (response.status === 200) {
        setSubconfigurationsCity(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllConfigurationData();
  }, []);

  useEffect(() => {
    if (cityId) {
      getAllCity();
    }
  }, [cityId]);

  return (
    <Box className="main-add-edit">
      <Box className="top-add-edit">
        <Typography component="h5" variant="h5">
          {state ? "Edit" : "Add"} User
        </Typography>
      </Box>
      <Box className="admin-master">
        <Formik
          initialValues={initialValues}
          validationSchema={userSchema}
          onSubmit={handelSubmitButton}
        >
          {({ values, handleSubmit, handleChange, errors }) => (
            <Box
              className="admin-form"
              component="form"
              onSubmit={handleSubmit}
            >
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && (
                  <span className="error"> {errors.firstName} </span>
                )}
              </Box>
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && (
                  <span className="error"> {errors.lastName} </span>
                )}
              </Box>
              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <span className="error"> {errors.email} </span>
                )}
              </Box>

              <Box className="admin-input">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="City"
                  select
                  variant="outlined"
                  name="city"
                  value={values?.city?._id}
                  defaultValue={state?.user?.city?._id}
                  onChange={handleChange}
                >
                  {subconfigurationsCity.map((item) => (

                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))}

                </TextField>
                {errors?.city && <span className="error"> {errors?.city} </span>}
              </Box>

              <Box sx={{ textAlign: "right", marginTop: "10px" }}>
                <LoaderButton className="loader-button" loading={loading} type="submit" />
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default UserAddEdit;
