import React, { useState } from "react";
import "./Layout.css";
import { Box, Typography } from "@mui/material";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import Index from "../../../container/Index";
const Sidebar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClickAccount = () => {
    setOpen(!open);
  };
  const admin = JSON.parse(localStorage.getItem("admin"));
  return (
    <>
      <Box className="Sidebar">
        <Box className="Sidebar-box">
          <Box className="contant-Sidebar-box">
            <Box>
              <Typography className="contant-Sidebar-text">
                Hire Professional
              </Typography>
            </Box>
          </Box>
          <Box className="admin-menu-list">
            <Box component="ul">
              <Box
                className={
                  location.pathname == "/admin/dashboard"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                  navigate("/admin/dashboard");
                }}
              >
                Dashboard
              </Box>
              <Box
                className={
                  location.pathname == "/admin/category"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => navigate("category")}
              >
                Category Master
              </Box>
              {admin?.roleType?.permission?.AdminMaster?.View && (
                <Box
                  className={
                    location.pathname == "/admin/home"
                      ? "menu-active"
                      : "menu-item"
                  }
                  component="li"
                  onClick={() => navigate("/admin/home")}
                >
                  Admin Master
                </Box>
              )}

              {admin?.roleType?.permission?.RoleManagement?.View && (
                <Box
                  className={
                    location.pathname == "/admin/role-management"
                      ? "menu-active"
                      : "menu-item"
                  }
                  component="li"
                  onClick={() => navigate("/admin/role-management")}
                >
                  Role Management
                </Box>
              )}

              {admin?.roleType?.permission?.UserManagement?.View && (
                <Box
                  className={
                    location.pathname == "/admin/user-list"
                      ? "menu-active"
                      : "menu-item"
                  }
                  component="li"
                  onClick={() => navigate("/admin/user-list")}
                >
                  User
                </Box>
              )}

              {admin?.roleType?.permission?.ProfessionalManagement?.View && (
                <Box
                  className={
                    location.pathname == "/admin/professionals-list"
                      ? "menu-active"
                      : "menu-item"
                  }
                  component="li"
                  onClick={() => navigate("/admin/professionals-list")}
                >
                  Professional
                </Box>
              )}

              {admin?.roleType?.permission?.ConfigurationManagement?.View && (
                <Box
                  className={
                    location.pathname == "/admin/configuration"
                      ? "menu-active"
                      : "menu-item"
                  }
                  component="li"
                  onClick={() => navigate("/admin/configuration")}
                >
                  Configurations
                </Box>
              )}

              <Box
                className={
                  location.pathname == "/admin/contact-us"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => navigate("/admin/contact-us")}
              >
                Contact Us
              </Box>

       

              <Box
                className="menu-item cms-menu-item"
                component="li"
                onClick={handleClickAccount}
              >
                <Index.Typography className="cms-text">CMS</Index.Typography>

                {open ? (
                  <Index.ExpandLess className="expandless-icon" />
                ) : (
                  <Index.ExpandMore className="expandmore-icon" />
                )}
              </Box>

              <Index.Box className="submenu-main">
                <Index.Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className="submenu-collapse"
                >
                  <Index.List
                    component="div"
                    disablePadding
                  >
                        
                    <Index.ListItem
                     className={
                      location.pathname == "/admin/terms-condition"
                        ? "menu-active"
                        : ""
                    }
                     onClick={() => navigate("/admin/terms-condition")}>
           
                        Terms&Condition
                     
                    </Index.ListItem>
                    <Index.ListItem 
                     className={
                      location.pathname == "/admin/privacy-policy"
                        ? "menu-active"
                        : ""
                    }
                   onClick={() => navigate("/admin/privacy-policy")}>
                 
                        Privacy Policy
                 
                    </Index.ListItem>
                  </Index.List>
                </Index.Collapse>
              </Index.Box>

              <Box
                className={
                  location.pathname == "/admin/site-setting"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => navigate("/admin/site-setting")}
              >
                Site Setting
              </Box>

              <Box
                className={
                  location.pathname == "/admin/faq"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => navigate("/admin/faq")}
              >
                FAQ
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
