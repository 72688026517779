import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import "./common.css"
const ViewSubConfrigurationModel = ({ data, setData, modaltitle, open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
        setData(null);
    }
    return (
        <Modal
            className="cus-main-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="cus-modal">
                <Typography
                    className="modal-heading-text"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    View {modaltitle}
                </Typography>
                <Box>
                    <Box className='admin-form' >
                        <Box className="admin-input">
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="title"
                                variant="outlined"
                                name="title"
                                value={data?.title}
                                disabled = {true}
                            />
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Modal>
    )
}

export default ViewSubConfrigurationModel