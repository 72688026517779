import React, { useState } from "react";
import { QuestionAddEdit } from "../../../../validation/UserValidation";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddEditQuestion = ({
  open,
  setOpen,
  data,
  setData,
  getQuestionDetails,
}) => {
  const handleClose = () => {
    setOpen(false);
    setData();
  };
  const [btnLoading, setBtnLoading] = useState(false);

  const submitForm = async (value) => {
    try {
      let res;
      setBtnLoading(true);
      if (data?._id) {
        res = await PageIndex.DataService.post(
          PageIndex.API.USER.PROFESSIONAL_UPDATE_QUESTION,
          {
            questionId: data?._id,
            question: value.question,
          }
        );
      } else {
        res = await PageIndex.DataService.post(
          PageIndex.API.USER.PROFESSIONAL_ADD_QUESTION,
          {
            question: value.question,
          }
        );
      }

      if (res?.data?.status == 201 || 200) {
        PageIndex.toast.success(res?.data?.message);
        handleClose();
        getQuestionDetails();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      handleClose();
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PageIndex.Formik
          validationSchema={QuestionAddEdit}
          initialValues={{
            question: data ? data.question : "",
          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Index.Box className="packages-modal-main">
                <Index.Box className="prof-heading-box">
                  <Index.Typography className="prof-heading-text">
                    {data?._id ? "Edit Question" : "Add Question"}
                  </Index.Typography>
                </Index.Box>

                {/* <Index.TextField
                  placeholder="Package Name"
                  size="small"
                  className="package-name-input"
                  onChange={handleChange}
                  value={values?.packageName}
                  name="packageName"
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 64 }}

                />
                   {errors?.packageName && (
                    <span className="error"> {errors?.packageName} </span>
                  )} */}

                <Index.TextField
                  placeholder="Enter Question"
                  multiline
                  rows={3}
                  onChange={handleChange}
                  className="package-name-description"
                  value={values?.question}
                  name="question"
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 200 }}
                />
                {errors?.question && (
                  <span className="error"> {errors?.question} </span>
                )}

                <Index.Box className="main-button-modal">
                  <Index.Box className="first-cancel-btn">
                    <Index.Button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="second-delete-btn">
                    <Index.Button type="submit" disabled={btnLoading}>
                      save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PageIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddEditQuestion;
