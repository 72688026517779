import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import AddEditQuestion from "./AddEditQuestion";
import "./Question.css";
import DeleteModal from "../../../../components/common/DeleteModal";
const Question = () => {
  const [questionData, getQuestionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [packageModalOpen, setPackageModalOpen] = useState(false);
  const [questionEditId, setQuestionEditId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getQuestionDetails = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.PROFESSIONAL_GET_QUESTION
      );
      if (res?.data?.status == 200) {
        getQuestionData(res?.data?.data);
        setFilterData(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };
  PageIndex.useEffect(() => {
    getQuestionDetails();
  }, []);


  const handleDeletePackge = async () => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.PROFESSIONAL_REMOVE_QUESTION,
        {
          questionId: deleteId,
        }
      );
      if (res.data.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getQuestionDetails();
      }
      setDeleteOpen(false);
    } catch (error) {
      setDeleteOpen(false);
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = questionData?.filter((item) => {
      const matchesQuestion = item.question.toLowerCase().includes(search);
      return matchesQuestion;
    });
    setFilterData(res);
  };

  return (
    <>
      <div style={{ margin: "25px" }}>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Question List
          </Index.Typography>

          <Index.Box className="heading-add-box">
            {/* <Index.TextField size="small" placeholder="Search" /> */}
            <Index.TextField
              className="search-filter-heading"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
            <Index.Button
              onClick={() => setPackageModalOpen(true)}
              className="add-button"
            >
              Add Question
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.TableContainer className="packages-table-container booking-list" sx={{ overflow: "scroll" }}>
          <Index.Table className="package-table" stickyHeader aria-label="sticky table">
            <Index.TableHead className="table-head">
              <Index.TableCell align="center" className="table-cell" >
                No.
              </Index.TableCell>
              <Index.TableCell className="table-cell" >
                Question{" "}
              </Index.TableCell>

              <Index.TableCell className="table-cell" >
                Action
              </Index.TableCell>
            </Index.TableHead>
            {filterData &&
              filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items, index) => (
                <>
                  {!loading ? (
                    <Index.TableBody className="table-body">
                      <Index.TableCell align="center">
                        {index + 1 + page * rowsPerPage}
                      </Index.TableCell>
                      <Index.TableCell>{items?.question}</Index.TableCell>
                      <Index.TableCell className="table-action-icon-box">
                        {/* <Index.Switch
                        size="small"
                        checked={items?.isActive === true}
                        className="table-icon-switch"
                        onClick={() =>
                          activeDeactivePackegs(
                            items?._id,
                            items?.isActive == true ? false : true
                          )
                        }
                      /> */}

                        <Index.EditIcon
                          size="small"
                          className="table-icon-edit"
                          onClick={() => {
                            setPackageModalOpen(true);
                            setQuestionEditId(items);
                          }}
                        />

                        <Index.DeleteIcon
                          size="small"
                          className="table-icon-delete"
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteId(items?._id);
                          }}
                        />
                      </Index.TableCell>
                    </Index.TableBody>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={questionData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <DeleteModal
          open={deleteOpen}
          setOpen={setDeleteOpen}
          handelDelete={handleDeletePackge}
        />

        <AddEditQuestion
          open={packageModalOpen}
          setOpen={setPackageModalOpen}
          data={questionEditId}
          setData={setQuestionEditId}
          getQuestionDetails={getQuestionDetails}
        />
      </div>
    </>
  );
};

export default Question;
