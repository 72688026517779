import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Paper, styled } from "@mui/material";
import Jpg from "../../assets/images/jpg";
import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Index from "../../container/Index";
import PageIndex from "../../container/PageIndex";
import RatingModal from "../../container/user/pages/booking/RatingModal";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ViewCard({ user }) {
  const [booking, setBooking] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBookingData = async () => {
    try {
      const res = await PageIndex.DataService.get(
        `${PageIndex.API.ADMIN.USER_BOOKING_LIST}?userId=${user?._id}`
      );
      if (res?.data?.status == 200) {
        setBooking(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  PageIndex.useEffect(() => {
    getBookingData();
  }, []);

  // let booking=[];

  return (
    <>
      <Box
        className="my-profile"
        sx={{ marginTop: "30px", minWidth: 275, overflow: "auto" }}
      >
        <Card className="my-profile-card" variant="outlined">
          <CardContent className="my-profile-content">
            <Box className="custom-container">
              <Grid container>
                <Grid item sm={3}>
                  <Box className="profile-section">
                    <Box className="profile-image">
                      {user?.profile ? (
                        <img src={`${PageIndex.imageURL}/${user?.profile}`} />
                      ) : (
                        <img src={Jpg.profile} alt="profile" />
                      )}
                    </Box>
                    {/* <Box className="profile-name">
                                        <Typography sx={{ textTransform: "capitalize" }} variant="h6">
                                            {user?.firstName} {user?.lastName}
                                        </Typography>
                                    </Box> */}
                  </Box>
                </Grid>
                <Grid item sm={9}>
                  <Box className="profile-content-section">
                    <Box className="profile-content-title">
                      <Box className="view-profile-data">
                        <Box className="custom-data-profile">
                          <Typography className="profile-data-list">
                            Name :{" "}
                          </Typography>
                        </Box>
                        <Typography className="profile-content-data">
                          {user?.firstName} {user?.lastName}
                        </Typography>
                      </Box>
                      <Box className="view-profile-data">
                        <Box className="custom-data-profile">
                          <Typography className="profile-data-list">
                            email :{" "}
                          </Typography>
                        </Box>
                        <Typography className="profile-content-data">
                          {user?.email ? user?.email : "-"}
                        </Typography>
                      </Box>

                      <Box className="view-profile-data">
                        <Box className="custom-data-profile">
                          <Typography className="profile-data-list">
                            gender :{" "}
                          </Typography>
                        </Box>
                        <Typography className="profile-content-data">
                          {user?.gender ? user?.gender?.title : "-"}
                        </Typography>
                      </Box>

                      {/* <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    language :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.language ? user?.language?.title  : "-"}
                                            </Typography>
                                        </Box> */}

                      {/* <Box className="view-profile-data">
                                            <Box className="custom-data-profile">
                                                <Typography className="profile-data-list">
                                                    nationality :{" "}
                                                </Typography>
                                            </Box>
                                            <Typography className="profile-content-data">
                                                {user?.nationality ? user?.nationality : "-"}
                                            </Typography>
                                        </Box> */}

                      <Box className="view-profile-data">
                        <Box className="custom-data-profile">
                          <Typography className="profile-data-list">
                            city :{" "}
                          </Typography>
                        </Box>
                        <Typography className="profile-content-data">
                          {user?.city?.title ? user?.city?.title : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* <Box className="view-profile-data top-border-line">
                        <Box className="custom-data-profile">

                            <Typography className="profile-data-lists">
                                services :{" "}
                            </Typography>
                        </Box>
                        <Typography className='profile-content-data'>
                            {user?.services.serviceName.length ? (
                                user?.services?.serviceName?.map((service, index) => (
                                    <span key={service._id}>
                                        {service}
                                        {index !== user?.serviceName?.length - 1 && ", "}
                                    </span>
                                ))
                            ) : (
                                "-"
                            )}
                        </Typography>
                    </Box> */}

            {/* <Box className="view-profile-data top-border-line">
                        <Box className="professional-view-profile">
                            <Box className="custom-data-profile">

                                <Typography className="profile-data-lists">
                                    packages :{" "}
                                </Typography>
                            </Box>
                            <TableContainer className="custom-table-container">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Package Name</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {user?.packages?.map((service, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Typography>{service?.packageName}</Typography> </TableCell>
                                                <TableCell><Typography>{service?.description}</Typography> </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box> */}

            <div style={{ padding: "20px", border: "0.5px solid gray" }}>
              <Index.Box className="heading-box">
                <Index.Typography className="heading-text">
                  Booking Detail :-{" "}
                </Index.Typography>

                <Index.Box className="heading-add-box">
                  {/* <Index.TextField size="small" placeholder="Search" /> */}
                  {/* <Index.Button onClick={() => {}} className='add-button'>
        Add Package
       </Index.Button> */}
                </Index.Box>
              </Index.Box>
              <Index.TableContainer
                className="packages-table-container booking-list-view"
                sx={{ overflow: "scroll" }}
              >
                <Index.Table
                  className="package-table"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableCell className="tableCell">
                      S.No
                    </Index.TableCell>
                    <Index.TableCell className="tableCell">
                      Image{" "}
                    </Index.TableCell>
                    <Index.TableCell className="tableCell">
                      Professional Name
                    </Index.TableCell>
                    {/* <Index.TableCell className="tableCell">Service</Index.TableCell> */}
                    <Index.TableCell className="tableCell">
                      Date
                    </Index.TableCell>
                    <Index.TableCell className="tableCell">
                      Package
                    </Index.TableCell>
                    <Index.TableCell className="tableCell">
                      Status
                    </Index.TableCell>
                    <Index.TableCell className="tableCell">
                      Rating
                    </Index.TableCell>
                  </Index.TableHead>

                  {booking &&
                    booking
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((data, index) => (
                        <Index.TableBody className="table-body">
                          {/* <Index.TableCell>{index+1}</Index.TableCell> */}
                          <Index.TableCell>
                            {index + 1 + page * rowsPerPage}
                          </Index.TableCell>
                          <Index.TableCell>
                            <img
                              src={`${PageIndex.imageURL}/${data?.professionalId?.profile}`}
                              style={{
                                height: "38px",
                                width: "50px",
                                borderRadius: "5px",
                              }}
                            />
                          </Index.TableCell>
                          <Index.TableCell>{`${data?.professionalId?.firstName} ${data?.professionalId?.lastName}`}</Index.TableCell>
                          {/* <Index.TableCell>{data?.professionalId?.services?.serviceName?.map((servic,index)=>(
                <p>{servic}</p>
              ))}</Index.TableCell> */}
                          <Index.TableCell>{data?.date}</Index.TableCell>
                          <Index.TableCell>
                            {data?.packagesId[0]?.packageName}
                          </Index.TableCell>
                          <Index.TableCell>{data?.status} </Index.TableCell>
                          <Index.TableCell>
                            {" "}
                            {data?.averageRating ?
                            <Index.Typography
                              className="rating-text"
                              onClick={() => {
                                setOpen(true);
                                setData(data);
                              }}
                            >
                              {data?.averageRating}
                            </Index.Typography>:"-"}
                          </Index.TableCell>
                          {/* <Index.TableCell>  
                 {data?.status == "Completed" &&
                <Index.Button onClick={()=>{setOpen(true); setData(data)}} className="rating-btn">
                  rating</Index.Button>}
                  </Index.TableCell> */}
                        </Index.TableBody>
                      ))}
                </Index.Table>
              </Index.TableContainer>
              <Index.TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={booking.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
      <RatingModal
        open={open}
        setOpen={setOpen}
        data={data}
        setData={setData}
        type={"read"}
      />
    </>
  );
}
