import React, { useState } from 'react'
import "./defaultLayout.css"
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, Grid } from '@mui/material';
import Location from '../../../assets/images/svg/location.svg';
import email from '../../../assets/images/svg/email.svg';
import call from '../../../assets/images/svg/call.svg';
import facebook from '../../../assets/images/svg/facebook.svg';
import instagram from '../../../assets/images/svg/instagram.svg';
import twitter from '../../../assets/images/svg/twitter.svg';
import youtube from '../../../assets/images/svg/youtube.svg';
import PageIndex from '../../../container/PageIndex';

export default function UserFooter() {
  const [webData, setWebData] = useState({});
  const getSiteData = async () => {
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.ADMIN.GET_SITE_SETTING
      );
      if (res?.data?.status === 200) {
        setWebData(res?.data?.data[0]);
      }
    } catch (error) {
        console.log(error,"error");
    }
  };

  PageIndex.useEffect(() => {
    getSiteData();
}, []);
    return (
        <>
            <Box className="footer-bg-content">
                <Box className="container">
                    <Grid container spacing={3} className="common-admin-grid">
                        <Grid item lg={4} xs={12} className="common-admin-grid-item">
                            <Box className="account-address-list">
                                <Typography component="p" variant="p" className="account-address-title">
                                    My Account
                                </Typography>
                                <List className="footer-list-details">
                                    <ListItem className="footer-listitem-details">
                                        <img src={Location} className="footer-add-icon"></img>
                                        <span> {webData?.address}</span>
                                    </ListItem>
                                    <ListItem className="footer-listitem-details">
                                        <img src={email} className="footer-add-icon"></img>
                                        <Link className="footer-link-text">
                                            <span> {webData?.email}</span>
                                        </Link>
                                    </ListItem>
                                    <ListItem className="footer-listitem-details">
                                        <img src={call} className="footer-add-icon"></img>
                                        <Link className="footer-link-text">
                                            <span>{webData?.mobileNumber}</span>
                                        </Link>
                                    </ListItem>
                                </List>
                                <List className="footer-list-details social-footer-list">
                                    <ListItem className="footer-listitem-details">
                                        <Link className="footer-link-text" to={webData?.facebook} target="_blank">
                                            <img
                                                src={facebook}
                                                className="footer-social-icons"
                                                alt="icons"
                                            ></img>
                                        </Link>
                                    </ListItem>
                                    <ListItem className="footer-listitem-details">
                                        <Link className="footer-link-text"  to={webData?.instagram} target="_blank">
                                            <img
                                                src={instagram}
                                                className="footer-social-icons"
                                                alt="icons"
                                            ></img>
                                        </Link>
                                    </ListItem>
                                    <ListItem className="footer-listitem-details">
                                        <Link className="footer-link-text"  to={webData?.twitter} target="_blank">
                                            <img
                                                src={twitter}
                                                className="footer-social-icons"
                                                alt="icons"
                                            ></img>
                                        </Link>
                                    </ListItem>
                                    <ListItem className="footer-listitem-details">
                                        <Link className="footer-link-text"  to={webData?.youTube} target="_blank">
                                            <img
                                                src={youtube}
                                                className="footer-social-icons"
                                                alt="icons"
                                            ></img>
                                        </Link>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item lg={3} xs={12} className="common-admin-grid-item">
                            <Box className="account-address-list">
                                <Typography component="p" variant="p" className="account-address-title">
                                    Information
                                </Typography>
                            </Box>
                            <List className="footer-list-details">
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text">Home</Link>
                                </ListItem>
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text">About</Link>
                                </ListItem>
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text">Expert Consulting</Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item lg={2} xs={12} className="common-admin-grid-item">
                            <Box className="account-address-list">
                                <Typography component="p" variant="p" className="account-address-title">
                                    General
                                </Typography>
                            </Box>
                            <List className="footer-list-details">
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text" to="/terms-condition">Terms & Conditions</Link>
                                </ListItem>
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text" to="/privacy-policy">Privacy Policy</Link>
                                </ListItem>
                                <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text" to="/faq">FAQ</Link>
                                </ListItem>
                                {/* <ListItem className="footer-listitem-details">
                                    <Link className="footer-link-text">Site Setting</Link>
                                </ListItem> */}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="copy-right-content">
                    <Box className="container">
                        <Typography className="copy-title">
                            Copyright ©All Right Reserved.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
