import React from 'react'
import { Box, Typography, TextField, Checkbox, TableRow, TableCell, TableBody, TableContainer, Table, TableHead, Button } from '@mui/material'
import "./addEdit.css"
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function RoleView() {
    const navigate = useNavigate();
    const location = useLocation();
    const [role, setRole] = useState({});
    useEffect(() => {
        setRole(location?.state?.role);
    }, [role]);
    if (role?.permission) {
        // console.log('role', role.permission);
    }

    return (
        <Box className="main-add-edit">
            <Box className="top-add-edit">
                <Typography component="h5" variant="h5" >
                    Role View
                </Typography>
                <Box className="main-input">
                    <TextField
                        className="custom-input"
                        id="outlined-basic"
                        variant="outlined"
                        label="Role Type"
                        name="RoleType"
                        disabled={true}
                        value={role.RoleType}
                    />
                </Box>
            </Box>
            <Box className="main-content">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Modules</TableCell>
                                <TableCell >All</TableCell>
                                <TableCell>Add</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell >View</TableCell>
                                <TableCell >Delete</TableCell>
                                <TableCell >Active/Deactive</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {role?.permission &&
                                Object.keys(role?.permission).map((permission, i) => (
                                    <TableRow>
                                        <TableCell>
                                            {role?.permission[permission].title}{" "}
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].All
                                                        ? role?.permission[permission].All
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].Add
                                                        ? role?.permission[permission].Add
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].Edit
                                                        ? role?.permission[permission].Edit
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].View
                                                        ? role?.permission[permission].View
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].Delete
                                                        ? role?.permission[permission].Delete
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    role?.permission[permission].Active
                                                        ? role?.permission[permission].Active
                                                        : false
                                                }
                                                disabled={true}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}