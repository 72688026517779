import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import "./common.css"
import { Formik } from "formik";
import * as Yup from "yup"
const AddEditModel = ({ data, setData,open, setOpen, handelSubmitButton }) => {
    const handleClose = () => {
        setOpen(false);
        setData(null);
    }
    const initialValues = {
        title: data ? data.title : "",
    }

    const configurationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
    })

    return (
        <Modal
            className="cus-main-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="cus-modal">
                <Typography
                    className="modal-heading-text"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    {data ? "Edit" : "Add"} Configuration
                </Typography>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={configurationSchema}
                        onSubmit={handelSubmitButton}
                    >
                        {({ values, handleSubmit, handleChange, errors }) => (
                            <Box className='admin-form' component="form" onSubmit={handleSubmit} >
                                <Box className="admin-input">
                                    <TextField
                                        size="small"
                                        id="outlined-basic"
                                        label="title"
                                        variant="outlined"
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                    />
                                    {errors.title && (
                                        <span className='error'> {errors.title} </span>
                                    )}
                                </Box>
                                <Box className="main-button-modal">
                                    <Box className="first-cancel-btn">
                                        <Button onClick={() => {setOpen(false); setData(null)}}>Cancel</Button>
                                    </Box>
                                    <Box className="second-delete-btn">
                                        <Button type="submit" >Save</Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Formik>
                </Box>

            </Box>
        </Modal>
    )
}

export default AddEditModel