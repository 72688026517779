import React from 'react';

function LockAnimation() {
  return (
    <div class="lock-container">
      <div class="lock">
        <div class="keyhole"></div>
      </div>
    </div>
    // <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512"
    //  height="512" x="0" y="0" viewBox="0 0 512 512" ><g><path d="M510.413 129.292c-3.705-7.409-12.713-10.413-20.124-6.708l-60 30c-7.41 3.705-10.413 12.715-6.708 20.125 3.704 7.409 12.713 10.413 20.124 6.708l60-30c7.41-3.705 10.413-12.715 6.708-20.125zM503.705 302.583l-60-30c-7.406-3.703-16.418-.702-20.124 6.708-3.705 7.41-.702 16.42 6.708 20.125l60 30c7.41 3.705 16.42.701 20.124-6.708 3.705-7.41.702-16.42-6.708-20.125zM345.998 241H119.999v-76c0-41.355 34.094-75 76-75 41.355 0 75 33.645 75 75v30c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-30C360.999 74.02 286.981.001 196 .001c-91.533.001-166 74.019-166 165v78.58C12.541 249.772 0 266.445 0 286v180.999c0 24.813 20.187 45 45 45h300.998c24.813 0 45-20.187 45-45V286c0-24.813-20.187-45-45-45zM60 165.001c0-74.439 61.01-134.999 135.999-134.999 74.439 0 134.999 60.561 134.999 134.999v15h-30v-15c0-57.897-47.103-104.999-104.999-104.999C137.931 60.002 90 107.122 90 165.001v76H60v-76zm300.998 301.998c0 8.271-6.729 15-15 15H45c-8.271 0-15-6.729-15-15V286c0-8.271 6.729-15 15-15h300.998c8.271 0 15 6.729 15 15v180.999z" fill="#ffffff" opacity="1" data-original="#000000"/><path d="M195.999 301c-24.813 0-45 20.187-45 45 0 19.555 12.541 36.228 30 42.42V437c0 8.284 6.716 15 15 15s15-6.716 15-15v-48.58c17.459-6.192 30-22.865 30-42.42 0-24.813-20.187-45-45-45zm0 59.999c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15zM496.997 211h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z" fill="#ffffff" opacity="1" data-original="#000000"/></g></svg>
  );
}

export default LockAnimation;
