import {
    Box, Typography, List,
    ListItem, Button, TableBody, TableCell, Menu,
    TableContainer, TableHead, TableRow,
    Paper, Table, Tab, Tabs, OutlinedInput,
    FormControl, InputAdornment, FormHelperText,Select,
    InputLabel, Checkbox, TablePagination, Link, Grid, IconButton, Breadcrumbs,
    TextField, TextareaAutosize,  MenuItem,Switch,Modal,Rating,Collapse

} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ClearIcon from "@mui/icons-material/Clear";
import NoDataFound from '../components/common/NoDataFound';
import VisibilityIcon from '@mui/icons-material/Visibility';
export default {
    DeleteIcon,
    NoDataFound,
    VisibilityIcon,
    TablePagination,
    AddCircleIcon,
    ExpandLess,
    ExpandMore,
    Collapse,
    Rating,
    RemoveCircleIcon,
    ClearIcon,
    EditIcon,
    Switch,
    Modal,
    AddIcon,
    LocationOnIcon,
    EmailIcon,
    CallIcon,
    CalendarMonthIcon,
    Box,
    Select,
    Typography,
    List,
    ListItem,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    Table,
    Tab,
    Tabs,
    OutlinedInput,
    FormControl,
    InputAdornment,
    FormHelperText,
    InputLabel,
    TablePagination,
    Checkbox,
    Link,
    Grid,
    IconButton,
    Breadcrumbs,
    TextField,
    TextareaAutosize,
    Visibility,
    VisibilityOff,
    MenuItem
};  