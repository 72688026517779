import React, { useState } from 'react'
import PageIndex from '../../../PageIndex';
import Index from '../../../Index';
import "./FAQ.css"

const AddEditFaq = ({ open, setOpen, data,setData,getFaqData }) => {
    console.log({open});
    const handleClose = () => {
        setOpen(false);
        setData();
      };
  const [btnLoading, setBtnLoading] = useState(false);
  const submitForm = async (value) => {
    try {
      setBtnLoading(true);
      const res = await PageIndex.DataService.post(PageIndex.API.ADMIN.ADDEDIT_FAQ, {
        id: data?._id,
        question: value.question,
        answer: value.answer,
      });
      if (res?.data?.status == 200 || 201) {
        PageIndex.toast.success(res?.data?.message);
        handleClose();
        getFaqData();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      handleClose();
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };
    return (
   <>
   
   <Index.Modal
        className="prof-main-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PageIndex.Formik
        //   validationSchema={PackagesAddEdit} 
          initialValues={{
            question: data ? data.question : "",
            answer: data ? data.answer : "",
          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Index.Box className="packages-modal-main faq-modal-box">
                <Index.Box className="prof-heading-box">
                  <Index.Typography className="prof-heading-text">
                    {data?._id ? "Edit FAQ" : "Add FAQ"}
                  </Index.Typography>
                </Index.Box>

                <Index.TextField
                  placeholder="Enter question"
                  size="small"
                  fullWidth
                  multiline
                  rows={2}
                  className='add-edit-modal-input'
                  onChange={handleChange}
                  value={values?.question}
                  name="question"
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 250 }}
                />
                   {errors?.question && (
                    <span className="error"> {errors?.question} </span>
                  )}
           <Index.TextField
                  placeholder="Enter answer"
                  size="small"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                  value={values?.answer}
                  name="answer"
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 200 }}
                />
          
                {errors?.answer && (
                    <span className="error"> {errors?.answer} </span>
                  )}

                <Index.Box className="main-button-modal">
                  <Index.Box className="first-cancel-btn">
                    <Index.Button
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Cancel
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="second-delete-btn">
                    <Index.Button type="submit" disabled={btnLoading}>save</Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PageIndex.Formik>
      </Index.Modal>
   
   </>
  )
}

export default AddEditFaq