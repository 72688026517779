import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import AddEditServices from "./AddEditServices";
import "./Service.css";
import DeleteModal from "../../../../components/common/DeleteModal";
const Service = () => {
  const [serviceData, setServiceData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [serviceEditId, setServiceEditId] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [data, setData] = useState([])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getServiceDetails = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.PROFESSIONAL_GET_SERVCES
      );
      if (res?.data?.status == 200) {
        setServiceData(res?.data?.data?.services);
        setFilterData(res?.data?.data?.services);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  PageIndex.useEffect(() => {
    getServiceDetails();
  }, []);

  const handleDeleteService = async () => {
    try {
      const res = await PageIndex.DataService.post(PageIndex.API.USER.PROFESSIONAL_REMOVE_SERVICE, {
        servicesId: deleteId,
      });
      if (res.data.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getServiceDetails();
      }
      setDeleteOpen(false);

    } catch (error) {
      PageIndex.toast.error(error?.data?.response?.message)
    }
  }

  const activeDeactiveService = async (id, status) => {
    try {
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.PROFESSIONAL_SERVICE_ACTIVE_DEACTIVE,
        { servicesId: id, isActive: status }
      );
      if (res?.data?.status == 200) {
        PageIndex.toast.success(res?.data?.message);
        getServiceDetails();
      }
    } catch (error) {
      PageIndex.toast?.error(error?.response?.data?.message);
      console.log("error", error);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    const res = serviceData?.filter((item) => {
      const matchesServices = item.serviceName.toLowerCase().includes(search);
      return matchesServices;
    });
    setFilterData(res);
  };

  return (
    <>
      <div style={{ margin: "25px" }}>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Service List
          </Index.Typography>
          <Index.Box className="heading-search-add">
            <Index.TextField
              className="search-filter-heading"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
            <Index.Box className="heading-add-box">
              {/* <Index.TextField size="small" placeholder="Search" /> */}
              <Index.Button className="add-button"
                onClick={() => {
                  setServiceModalOpen(true)
                }}

              >Add Service</Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.TableContainer className="packages-table-container booking-list" sx={{ overflow: "scroll" }}>
          <Index.Table stickyHeader aria-label="sticky table">
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell align="center" sx={{ fontWeight: "600" }}>S.No</Index.TableCell>
                <Index.TableCell sx={{ fontWeight: "600" }}>Services</Index.TableCell>
                <Index.TableCell sx={{ fontWeight: "600" }}>Action</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            {!loading ? (
              <Index.TableBody >
                {filterData && filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items, index) => (
                  <>
                    <Index.TableRow>
                      <Index.TableCell>{index + 1 + page * rowsPerPage}</Index.TableCell>
                      <Index.TableCell >
                        <Index.Typography>{items?.serviceName}</Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell className="service-icon-box">

                        <Index.Switch
                          size="small"
                          checked={items?.isActive === true}
                          className="table-icon-switch"
                          onClick={() =>
                            activeDeactiveService(
                              items?._id,
                              items?.isActive == true ? false : true
                            )
                          }
                        />
                        <Index.EditIcon
                          size="small"
                          className="table-icon-edit"
                          onClick={() => {
                            setServiceModalOpen(true);
                            setData(items);
                          }}
                        />
                        <Index.DeleteIcon
                          fontSize="small"
                          className="delete-icon"
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteId(items?._id);
                          }}
                        />
                      </Index.TableCell>

                    </Index.TableRow>
                  </>
                ))}
              </Index.TableBody>
            ) : (
              ""
            )}
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={serviceData?.services?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <DeleteModal
          open={deleteOpen}
          setOpen={setDeleteOpen}
          handelDelete={handleDeleteService}
        />
        <AddEditServices open={serviceModalOpen} setOpen={setServiceModalOpen} data={data} setData={setData} getServiceDetails={getServiceDetails} />


      </div>
    </>
  );
};

export default Service;
