import React from "react";
import './UserAuth.css';
import { ToastContainer, toast } from "react-toastify";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/API";
import { userResetPassword } from '../../../validation/UserValidation';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function ResetPassword() {
    const navigate = useNavigate();
    const params = useParams()
    const initialValues = {
        password: "",
        confirm_password: "",
    }
    // password
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // confirm password
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleFormSubmit = async (value) => {
        const data = {
            password: value.password,
            confirm_password: value.confirm_password,
            resetToken: params?.token
        }
        try {
            const res = await DataService.post(API.USER.USER_RESET_PASSWORD, data);
            if (res?.data?.status == 200) {
                toast.success(res?.data?.message);
                navigate("/user/login");
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, "error");
        }
    }

    return (
        <div className="meanClass">
            <div className="left "></div>
            <div className="rigth common">
                
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        handleFormSubmit(values);
                        actions.resetForm();
                    }}
                    validationSchema={userResetPassword}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <Form className="main-form " onSubmit={handleSubmit}>
                            <h3 className="user-heding">Reset Password!</h3>
                            <label className='user-from'>New Password</label>
                            <OutlinedInput
                                fullWidth
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={values.password}
                                placeholder="Enter your password"
                                className='password-view'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                endAdornment={
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                            {errors.password && touched.password ? (<p className='from-error'>{errors.password}</p>) : null}
                            <label className='user-from'>Confirm Password</label>
                            <OutlinedInput
                                fullWidth
                                id="outlined-adornment-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirm_password"
                                value={values.confirm_password}
                                placeholder="Enter your confirm password"
                                className='password-view'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                endAdornment={
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {errors.confirm_password && touched.confirm_password ? (<p className='from-error'>{errors.confirm_password}</p>) : null}
                            <div class="reset-submit">
                                <button type="submit">Reset Password</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default ResetPassword;
