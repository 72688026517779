import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Login from "../container/admin/auth/login/Login";
import DefaultLayout from "../components/admin/layout/DefaultLayout";
import AddEdit from "../container/admin/pages/roleManagment/AddEdit.js";
import Availability from "../container/professional/page/myAvailability/Availability.js";
import UserLogin from "../container/user/auth/Login";
import UserForgotPassword from "../container/user/auth/ForgotPassword";
import UserResetPassword from "../container/user/auth/ResetPassword";
import UserSignup from "../container/user/auth/Register";
import UserLayout from "../components/user/defaultLayout/UserLayout";
import UserChangePassword from "../container/user/pages/changePassword/UserChangePassword";
import UserEditProfile from "../container/user/pages/profile/UserEditProfile";
import ProfessionalSignup from "../container/professional/Signup";
import ProfessionalList from "../container/professional/page/professional/ProfessionalList";
import ProfessionalDetails from "../container/professional/page/professional/ProfessionalDetails.js";
import UserHome from "../container/user/pages/home/Home";
import RoleManagement from "../container/admin/pages/roleManagment/RoleManagement";
import RoleView from "../container/admin/pages/roleManagment/RoleView";
import Dashboard from "../container/admin/pages/home/Dashboard";
import AdminMaster from "../container/admin/pages/adminMaster/AdminMaster";
import AdminMasterAddEdit from "../container/admin/pages/adminMaster/AdminMasterAddEdit";
import AdminView from "../container/admin/pages/adminMaster/AdminView";
import UserList from "../container/admin/pages/userMaster/UserList";
import ProfessionalsList from "../container/admin/pages/professionalMaster/ProfessionalsList";
import Configuration from "../container/admin/pages/configuration/Configuration";
import AddSubConfiguration from "../container/admin/pages/configuration/AddSubConfiguration";
import ErrorPage from "../container/admin/pages/error/Error";
import NotFound from "../container/admin/pages/error/NotFound";
import UserAddEdit from "../container/admin/pages/userMaster/UserAddEdit";
import UserView from "../container/admin/pages/userMaster/UserView";
import ProfessionalAddEdit from "../container/admin/pages/professionalMaster/ProfessionalAddEdit";
import ProfessionalView from "../container/admin/pages/professionalMaster/ProfessionalView";
import { CategoryMaster } from "../container/admin/pages/category/CategoryMaster";
import ProfessionaLayout from "../components/professional/DefaultLayout/Layout.js";
import Profile from "../container/professional/page/profile/Profile.js";
import Package from "../container/professional/page/package/Package.js";
import Portfolio from "../container/professional/page/portfolio/Portfolio.js";
import Service from "../container/professional/page/services/Service.js";
import Booking from "../container/professional/page/booking/Booking.js";
import MyBooking from "../container/user/pages/booking/MyBooking.js";
import AddEditServices from "../container/professional/page/services/AddEditServices.js";
import Question from "../container/professional/page/question/Question.js";
import ContactUs from "../container/admin/pages/contactUs/ContactUs.js";
import TermsAndCondition from "../container/admin/pages/terms&condition/TermsAndCondition.js";
import PrivacyPolicy from "../container/admin/pages/privacyPolicy/PrivacyPolicy.js";
import TermsCondition from "../container/user/pages/termsAndCondition/TermsCondition.js";
import PrivacyAndPolicy from "../container/user/pages/privacyPolicy/PrivacyAndPolicy.js";
import SiteSetting from "../container/admin/pages/siteSetting/SiteSetting.js";
import FAQ from "../container/admin/pages/faq/FAQ.js";
import UserFaq from "../container/user/pages/faq/UserFaq.js";

const Routes = () => {
  let admin = JSON.parse(localStorage.getItem("admin"));
  return (
    <BrowserRouter>
      <Routess>
        <Route path="/admin" element={<Login />} />
        <Route path="/admin" element={<DefaultLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          {/* <Route path="add-edit" element={<AddEdit />} /> */}
          <Route path="category" element={<CategoryMaster />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="terms-condition" element={<TermsAndCondition />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="site-setting" element={<SiteSetting />} />
          <Route path="faq" element={<FAQ />} />

          <Route path="home" element={<AdminMaster />} />
          <Route path="admin-add-edit" element={<AdminMasterAddEdit />} />
          <Route path="admin-view" element={<AdminView />} />

          {/* Role Management */}

          <Route path="role-management" element={<RoleManagement />} />
          <Route path="role-add-edit" element={<AddEdit />} />
          <Route path="role-view" element={<RoleView />} />

          {/* User Management */}

          <Route path="user-list" element={<UserList />} />
         <Route path="user-add-edit" element={<UserAddEdit />} />
         <Route path="user-view" element={<UserView />} />

          {/* Professionals Management */}

          <Route path="professionals-list" element={<ProfessionalsList />} />

          <Route
            path="professional-add-edit"
            element={<ProfessionalAddEdit />}
          />

          <Route path="professional-view" element={<ProfessionalView />} />

          {/* configuration Management */}

          <Route path="configuration" element={<Configuration />} />
          <Route
            path="add-sub-configuration"
            element={<AddSubConfiguration />}
          />

          <Route path="*" element={<NotFound />} />
        </Route>

        {/* User routes */}

        <Route path="/" element={<UserLayout />}>
          <Route path="" element={<UserHome />} />
          <Route path="/faq" element={<UserFaq />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          <Route path="change-password" element={<UserChangePassword />} />
          <Route path="edit-profile" element={<UserEditProfile />} />
          <Route path="my-booking" element={<MyBooking />} />
          <Route path="professional/list" element={<ProfessionalList />} />
          <Route path="professional/:id" element={<ProfessionalDetails />} />
        </Route>

        <Route path="/professional" element={<ProfessionaLayout />}>
          <Route path="" element={<Profile />} />
          <Route path="service" element={<Service />} />
          <Route path="update-service" element={<AddEditServices />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="package" element={<Package />} />
          <Route path="booking" element={<Booking />} />
          <Route path="question" element={<Question />} />
          <Route path="availability" element={<Availability />} />
        </Route>

        <Route path="/user/login" element={<UserLogin />} />
        <Route path="/user/forgot-password" element={<UserForgotPassword />}>
          {" "}
        </Route>
        <Route
          path="/user/reset-password/:token"
          element={<UserResetPassword />}
        >
          {" "}
        </Route>
        <Route path="/user/signup" element={<UserSignup />}>
          {" "}
        </Route>
        <Route path="/professional/signup" element={<ProfessionalSignup />}>
          {" "}
        </Route>
      </Routess>
    </BrowserRouter>
  );
};

export default Routes;
