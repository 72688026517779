import React from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import "./common.css"

const ViewUserModel = ({ type, data, setData, open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
        setData(null);
    }
    return (
        <Modal
            className="cus-main-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="cus-modal">
                <Typography
                    className="modal-heading-text"
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    View {type}
                </Typography>
                <Box>

                    <Box className='admin-form' >
                        <Box className="admin-input">
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                name="firstName"
                                value={data?.firstName}
                                disabled={true}
                            />
                        </Box>
                        <Box className="admin-input">
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                name="lastName"
                                value={data?.lastName}
                                disabled={true}
                            />
                        </Box>
                        <Box className="admin-input">
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={data?.email}
                                disabled={true}
                            />
                        </Box>
                        <Box className="admin-input">
                            <TextField
                                size="small"
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                name="city"
                                value={data?.city}
                                disabled={true}
                            />
                        </Box>
                        {/* <Box className="main-button-modal">
                            <Box className="first-cancel-btn">
                                <Button onClick={() => { setOpen(false); setData(null) }}>Cancel</Button>
                            </Box>
                            <Box className="second-delete-btn">
                                <Button type="submit" >save</Button>
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ViewUserModel