import React from "react";
import "./Layout.css";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate();
  let location = useLocation();


  return (
    <>
      <Box className="Sidebar">
        <Box className="Sidebar-box">
          <Box className="contant-Sidebar-box">
            <Box>
              <Typography className="contant-Sidebar-text">
                Hire Professional
              </Typography>
            </Box>
          </Box>
          <Box className="admin-menu-list">
            <Box component="ul">
              <Box
                className={
                  location.pathname == "/professional"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                  navigate("/professional");
                }}
              >
                 Profile Setup
              </Box>

              <Box
                className={
                  location.pathname == "/professional/availability"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/availability");
                  }}
              >
               Professional Availability
              </Box>

              <Box
                className={
                  location.pathname == "/professional/service"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/service");
                  }}
              >
              My Service
              </Box>

              <Box
                className={
                  location.pathname == "/professional/package"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/package");
                  }}
              >
                Package List
              </Box>

              
              <Box
                className={
                  location.pathname == "/professional/portfolio"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/portfolio");
                  }}
              >
               Portfolio 
              </Box>

              <Box
                className={
                  location.pathname == "/professional/booking"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/booking");
                  }}
              >
              Booking List 
              </Box>

              
              <Box
                className={
                  location.pathname == "/professional/question"
                    ? "menu-active"
                    : "menu-item"
                }
                component="li"
                onClick={() => {
                    navigate("/professional/question");
                  }}
              >
            Review Question
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
