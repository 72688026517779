import React, { useState } from "react";
import Index from "../../../Index";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import PageIndex from "../../../PageIndex";
import AddEditPortfolio from "./AddEditPortfolio";
import "./Portfolio.css";
import DeleteModal from "../../../../components/common/DeleteModal";

const Portfolio = () => {
  const [loading, setLoading] = useState(false);
  const [portfolioModalOpen, setPortfolioOpen] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [portfolioEditId, setPortfolioEditId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const getPortfolioDetails = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.DataService.get(
        PageIndex.API.USER.PROFESSIONAL_GET_PORTFOLIO
      );
      if (res?.data?.status === 200) {
        setPortfolioEditId(res?.data?.data);
        setPortfolioData(res?.data?.data?.Portfolio);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };


  PageIndex.useEffect(() => {
    getPortfolioDetails();
  }, []);

  const handleDeletePackge = async () => {
    try {
      const updatedPortfolioData = [...portfolioData];
      updatedPortfolioData?.splice(deleteId, 1);
      setPortfolioData(updatedPortfolioData);
      await updatePortfolio(updatedPortfolioData);
      setDeleteOpen(false);

    } catch (error) {
      setDeleteOpen(false);
      console.log(error, "error");
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };

  const updatePortfolio = async (updatedPortfolioData) => {
    try {
      const formData = new FormData();
      updatedPortfolioData?.forEach((file) => {
        formData.append("Portfolio", file);
      });
      const res = await PageIndex.DataService.post(
        PageIndex.API.USER.PROFESSIONAL_UPDATE_PORTFOLIO,
        formData
      );
      if (res?.data?.status === 200) {
        PageIndex.toast.success("Portfolio deleted sccessfully");
        // getPortfolioDetails();
      }
    } catch (error) {
      PageIndex.toast.error(error?.response?.data?.message);
    }
  };


  return (
    <div style={{ margin: "25px" }}>
      <>
        <Index.Box className="heading-box">
          <Index.Typography className="heading-text">
            Portfolio
          </Index.Typography>

          <Index.Box className="heading-add-box">
            <Index.Button
              className="add-button"
              onClick={() => {
                setPortfolioOpen(true);
              }}
            >
              Add Portfolio
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.Box sx={{ flexGrow: 1 }} className="profile-img-container">
          <Index.Grid container spacing={2}>
            {!loading &&
              portfolioData?.map((item, index) => (
                <Index.Grid item xs={4} key={index}>
                  <Index.Box className="portfolio-img-main-box">
                    <Zoom>
                      <img
                        src={`${PageIndex.imageURL}/${item}`}
                        className="portfolio-profile-img"
                        alt={`Portfolio ${index}`}
                      /></Zoom>
                    <Index.ClearIcon
                      fontSize="small"
                      className="portflio-img-remove-icon"
                      onClick={() =>
                      // removeImage(index)
                      {
                        setDeleteOpen(true);
                        setDeleteId(index)

                      }
                      }
                    />
                  </Index.Box>
                </Index.Grid>
              ))}
          </Index.Grid>
        </Index.Box>
      </>

      <AddEditPortfolio
        open={portfolioModalOpen}
        setOpen={setPortfolioOpen}
        data={portfolioEditId}
        setData={setPortfolioEditId}
        getPortfolioDetails={getPortfolioDetails}
      />

      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handelDelete={handleDeletePackge}

      />
    </div>
  );
};

export default Portfolio;
